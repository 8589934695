<template>
    <div>
        <!-- Hero banner -->
        <Widget-Banner></Widget-Banner>
        <div class="grid grid-cols-1 gap-4 md:grid-cols-3">
            <loading :active.sync="isProcessing"
                color="#3B82F6"
                :width="128"
                :height="128"
                backgroundColor="#fff"
                :opacity="0.5"
            ></loading>
            <div class="col-span-2">



                <!-- posts  -->
                <div v-for="item in posts" :key="item._id" class="mb-6 bg-white overflow-hidden shadow sm:rounded-lg"  :ref="item._id">
                    <div class="bg-white px-4 py-5 sm:px-6">
                        <div class="flex space-x-3">
                            <div class="flex-shrink-0">
                            <img class="h-10 w-10 rounded-full" :src="item.photo ? '/people/'+item.photo : '/people/person.png'" alt="">
                            </div>
                            <div class="min-w-0 flex-1">
                            <p class="text-sm font-medium text-gray-900">
                                <a href="#" class="hover:underline">{{item.name}}</a>
                            </p>
                            <p class="text-sm text-gray-500">
                                <a href="#" class="hover:underline">{{item.date | dateFormat}}</a>
                            </p>
                            </div>
                        </div>
                    </div>
                    <div v-if="!item.isvideo" class="py-6 lg:px-8 bg-white overflow-x-hidden ">
                        <div class="max-w-max lg:max-w-7xl mx-auto max-h-96 overflow-x-hidden" v-html="item.content">
                        </div>
                    </div>
                    <div v-else class="video-container" v-html="item.content">
                    </div>
                </div>
            </div>
            <div>
                <div class="bg-white overflow-hidden shadow sm:rounded-lg">
                    <div class="bg-white px-4 py-4 sm:px-6">
                        <h2 class="text-xl mb-3 font-bold leading-7 text-gray-600 sm:text-2xl sm:truncate">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline pb-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
                            </svg>
                            Recent Posts

                        </h2>
                        <div v-for="item in posts" :key="item._id" >
                            <p class="text-sm font-medium text-gray-900 cursor-pointer flex justify-between space-x-3">
                                <a @click="goto(item._id)" class="hover:underline min-w-0 flex-1">
                                    <span>{{item.title}}</span>
                                </a>
                                <time :datetime="item.date" class="flex-shrink-0 whitespace-nowrap text-sm text-gray-500">{{item.date | moment("from")}}</time>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="bg-white overflow-hidden shadow sm:rounded-lg mt-3 md:mt-4">
                    <div class="bg-white px-4 py-4 sm:px-6">
                        <h2 class="text-xl mb-3 font-bold leading-7 text-gray-600 sm:text-2xl sm:truncate">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline pb-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                        </svg>
                        Helpful Links
                        </h2>
                        <div v-for="item in links" :key="item._id" >
                            <p class="text-sm font-medium text-gray-900">
                                <a :href="item.link" target="_blank" class="hover:underline">{{item.title}}</a>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="bg-white overflow-hidden shadow sm:rounded-lg mt-3 md:mt-4">
                    <div class="bg-white px-4 py-4 sm:px-6">
                        <h2 class="text-xl mb-3 font-bold leading-7 text-gray-600 sm:text-2xl sm:truncate">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline pb-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                        </svg>
                        Timeline
                        </h2>
                        <TWTimeline/>
                    </div>
                </div>

            </div>
        </div>
    </div>


</template>

<script>
import {mapState,mapActions} from 'vuex'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import TWTimeline from './TWTimeline.vue'
import WidgetBanner from './WidgetBanner.vue'

export default {
    components: {
        TWTimeline,
        Loading,
        WidgetBanner
    },
    name: 'ProgramPosts',
    created () {
        this.$emit('ready')
        this.trackEvent('welcome')
    },
    mounted () {
        if(this.posts.length ==0) {
        this.loadPosts()
            .then(this.loadLinks())
        }
    },
    computed: {
      ...mapState('post',{
          posts: state => state.items,
          links: state => state.links,
          isProcessing: state => state.isProcessing,
      }),
    },
    methods: {
        ...mapActions('post',{
            loadPosts : 'loadPosts',
            loadLinks : 'loadLinks',
        }),
        goto(refName) {
            var el = this.$refs[refName][0];
            if (el) {
            // Use el.scrollIntoView() to instantly scroll to the element
                el.scrollIntoView({behavior: 'smooth'});
            }
        }
    }
}
</script>

<style scoped>
.video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0px;
    height: 0;
    overflow: hidden;
}

.video-container::v-deep iframe,
.video-container::v-deep object,
.video-container::v-deep embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>