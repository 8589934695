<template>
    <div class="fixed z-20 inset-0 overflow-y-auto">
      <div class="flex items-end justify-center min-h-screen max-w-4xl pt-4 px-4 pb-20 text-center sm:block sm:p-0 mx-auto">

        <transition
          enter-active-class="transition ease-out duration-300"
          enter-from-class="opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="transition ease-in duration-200"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div v-if="showModal" @click="closeMe" class="fixed inset-0 transition-opacity" aria-hidden="true">
            <div class="absolute inset-0 bg-gray-300 opacity-75"></div>
          </div>
        </transition>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <!--
          Modal panel, show/hide based on modal state.
        -->
        <transition
          enter-active-class="transition ease-out duration-700"
          enter-from-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to-class="opacity-100 translate-y-0 sm:scale-100"
          leave-active-class="ease-in duration-200"
          leave-from-class="opacity-100 translate-y-0 sm:scale-100"
          leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div v-if="showModal" class="inline-block align-bottom bg-white rounded-lg mt-24 text-left w-full min-h-60 max-h-96	shadow-xl overflow-auto transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:mt-8" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
            <!-- HEADER --->
            <div class="sticky top-0 z-50	bg-blue-50 px-4 py-4 border-b border-gray-200 sm:px-6">
              <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button @click="closeMe" type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <span class="sr-only">Close</span>
                  <!-- Heroicon name: outline/x -->
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <h4 class="text-large w-48 inline overflow-hidden" content="Used for adjusting contributions and item sharing between spouses and adding support account information." v-tippy>Advanced Settings
                <svg xmlns="http://www.w3.org/2000/svg" class="inline h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
                </svg>
              </h4>
            </div>
            <!-- MODAL CONTENT --->
            <div class="px-5 sm:flex sm:items-start overflow-y-auto">
              <div class="text-left sm:mt-5 w-full">
                <!-- SUPPORT --->
                <span class="text-base font-semibold text-gray-500">Support Account Forecast
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline text-gray-400 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                  </svg>
                </span>
                <div class="w-full text-left text-xs text-yellow-600">* Accurate forecasting requires additional items to be added. Click to watch the <a href="https://vimeo.com/573626737/e706fd5daa" target="_tutorials" class="underline font-bold">Forecasting Tutorial</a>.</div>
                <div class="px-4 py-4 sm:px-0 flex flex-col gap-3 md:items-center md:justify-between md:flex-row">
                  <div class="w-full md:w-1/2">
                    <label for="averagemonthly" class="block text-sm font-medium text-gray-400">Average Monthly Support</label>
                    <div class="flex flex-col">
                      <div class="mb-1 pt-0 relative rounded-md shadow-sm">
                          <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <span class="text-gray-500 sm:text-sm">
                              $
                          </span>
                          </div>
                          <input v-model="$v.averagemonthly.$model" @keyup="submitSupport()" type="number" name="averagemonthly" id="averagemonthly" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md" placeholder="0.00" aria-describedby="529 contribution amount ">
                          <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <span class="text-gray-500 sm:text-sm" id="price-currency">
                              USD
                          </span>
                          </div>
                      </div>
                      <div v-if="(averagemonthly || balance) && !$v.averagemonthly.$error" class="text-center text-xs text-gray-400">
                      ${{(parseFloat(averagemonthly) || 0)*12 | dollarAmt}} USD/year
                      </div>
                      <TWAlert v-if="$v.averagemonthly.$error && !$v.averagemonthly.decimal" alerttype="mini" message="Needs valid USD $ amount."></TWAlert>
                      <TWAlert v-if="$v.averagemonthly.$error && $v.averagemonthly.decimal && !$v.averagemonthly.minValue" alerttype="mini" message="Needs valid positive $ amount"></TWAlert>

                    </div>
                  </div>
                  <div class="w-full md:w-1/2">
                    <label for="balance" class="block text-sm font-medium text-gray-400">Current Support Balance</label>
                    <div class="flex flex-col">
                      <div class="mb-1 pt-0 relative rounded-md shadow-sm">
                          <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <span class="text-gray-500 sm:text-sm">
                              $
                          </span>
                          </div>
                          <input v-model="$v.balance.$model" @keyup="submitSupport()" type="number" name="balance" id="balance" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md" placeholder="0.00" aria-describedby="529 contribution amount ">
                          <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <span class="text-gray-500 sm:text-sm" id="price-currency">
                              USD
                          </span>
                          </div>
                      </div>
                      <div v-if="(averagemonthly || balance) && !$v.balance.$error" class="text-center text-xs text-gray-400">
                      ${{(parseFloat(balance) || 0) | dollarAmt}} current balance.
                      </div>
                      <TWAlert v-if="$v.balance.$error && !$v.balance.decimal" alerttype="mini" message="Needs valid USD $ amount."></TWAlert>
                      <TWAlert v-if="$v.balance.$error && $v.balance.decimal && !$v.balance.minValue" alerttype="mini" message="Needs valid positive $ amount"></TWAlert>

                    </div>
                  </div>
                </div>
                <!-- SPOUSE SPLITS --->
                <span  v-if="isTwoPlan" class="text-base font-semibold text-gray-500">
                  Spouse % Splits
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline ml-1 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                  </svg>
                  </span>
                <ul  v-if="isTwoPlan" class="divide-y divide-gray-50">
                  <li class="px-4 py-4 sm:px-0 flex flex-col gap-4 md:items-center md:justify-between md:flex-row">
                    <div class="md:w-48">
                      <label for="retirement" class="block text-sm font-medium text-gray-400">Retirement</label>
                      <div class="flex flex-col">
                        <div class="mb-1 pt-0 relative rounded-md shadow-sm">
                            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span class="text-gray-500 sm:text-sm">
                                $
                            </span>
                            </div>
                            <input v-model="$v.retirement.$model" @keyup="submit()" type="number" name="retirement" id="retirement" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md" placeholder="0.00" aria-describedby="529 contribution amount ">
                            <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <span class="text-gray-500 sm:text-sm" id="price-currency">
                                USD
                            </span>
                            </div>
                        </div>
                        <div v-if="(retirement)&& !$v.retirement.$error" class="text-center text-xs text-gray-400">
                        ${{(parseFloat(retirement) || 0)*12 | dollarAmt}} USD/year
                        </div>
                        <TWAlert v-if="$v.retirement.$error && !$v.retirement.decimal" alerttype="mini" message="Needs valid USD $ amount."></TWAlert>
                        <TWAlert v-if="$v.retirement.$error && $v.retirement.decimal && !$v.retirement.minValue" alerttype="mini" message="Needs valid positive $ amount"></TWAlert>

                      </div>
                    </div>
                    <div class="flex-grow flex flex-col">
                      <vue-range-slider ref="slider" v-model="retirementOffset" :value="retirementOffset" v-bind="slideroptions" :tooltip-formatter="formatter1" @change="submit">
                      </vue-range-slider>
                      <div class="w-full -mt-2 text-sm text-gray-400 items-center justify-start flex">
                        <div>Spouse 1 <span class="text-gray-500">${{(parseFloat(retirement*retirementOffset/100) || 0) | dollarAmt}}</span></div>
                        <div class="flex-1 text-right">Spouse 2 <span class="text-gray-500">${{(parseFloat(retirement*(100-retirementOffset)/100) || 0) | dollarAmt}}</span></div>
                      </div>
                    </div>
                  </li>
                  <li  class="px-4 py-4 sm:px-0 flex flex-col gap-3 md:items-center md:justify-between md:flex-row">
                    <div class="md:w-48">
                      <label for="lifeinsurance" class="block text-sm font-medium text-gray-400">Life Insurance</label>
                      <div class="flex flex-col">
                        <div class="mb-1 pt-0 relative rounded-md shadow-sm">
                            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span class="text-gray-500 sm:text-sm">
                                $
                            </span>
                            </div>
                            <input v-model="$v.lifeinsurance.$model" @keyup="submit()" type="number" name="lifeinsurance" id="lifeinsurance" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md" placeholder="0.00" aria-describedby="529 contribution amount ">
                            <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <span class="text-gray-500 sm:text-sm" id="price-currency">
                                USD
                            </span>
                            </div>
                        </div>
                        <div v-if="(lifeinsurance) && !$v.lifeinsurance.$error" class="text-center text-xs text-gray-400">
                        ${{(parseFloat(lifeinsurance) || 0)*12 | dollarAmt}} USD/year
                        </div>
                        <TWAlert v-if="$v.lifeinsurance.$error && !$v.lifeinsurance.decimal" alerttype="mini" message="Needs valid USD $ amount."></TWAlert>
                        <TWAlert v-if="$v.lifeinsurance.$error && $v.lifeinsurance.decimal && !$v.lifeinsurance.minValue" alerttype="mini" message="Needs valid positive $ amount"></TWAlert>

                      </div>
                    </div>
                    <div class="flex-grow flex flex-col">
                      <vue-range-slider ref="slider" v-model="lifeinsuranceOffset" :value="lifeinsuranceOffset" v-bind="slideroptions" :tooltip-formatter="formatter1" @change="submit">
                      </vue-range-slider>
                      <div class="w-full -mt-2 text-sm text-gray-400 items-center justify-start flex">
                        <div>Spouse 1 <span class="text-gray-500">${{(parseFloat(lifeinsurance*lifeinsuranceOffset/100) || 0) | dollarAmt}}</span></div>
                        <div class="flex-1 text-right">Spouse 2 <span class="text-gray-500">${{(parseFloat(lifeinsurance*(100-lifeinsuranceOffset)/100) || 0) | dollarAmt}}</span></div>
                      </div>
                    </div>
                  </li>
                  <li v-if="form.usbased" class="px-4 py-4 sm:px-0 flex flex-col gap-3 md:items-center md:justify-between md:flex-row">
                    <div class="md:w-48">
                      <label for="flexiblespendingplan" class="block text-sm font-medium text-gray-400">USFlex Plan</label>
                      <div class="flex flex-col">
                        <div class="mb-1 pt-0 relative rounded-md shadow-sm">
                            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span class="text-gray-500 sm:text-sm">
                                $
                            </span>
                            </div>
                            <input v-model="$v.flexiblespendingplan.$model" @keyup="submit()" type="number" name="flexiblespendingplan" id="flexiblespendingplan" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md" placeholder="0.00" aria-describedby="529 contribution amount ">
                            <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <span class="text-gray-500 sm:text-sm" id="price-currency">
                                USD
                            </span>
                            </div>
                        </div>
                        <div v-if="(flexiblespendingplan) && !$v.flexiblespendingplan.$error" class="text-center text-xs text-gray-400">
                        ${{(parseFloat(flexiblespendingplan) || 0)*12 | dollarAmt}} USD/year
                        </div>
                        <TWAlert v-if="$v.flexiblespendingplan.$error && !$v.flexiblespendingplan.decimal" alerttype="mini" message="Needs valid USD $ amount."></TWAlert>
                        <TWAlert v-if="$v.flexiblespendingplan.$error && $v.flexiblespendingplan.decimal && !$v.flexiblespendingplan.minValue" alerttype="mini" message="Needs valid positive $ amount"></TWAlert>

                      </div>
                    </div>
                    <div class="flex-grow flex flex-col">
                      <vue-range-slider ref="slider" v-model="flexiblespendingplanOffset" :value="flexiblespendingplanOffset" v-bind="slideroptions" :tooltip-formatter="formatter1" @change="submit">
                      </vue-range-slider>
                      <div class="w-full -mt-2 text-sm text-gray-400 items-center justify-start flex">
                        <div>Spouse 1 <span class="text-gray-500">${{(parseFloat(flexiblespendingplan*flexiblespendingplanOffset/100) || 0) | dollarAmt}}</span></div>
                        <div class="flex-1 text-right">Spouse 2 <span class="text-gray-500">${{(parseFloat(flexiblespendingplan*(100-flexiblespendingplanOffset)/100) || 0) | dollarAmt}}</span></div>
                      </div>
                    </div>
                  </li>
                  <li v-if="form.iswithholding" class="px-4 py-4 sm:px-0 flex flex-col gap-3 md:items-center md:justify-between md:flex-row">
                    <div class="md:w-48">
                      <label for="federalwithholding" class="block text-sm font-medium text-gray-400">Fed Tax Withholding</label>
                      <div class="flex flex-col">
                        <div class="mb-1 pt-0 relative rounded-md shadow-sm">
                            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span class="text-gray-500 sm:text-sm">
                                $
                            </span>
                            </div>
                            <input v-model="$v.federalwithholding.$model" @keyup="submit()" type="number" name="federalwithholding" id="federalwithholding" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md" placeholder="0.00" aria-describedby="529 contribution amount ">
                            <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <span class="text-gray-500 sm:text-sm" id="price-currency">
                                USD
                            </span>
                            </div>
                        </div>
                        <div v-if="(federalwithholding) && !$v.federalwithholding.$error" class="text-center text-xs text-gray-400">
                        ${{(parseFloat(federalwithholding) || 0)*12 | dollarAmt}} USD/year
                        </div>
                        <TWAlert v-if="$v.federalwithholding.$error && !$v.federalwithholding.decimal" alerttype="mini" message="Needs valid USD $ amount."></TWAlert>
                        <TWAlert v-if="$v.federalwithholding.$error && $v.federalwithholding.decimal && !$v.federalwithholding.minValue" alerttype="mini" message="Needs valid positive $ amount"></TWAlert>

                      </div>
                    </div>
                    <div class="flex-grow flex flex-col">
                      <vue-range-slider ref="slider" v-model="federalwithholdingOffset" :value="federalwithholdingOffset" v-bind="slideroptions" :tooltip-formatter="formatter1" @change="submit">
                      </vue-range-slider>
                      <div class="w-full -mt-2 text-sm text-gray-400 items-center justify-start flex">
                        <div>Spouse 1 <span class="text-gray-500">${{(parseFloat(federalwithholding*federalwithholdingOffset/100) || 0) | dollarAmt}}</span></div>
                        <div class="flex-1 text-right">Spouse 2 <span class="text-gray-500">${{(parseFloat(federalwithholding*(100-federalwithholdingOffset)/100) || 0) | dollarAmt}}</span></div>
                      </div>
                    </div>
                  </li>
                  <li v-if="form.iswithholding" class="px-4 py-4 sm:px-0 flex flex-col gap-3 md:items-center md:justify-between md:flex-row">
                    <div class="md:w-48">
                      <label for="statewithholding" class="block text-sm font-medium text-gray-400">State Tax Withholding</label>
                      <div class="flex flex-col">
                        <div class="mb-1 pt-0 relative rounded-md shadow-sm">
                            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span class="text-gray-500 sm:text-sm">
                                $
                            </span>
                            </div>
                            <input v-model="$v.statewithholding.$model" @keyup="submit()" type="number" name="statewithholding" id="statewithholding" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md" placeholder="0.00" aria-describedby="529 contribution amount ">
                            <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <span class="text-gray-500 sm:text-sm" id="price-currency">
                                USD
                            </span>
                            </div>
                        </div>
                        <div v-if="(statewithholding) && !$v.statewithholding.$error" class="text-center text-xs text-gray-400">
                        ${{(parseFloat(statewithholding) || 0)*12 | dollarAmt}} USD/year
                        </div>
                        <TWAlert v-if="$v.statewithholding.$error && !$v.statewithholding.decimal" alerttype="mini" message="Needs valid USD $ amount."></TWAlert>
                        <TWAlert v-if="$v.statewithholding.$error && $v.statewithholding.decimal && !$v.statewithholding.minValue" alerttype="mini" message="Needs valid positive $ amount"></TWAlert>

                      </div>
                    </div>
                    <div class="flex-grow flex flex-col">
                      <vue-range-slider ref="slider" v-model="statewithholdingOffset" :value="statewithholdingOffset" v-bind="slideroptions" :tooltip-formatter="formatter1" @change="submit">
                      </vue-range-slider>
                      <div class="w-full -mt-2 text-sm text-gray-400 items-center justify-start flex">
                        <div>Spouse 1 <span class="text-gray-500">${{(parseFloat(statewithholding*statewithholdingOffset/100) || 0) | dollarAmt}}</span></div>
                        <div class="flex-1 text-right">Spouse 2 <span class="text-gray-500">${{(parseFloat(statewithholding*(100-statewithholdingOffset)/100) || 0) | dollarAmt}}</span></div>
                      </div>
                    </div>
                  </li>
                </ul>
                <!-- CUSTOM ITEMS --->
                <span v-if="personals.length > 0 && isTwoPlan" class="text-base font-semibold text-gray-500">
                  Custom Items Splits
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline ml-1 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z" />
                  </svg>
                </span>
                <ul v-if="personals.length > 0 && isTwoPlan" class="divide-y divide-gray-50">
                  <li v-for="item in $v.personals.$each.$iter" :key="item.id" class="px-4 py-4 sm:px-0 flex flex-col gap-4 md:items-center md:justify-between md:flex-row">
                    <div class="md:w-48">
                      <label :for="item.$model.id" class="block text-sm font-medium text-gray-400">{{item.$model.name}}</label>
                      <div class="flex flex-col">
                        <div class="mb-1 pt-0 relative rounded-md shadow-sm">
                            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span class="text-gray-500 sm:text-sm">
                                $
                            </span>
                            </div>
                            <input v-model="item.amt.$model" @keyup="submitCustom" type="number" :name="item.$model.id" :id="item.$model.id"  class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md" placeholder="0.00" >
                            <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <span class="text-gray-500 sm:text-sm" id="price-currency">
                                USD
                            </span>
                            </div>
                        </div>
                        <div v-if="(item.$model.amt) && !item.amt.$error" class="text-center text-xs text-gray-400">
                        ${{(parseFloat(item.$model.amt) || 0)*12 | dollarAmt}} USD/year
                        </div>
                        <TWAlert v-if="item.amt.$error && !item.amt.decimal" alerttype="mini" message="Needs valid USD $ amount."></TWAlert>
                      </div>
                    </div>
                    <div class="flex-grow flex flex-col">
                      <vue-range-slider ref="slider" v-model="item.$model.offset" :value="item.$model.offset" v-bind="slideroptions" :tooltip-formatter="formatter1" @change="submitCustom">
                      </vue-range-slider>
                      <div class="w-full -mt-2 text-sm text-gray-400 items-center justify-start flex">
                        <div>Spouse 1 <span class="text-gray-500">${{(parseFloat(item.$model.amt*item.$model.offset/100) || 0) | dollarAmt}}</span></div>
                        <div class="flex-1 text-right">Spouse 2 <span class="text-gray-500">${{(parseFloat(item.$model.amt*(100-item.$model.offset)/100) || 0) | dollarAmt}}</span></div>
                      </div>
                    </div>
                  </li>
                </ul>
                <!-- Settings --->
                <span class="text-base font-semibold text-gray-500">Format and Exceptions
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline text-gray-400 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </span>
                <div class="px-4 py-4 sm:px-0 flex flex-col gap-3 md:items-center md:justify-between md:flex-row">
                  <div class="w-full md:w-1/2">
                    <label for="averagemonthly" class="block text-sm font-medium text-gray-400">$ Rounding</label>
                    <div class="flex flex-col">
                      <div class="mb-1 pt-0 relative rounded-md shadow-sm">
                        <select @change="submitFormat($event)" id="rounding" name="rounding" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                          <option :selected="rounding == 0" value="0">$101</option>
                          <option :selected="rounding == 1" value="1">$100.6</option>
                          <option :selected="rounding == 2" value="2">$100.56</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
</template>
<script>
import 'vue-slider-component/theme/antd.css'
import _ from 'lodash'
import VueRangeSlider from 'vue-slider-component'
import {mapState, mapGetters, mapActions} from 'vuex'
import {minValue,decimal} from 'vuelidate/lib/validators'

export default {
  name:'ModalSettings',
  props: {
    showModal: {
      type: Boolean,
      default: false
    }
  },
  components: {
    VueRangeSlider
  },
  data () {
    return {
      personals: [],
      balance: null,
      averagemonthly: null,
      retirement: null,
      retirementOffset: 50,
      lifeinsurance: null,
      lifeinsuranceOffset: 50,
      flexiblespendingplan:null,
      flexiblespendingplanOffset: 50,
      federalwithholding: null,
      federalwithholdingOffset: 50,
      statewithholding: null,
      statewithholdingOffset: 50,
      rounding: 0,
      formatter1: '{value}%',
      slideroptions: {
      }
    }
  },
  validations: {
    averagemonthly: {
      decimal,
      minValue: minValue(0)
    },
    balance: {
      decimal,
      minValue: minValue(0)
    },
    retirement: {
      decimal,
      minValue: minValue(0)
    },
    lifeinsurance: {
      decimal,
      minValue: minValue(0)
    },
    flexiblespendingplan: {
      decimal,
      minValue: minValue(0)
    },
    federalwithholding: {
      decimal,
      minValue: minValue(0)
    },
    statewithholding: {
      decimal,
      minValue: minValue(0)
    },
    personals: {
      $each: {
        amt: {
          decimal
        }
      }
    }
  },
  mounted() {
    this.retirement = parseFloat(this.form.additionalcontributions.retirement) || 0
    this.retirementOffset = parseFloat(this.form.additionalcontributions.retirementOffset) || 50
    this.lifeinsurance = parseFloat(this.form.additionalcontributions.lifeinsurance)  || 0
    this.lifeinsuranceOffset = parseFloat(this.form.additionalcontributions.lifeinsuranceOffset) || 50
    this.flexiblespendingplan = parseFloat(this.form.flexiblespendingplan) || 0
    this.flexiblespendingplanOffset = parseFloat(this.form.flexiblespendingplanOffset) || 50
    this.federalwithholding = parseFloat(this.form.taxes.federalwithholding)  || 0
    this.federalwithholdingOffset = parseFloat(this.form.taxes.federalwithholdingOffset) || 50
    this.statewithholding = parseFloat(this.form.taxes.statewithholding) || 0
    this.statewithholdingOffset = parseFloat(this.form.taxes.statewithholdingOffset) || 50
    this.averagemonthly = parseFloat(this.form.supportaccount.averagemonthly) || 0
    this.balance = parseFloat(this.form.supportaccount.balance) || 0
    this.personals = this.form.personal
    this.rounding = this.form.rounding
  },
  computed: {
    ...mapState({
      form: state => state.estimator
    }),
    ...mapGetters('estimator',{
      isTwoPlan : 'isTwoPlan'
    })
  },
  methods: {
    ...mapActions({
      updateData : 'estimator/updateData'
    }),
    closeMe () {
      this.$emit('close')
    },
    submitSupport: _.debounce(function () {
      if(!this.$v.$invalid) {
        this.updateData({
          supportaccount: {
            averagemonthly: parseFloat(this.averagemonthly),
            balance: parseFloat(this.balance)
          }
        })
      }
    }, 500),
    submitFormat(event) {
      this.rounding = event.target.value
      console.log(event.target.value)
      this.updateData({
        rounding: this.rounding
      })
    },
    submitCustom: _.debounce(function () {
      if(!this.$v.$invalid) {
        this.personals = this.personals.map(item => {
          item.amt1 = item.amt*(item.offset/100)
          item.amt2 =  item.amt*((100-item.offset)/100)
          return item
        })
        this.updateData({
          personal: this.personals
        })
      }
    }, 500),
    submit () {
      if(!this.$v.$invalid) {
        this.updateData({
            additionalcontributions: {
              retirement: parseFloat(this.retirement),
              retirement1: parseFloat(this.retirement)*(this.retirementOffset/100),
              retirement2: parseFloat(this.retirement)*((100-this.retirementOffset)/100),
              retirementOffset: this.retirementOffset,
              lifeinsurance: parseFloat(this.lifeinsurance),
              lifeinsurance1: parseFloat(this.lifeinsurance)*(this.lifeinsuranceOffset/100),
              lifeinsurance2: parseFloat(this.lifeinsurance)*((100-this.lifeinsuranceOffset)/100),
              lifeinsuranceOffset: this.lifeinsuranceOffset,
            },
            flexiblespendingplan: parseFloat(this.flexiblespendingplan),
            flexiblespendingplan1: parseFloat(this.flexiblespendingplan)*(this.flexiblespendingplanOffset/100),
            flexiblespendingplan2: parseFloat(this.flexiblespendingplan)*((100-this.flexiblespendingplanOffset)/100),
            flexiblespendingplanOffset: this.flexiblespendingplanOffset,
            taxes: {
              federalwithholding: parseFloat(this.federalwithholding),
              federalwithholding1: parseFloat(this.federalwithholding)*(this.federalwithholdingOffset/100),
              federalwithholding2: parseFloat(this.federalwithholding)*((100-this.federalwithholdingOffset)/100),
              federalwithholdingOffset: this.federalwithholdingOffset,
              statewithholding: parseFloat(this.statewithholding),
              statewithholding1: parseFloat(this.statewithholding)*(this.statewithholdingOffset/100),
              statewithholding2: parseFloat(this.statewithholding)*((100-this.statewithholdingOffset)/100),
              statewithholdingOffset: this.statewithholdingOffset,
            },
            personal: this.personals
          })
      }
    }
  }
}
</script>