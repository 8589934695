import axios from 'axios'
export default {
  namespaced:true,
  state: {
    items: [],
    isProcessing: false,
  },
  getters: {
    getFaqs (state) {
      return state.items
    },
  },
  actions: {
    loadFaqs ({ commit }) {
      commit('setProcessing', true)
      axios
        .get('/.netlify/functions/faqs', {
            headers: {
                'jwt': localStorage.getItem("jwt"),
            }
        })
        .then(response => response.data)
        .then(items => {
          commit('setFaqs', items)
          commit('setProcessing', false)
        })
    }
  },
  mutations: {
    setFaqs(state,items) {
      state.items  = items
    },
    setProcessing(state,processing) {
      state.isProcessing = processing
    },
  }

}
