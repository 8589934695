<template>
    <div class="flex flex-col items-center" :content="tooltip ? tooltip : null"  v-tippy>
      <div :class="topclass" class="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0">
        <svg v-if="arrowme" :class="innerclass" class="-ml-1 mr-0.5 flex-shrink-0 self-center h-4 w-4" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
          <path fill-rule="evenodd" :d="svg" clip-rule="evenodd" />
        </svg>
        <span class="sr-only">
        {{text}} by
        </span>
        <span v-if="flat">
          {{Math.abs(amt).toFixed(1)}}
        </span>
        <span v-else-if="full100 && amt > -100">
          {{Math.abs(100+amt).toFixed(1)}}%
        </span>
        <span v-else-if="full100">
        -{{Math.abs(100+amt).toFixed(1)}}%
        </span>
        <span v-else>
          {{Math.abs(amt).toFixed(1)}}%
        </span>
      </div>
      <div v-if="msg" class="text-xs font-medium text-center text-gray-300">
        {{msg}}
      </div>
    </div>
</template>
<script>

export default {
  name:'TWBadge',
  data() {
    return {
      colorindex: [-100,-50,-30,-10,-5,0,5,10,30,100],
      topcolors: ['bg-red-300 text-red-800','bg-red-200 text-red-700','bg-red-100 text-red-400','bg-yellow-500 text-yellow-900'
        ,'bg-yellow-300 text-yellow-900','bg-green-100 text-green-800','bg-green-200 text-green-700','bg-green-100 text-green-500'
        ,'bg-red-100 text-red-600','bg-red-200 text-red-700'],
      arrowcolors: ['text-red-800','text-red-700','text-red-400','text-yellow-900'
        ,'text-yellow-600','text-green-800','text-green-700','text-green-500'
        ,'text-yellow-600','text-red-800']
    }
  },
  props: {
    amt: Number,
    msg: String,
    arrowme: {
      type: Boolean,
      default: true
    },
    full100: {
      type: Boolean,
      default: false
    },
    flat: {
      type: Boolean,
      default: false
    },
    tooltip: String
  },
  computed:  {
    closestColor () {
      const output = this.colorindex.reduce((prev, curr) => Math.abs(curr -parseFloat(this.amt)) < Math.abs(prev - parseFloat(this.amt)) ? curr : prev)
      return output
    },
    topclass () {
      return this.topcolors[this.colorindex.indexOf(this.closestColor)]
    },
    innerclass () {
      return this.arrowcolors[this.colorindex.indexOf(this.closestColor)]
    },
    svg () {
      if(parseFloat(this.amt) < 0)
        return "M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z"
      else
        return "M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z"
    },
    text () {
      if(parseFloat(this.amt) < 0)
        return "Decreased"
      else
        return "Increased"
    }
  }

}
</script>