export const twoPlanGuide = [
  {
    title: 'Welcome to the Clearcomp Preview Panels! 👋',
    intro: 'This short guide will walk you through the different options and what it all means!'
  },
  {
      element: '#programToggle',
      title: 'Program Selection',
      intro: 'Click the toggle to view the 1-Paid option vs. the 2-Paid Option'
  },
  {

      element: '#vsrSection',
      title: 'Voluntary Salary Reduction',
      intro: 'Here you can model how a different amount of Voluntary Salary Reduction would affect your estimate. You can have two different amounts for the 2-pay plan.'
  },
  {

      element: '#grossSection',
      title: 'Gross Income',
      intro: 'Wages, salary, annual items and the (optional) voluntary salary reduction are all part of your gross salary.'
  },
  {

      element: '#netSection',
      title: 'Take-Home Estimation',
      intro: 'All pre-tax and post-tax deductions from your gross salary provide you your estimated net monthly take-home amount.  These monthly and yearly estimations <b>do not include any annual items</b> from your gross calculation.'
  },
  {

      element: '#supportSection',
      title: 'Support Impact',
      intro: 'This section estimates the monthly (and annual) overall impact on your support account. <br/> The support items listed do not include some monthly items (i.e., Admin fees, HMA Reserve, and Area Retreat) because they are too difficult to estimate, but later we will show you how to add them yourself. '
  },
  {

      element: '#buttonAddCustom',
      title: 'Custom Items',
      position:'top',
      intro: 'As mentioned, some items are not out-of-the-box, so you can customize the preview screen by adding items to each section where you see this button. <br/><br/> Here on the Support Section, you can pull from Workday your information to be able to fill in the missing pieces such as Admin fees, HMA Reserve, and Area Retreat.'
  },
  
  {

      element: '#buttonExport',
      title: 'Export PDF',
      position:'bottom',
      intro: 'This converts a screenshot image of your preview panel to a PDF you can download and save so you will be ready for the program and VSR election period in July.'
  },
  {

    element: '#buttonSettings',
    title: 'Advanced Settings',
    position: 'left',
    intro: 'You can set the advanced items here for Support Forecasting and adjusting Spouse Splits.'
},

]


export const onePlanGuide =[
  {
    title: 'Welcome to the Clearcomp Preview Panels! 👋',
    intro: 'This short guide will walk you through the different options and what it all means!'
  },
  {

      element: '#vsrSection',
      title: 'Voluntary Salary Reduction',
      intro: 'Here you can model how a different amount of Voluntary Salary Reduction would affect your estimate.'
  },
  {

      element: '#grossSection',
      title: 'Gross Income',
      intro: 'Wages, salary, annual items and the (optional) voluntary salary reduction are all part of your gross salary.'
  },
  {

      element: '#netSection',
      title: 'Take-Home Estimation',
      intro: 'All pre-tax and post-tax deductions from your gross salary provide you your estimated net monthly take-home amount.  These monthly and yearly estimations don\'t include any annual items from your gross calculation.'
  },
  {

      element: '#supportSection',
      title: 'Support Impact',
      intro: 'This section estimates the monthly (and annual) overall impact on your support account. <br/> The support items listed do not include some monthly items (i.e., Admin fees, HMA Reserve, and Area Retreat) because they are too difficult to estimate, but later we will show you how to add them yourself. '
  },
  {

      element: '#buttonAddCustom',
      title: 'Custom Items',
      position:'top',
      intro: 'As mentioned, some items are not out-of-the-box, so you can customize the preview screen by adding items to each section where you see this button. <br/><br/> Here on the Support Section, you can pull from Workday your information to be able to fill in the missing pieces such as Admin fees, HMA Reserve, and Area Retreat.'
  },
  {

      element: '#buttonExport',
      title: 'Export PDF',
      position:'bottom',
      intro: 'This converts a screenshot image of your preview panel to a PDF you can download and save so you will be ready for the program and VSR election period in July.'
  },
  {

      element: '#buttonSettings',
      title: 'Advanced Settings',
      position:'bottom',
      intro: 'Support Forecasting</b>gives you a burn-down month count on your support section to indicate how many months would be left with your estimated model and how much (if any) you may need to raise to meet the estimated program. '
  }
]