<template>
    <div class="fixed z-20 inset-0 overflow-y-auto">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

        <transition
          enter-active-class="transition ease-out duration-300"
          enter-from-class="opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="transition ease-in duration-200"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div @click="closeMe" class="fixed inset-0 transition-opacity" aria-hidden="true">
            <div class="absolute inset-0 bg-gray-300 opacity-75"></div>
          </div>
        </transition>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <!--
          Modal panel, show/hide based on modal state.
          enter-active-class="transition ease-out duration-300"
          enter-from-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to-class="opacity-100 translate-y-0 sm:scale-100"
          leave-active-class="ease-in duration-200"
          leave-from-class="opacity-100 translate-y-0 sm:scale-100"
          leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        -->
        <transition
          name="fade" mode="out-in"
        >
          <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 sm:mt-8" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
            <div v-if="modalType!='accept'" class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
              <button @click="closeMe" type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <span class="sr-only">Close</span>
                <!-- Heroicon name: outline/x -->
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div class="sm:flex sm:items-start">
              <div v-if="icon" class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                <!-- Heroicon name: outline/exclamation -->
                <svg :class="iconclass" class="h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" :d="icon" />
                </svg>
              </div>
              <div v-if="!$slots.default" class="mt-3 text-center sm:mt-5">
                <h3 v-if="title" class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                  {{title}}
                </h3>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    {{message}}
                  </p>
                </div>
              </div>
              <div v-else class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <slot/>
              </div>
            </div>
            <div v-if="modalType=='accept'" class="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex">
              <button @click="acceptMe" type="button" class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:w-auto sm:text-sm">
              {{acceptText || 'Accept Terms'}}
              </button>
              <button @click="closeMe" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
              Cancel
              </button>
            </div>
          </div>
        </transition>
      </div>
    </div>
</template>
<script>
export default {
  name:'TWModal',
  props: {
    message: String,
    modalType: String,
    title: null,
    acceptText: String,
    icon: null,
    iconclass: {
      type: String,
      default: 'text-yellow-600'
    },
  },
  methods: {
    closeMe () {
      this.$emit('close')
    },
    acceptMe () {
      this.$emit('accept')
    }
  }
}
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  opacity: 1;
  transition: all 0.6s;
}
.fade-enter,
.fade-leave-to {
  transform: translateY(5px);
  opacity: 0;
}
</style>