import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/pages/PageHome'
import Error404 from '@/pages/Error404'
import Login from '@/pages/Login'
import FormWizard from '@/components/FormWizard'
import Program from '@/components/CCProgram'
import Faqs from '@/components/CCFaqs'
import Budget from '@/components/CCBudgetPlanner'
import Disclaimer from '@/components/CCDisclaimer'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/',
      component: Home,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: '',
          name: 'Estimator',
          component: FormWizard
        },
        {
          path: 'estimator',
          component: FormWizard
        },
        {
          path: 'program',
          component: Program
        },
        {
          path: 'faqs',
          component: Faqs
        },
        {
          path: 'budget',
          component: Budget
        },
        {
          path: 'disclaimer',
          component: Disclaimer
        }
      ]
    },
    {
      path: '*',
      name: 'NotFound',
      component: Error404
    }
  ],
  mode: 'history'
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && !localStorage.getItem("jwt")) next({ name: 'Login' })
  // if (to.matched.some(record => record.meta.requiresAuth)) {
  //   if (!localStorage.getItem("jwt")) {
  //     next({ name: 'Login' })
  //   }
  // }
  else next()
})

export default router