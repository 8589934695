<template>
  <div>

    <h2 class="text-center text-xl py-3 font-semibold	">Home-Assigned Worker <TWQType qtype="estimated" /></h2>

    <div class="space-y-6 max-w-3xl text-left mx-auto">

      <div class="flex items-center justify-between">
        <span class="flex-grow flex flex-col" id="availability-label">
          <span class="text-sm font-medium text-gray-900">Living In States</span>
          <span class="text-sm text-gray-500">In 2022, are you based in the U.S. primarily for work (not for HMA)? </span>
        </span>
        <!-- Enabled: "bg-indigo-600", Not Enabled: "bg-gray-200" -->
        <button type="button" @click="isUsbased()" :aria-pressed="usbased ? 'true' : 'false'" :class="usbased ? 'bg-indigo-600' : 'bg-gray-200'" class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"  aria-labelledby="availability-label">
          <span class="sr-only">Based in the states</span>
          <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
          <span aria-hidden="true" :class="usbased ? 'translate-x-5' : 'translate-x-0'" class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
        </button>
      </div>

      <div v-if="usbased" class="flex flex-col gap-3 md:items-center md:justify-between md:flex-row md:mt-3">
        <span class="flex-grow flex flex-col" id="availability-label">
          <span class="text-sm font-medium text-gray-900">Flexible-Spending Account</span>
          <span class="text-sm text-gray-500">What is your <span class="font-bold">monthly</span> amount deposited for your flexible spending plan?</span>
        <TWAlert v-if="$v.flexiblespendingplan.$error && !$v.flexiblespendingplan.decimal" alerttype="mini" message="Needs valid USD $ amount."></TWAlert>
        <TWAlert v-if="$v.flexiblespendingplan.$error && $v.flexiblespendingplan.decimal && !$v.flexiblespendingplan.minValue" alerttype="mini" message="Needs valid positive $ amount"></TWAlert>
        </span>

        <div class="md:w-48">
          <!-- <label v-if="isTwoPlan" for="flexiblespendingplan" class="block text-sm font-medium text-gray-400">1-Paid or Spouse 1</label> -->
          <div class="flex flex-col">
            <div class="mb-1 pt-0 relative rounded-md shadow-sm">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm">
                    $
                </span>
                </div>
                <input v-model="$v.flexiblespendingplan.$model" @keyup="submit()" type="number" name="flexiblespendingplan" id="flexiblespendingplan" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md" placeholder="0.00" aria-describedby="529 contribution amount ">
                <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm" id="price-currency">
                    USD
                </span>
                </div>
            </div>
            <div v-if="flexiblespendingplan || flexiblespendingplan2" class="text-right text-xs text-gray-400">
            ${{(parseFloat(flexiblespendingplan) || 0) *12 | dollarAmt}} USD annually
            </div>          
          </div>

        </div>

        <!-- <div v-if="isTwoPlan" class="md:w-48">
          <label for="flexiblespendingplan2" class="block text-sm font-medium text-gray-400">Spouse 2</label>
          <div class="flex flex-col">
            <div class="mb-1 pt-0 relative rounded-md shadow-sm">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm">
                    $
                </span>
                </div>
                <input v-model="$v.flexiblespendingplan2.$model" @keyup="submit()" type="number" name="flexiblespendingplan2" id="flexiblespendingplan2" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md" placeholder="0.00" aria-describedby="529 contribution amount ">
                <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm" id="price-currency">
                    USD
                </span>
                </div>
            </div>
            <div v-if="flexiblespendingplan || flexiblespendingplan2" class="text-right text-xs text-gray-400">
            ${{(parseFloat(flexiblespendingplan2) || 0)*12 | dollarAmt}} USD annually
            </div>          
          </div>

        </div>         -->
      </div>

    </div>

  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import {minValue,decimal} from 'vuelidate/lib/validators'
import TWAlert from './TWAlert.vue'
import TWQType from './TWQType.vue'
export default {
  components: {
    TWAlert,
    TWQType
  },
  data () {
    return {
      usbased: false,
      flexiblespendingplan: null,
      flexiblespendingplan2: null 
    }
  },
  validations: {
    flexiblespendingplan: {
      decimal,
      minValue: minValue(0)
    },
    flexiblespendingplan2: {
      decimal,
      minValue: minValue(0)
    }    
  },
  computed: {
    ...mapState({
      form: state => state.estimator
    }),
    ...mapGetters('estimator',{
      isTwoPlan : 'isTwoPlan'
    })    
  },
  mounted () {
    this.usbased = this.form.usbased
    this.flexiblespendingplan = this.form.flexiblespendingplan
    this.flexiblespendingplan2 = this.form.flexiblespendingplan2
  },
  methods: {
    isUsbased () {
      this.usbased = !this.usbased
      if(!this.usbased) {
        this.flexiblespendingplan = null
        this.flexiblespendingplan2 = null
      }
      this.submit()
    },
    submit () {
      const offset = parseFloat(this.form.flexiblespendingplanOffset) || 50 
      this.$emit('update',{
        data: {
          usbased: this.usbased,
          flexiblespendingplan: this.flexiblespendingplan,          
          flexiblespendingplan1: this.flexiblespendingplan*(offset/100),
          flexiblespendingplan2: this.flexiblespendingplan*((100-offset)/100),
        },
        valid: !this.$v.$invalid
      })
    }
  }
}
</script>

<style scoped>

</style>