<template>
  <div class="col-full" :class="$style.centered">
    <h1 :class="$style.headingLarge"> Not Found</h1>
    <p :class="$style.textLarge">Oops, we couldn't find whhat you were looking for go... 
      <router-link :to="{name:'Home'}">Home</router-link>
    </p>
  </div>
</template>
<script>
export default {
  created () {
    this.$emit('ready')
  }
}
</script>
<style module>
.headingLarge { font-size:5em}
.textLarge { font-size: 2em; font-weight: 100}
.centered { text-align:center}
</style> 
