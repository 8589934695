<template>
    <div class="relative mt-2"> <!--- Custom Item Button --->
      <div class="absolute inset-0 flex items-center" aria-hidden="true">
          <div class="w-full border-t border-gray-200"></div>
      </div>
      <div :id="id" class="relative flex justify-center">
          <button @click="toggleCustomItem" type="button" class="inline-flex items-center shadow-sm px-0.5 py-0.5 border border-gray-300 text-xs leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          <!-- Heroicon name: solid/plus-sm -->
          <svg class="h-4 w-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" />
          </svg>
          </button>
      </div>
  </div>
</template>
<script>
export default {
  name: 'PIButton',
  props: {
    kind: String,
    prettykind: String,
    id: String,
  },
  methods: {
    toggleCustomItem() {
      this.$emit('open',this.kind,this.prettykind)
    }
  }
}
</script>