import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuelidate from 'vuelidate'
import axios from 'axios'
import store from '@/store/index'
import filters from './filters';
import './assets/styles/tailwind.css'
import VueGtag from "vue-gtag";
const moment = require('moment')
import VueTippy, { TippyComponent } from "vue-tippy";

Vue.use(VueTippy, {
  directive: "tippy", // => v-tippy
  flipDuration: 0,
  arrow:true,
  popperOptions: {
    modifiers: {
      preventOverflow: {
        enabled: true
      }
    }
  }
});
Vue.component("tippy", TippyComponent);

Vue.use(require('vue-moment'), {
    moment
})

const base = axios.create({
  baseURL: "/"
});

Vue.prototype.$http = base;
Vue.use(vuelidate)
Vue.config.productionTip = false
Vue.use(filters);

Vue.use(VueGtag, {
  config: { id: "G-CKLRFBKXM5" }
}, router);

Vue.mixin({
  methods: {
    trackEvent: function(action,config={}) {
      return this.$gtag.event(action,config) //category label--engagement
    },


  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
