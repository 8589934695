import { v4 as uuidv4 } from 'uuid';
import axios from 'axios'
const getDefaultState = () => {
    return {
        items: [
            {
                area: "Housing",
                category: "Rent and Utilities",
                name: "Rent",
                month: null,
                annual: null,
                id: 3,
            },
            {
                area: "Housing",
                category: "Rent and Utilities",
                name: "AssociationFees",
                month: null,
                annual: null,
                id: 4,
            },
            {
                area: "Housing",
                category: "Rent and Utilities",
                name: "Electricity",
                month: null,
                annual: null,
                id: 5,
            },
            {
                area: "Housing",
                category: "Rent and Utilities",
                name: "Water",
                month: null,
                annual: null,
                id: 6,
            },
            {
                area: "Housing",
                category: "Rent and Utilities",
                name: "Gas",
                month: null,
                annual: null,
                id: 7,
            },
            {
                area: "Housing",
                category: "Rent and Utilities",
                name: "Heating Oil/Kerosene",
                month: null,
                annual: null,
                id: 8,
            },
            {
                area: "Housing",
                category: "Rent and Utilities",
                name: "Trash Disposal",
                month: null,
                annual: null,
                id: 9,
            },
            {
                area: "Housing",
                category: "Rent and Utilities",
                name: "Sewer",
                month: null,
                annual: null,
                id: 10,
            },
            {
                area: "Housing",
                category: "Other Household Costs",
                name: "Bottled Water",
                month: null,
                annual: null,
                id: 11,
            },
            {
                area: "Housing",
                category: "Other Household Costs",
                name: "Security",
                month: null,
                annual: null,
                id: 12,
            },
            {
                area: "Housing",
                category: "Other Household Costs",
                name: "Groundskeeping",
                month: null,
                annual: null,
                id: 13,
            },
            {
                area: "Housing",
                category: "Other Household Costs",
                name: "Water Filtration",
                month: null,
                annual: null,
                id: 14,
            },
            {
                area: "Housing",
                category: "Other Household Costs",
                name: "HOA/Condo fees",
                month: null,
                annual: null,
                id: 15,
            },
            {
                area: "Housing",
                category: "Other Household Costs",
                name: "Parking",
                month: null,
                annual: null,
                id: 17,
            },
            {
                area: "Housing",
                category: "Other Household Costs",
                name: "Household Assistance",
                month: null,
                annual: null,
                id: 189,
            },
            {
                area: "Housing",
                category: "Other Household Costs",
                name: "Childcare (ongoing)",
                month: null,
                annual: null,
                id: 19,
            },
            {
                area: "Housing",
                category: "Other Household Costs",
                name: "Home Repairs",
                month: null,
                annual: null,
                id: 290,
            },
            {
                area: "Housing",
                category: "Other Household Costs",
                name: "Home Insurance (req.)",
                month: null,
                annual: null,
                id: 21,
            },
            {
                area: "Housing",
                category: "Other Household Costs",
                name: "Generator",
                month: null,
                annual: null,
                id: 22
            },
            {
                area: "Children's Education",
                category: "Tuition and Books",
                name: "Public/Private School Tuition",
                month: null,
                annual: null,
                id: 23,
            },
            {
                area: "Children's Education",
                category: "Tuition and Books",
                name: "Home School or Other Curricula",
                month: null,
                annual: null,
                id: 24,
            },
            {
                area: "Children's Education",
                category: "Tuition and Books",
                name: "Testing Fees",
                month: null,
                annual: null,
                id: 25,
            },
            {
                area: "Children's Education",
                category: "Tuition and Books",
                name: "Tutoring",
                month: null,
                annual: null,
                id: 26,
            },
            {
                area: "Children's Education",
                category: "Misc. Costs",
                name: "School Uniforms",
                month: null,
                annual: null,
                id: 27,
            },
            {
                area: "Children's Education",
                category: "Misc. Costs",
                name: "School Trips",
                month: null,
                annual: null,
                id: 28,
            },
            {
                area: "Children's Education",
                category: "Misc. Costs",
                name: "Transportation",
                month: null,
                annual: null,
                id: 29,
            },
            {
                area: "Children's Education",
                category: "Misc. Costs",
                name: "Sports",
                month: null,
                annual: null,
                id: 30
            },
            {
                area: "Children's Education",
                category: "Misc. Costs",
                name: "Clubs",
                month: null,
                annual: null,

                id: 31
            },
            {
                area: "Children's Education",
                category: "Misc. Costs",
                name: "Art / Music Lessons",
                month: null,
                annual: null,
                id: 32
            },
            {
                area: "Children's Education",
                category: "Misc. Costs",
                name: "Technology",
                month: null,
                annual: null,
                id: 33
            },
            {
                area: "Children's Education",
                category: "Misc. Costs",
                name: "Supplies",
                month: null,
                annual: null,
                id: 34
            },
            {
                area: "Personal Expenses",
                category: "Personal",
                name: "Electronics",
                month: null,
                annual: null,
                id: 35
            },
            {
                area: "Personal Expenses",
                category: "Personal",
                name: "Vehicle Costs",
                month: null,
                annual: null,
                id: 37
            },
            {
                area: "Personal Expenses",
                category: "Personal",
                name: "Appliance or Furniture",
                month: null,
                annual: null,
                id: 38
            },
            {
                area: "Personal Expenses",
                category: "Personal",
                name: "College Students Visit Field",
                month: null,
                annual: null,
                id: 39,
            },
            {
                area: "Personal Expenses",
                category: "Personal",
                name: "Hardship Vacation Travel",
                month: null,
                annual: null,
                id: 409,
            },
            {
                area: "Personal Expenses",
                category: "Personal",
                name: "Add-On Mileage",
                month: null,
                annual: null,
                id: 41
            },
            {
                area: "Personal Expenses",
                category: "Personal",
                name: "Naturalization Costs",
                month: null,
                annual: null,
                id: 42
            },
            {
                area: "Personal Expenses",
                category: "Personal",
                name: "Clothes",
                month: null,
                annual: null,
                id: 43
            },
            {
                area: "Personal Expenses",
                category: "Personal",
                name: "Groceries",
                month: null,
                annual: null,
                id: 44
            },
            {
                area: "Personal Expenses",
                category: "Personal",
                name: "Leisure/Hobbies",
                month: null,
                annual: null,
                id: 45
            },
            {
                area: "Personal Expenses",
                category: "Personal",
                name: "Personal Savings",
                month: null,
                annual: null,
                id: 46
            },
            {
                area: "Personal Expenses",
                category: "Personal",
                name: "Retirement Savings",
                month: null,
                annual: null,
                id: 47
            },
            {
                area: "Personal Expenses",
                category: "Personal",
                name: "Travel",
                month: null,
                annual: null,
                id: 48
            },
            {
                area: "Personal Expenses",
                category: "Personal",
                name: "Rainy Day Fund",
                month: null,
                annual: null,
                id: 49
            },
            {
                area: "Personal Expenses",
                category: "Personal",
                name: "Tax Liability and Fees",
                month: null,
                annual: null,
                id: 50
            },

        ],
        isProcessing: false,
        currency: null,
        currencyOffset: 1
      }
}
export default {
  namespaced:true,
  state: getDefaultState(),
  getters: {
    getBudget(state) {
      return state.items
    },
    budgetMonthlyTotal(state) {
        return state.items
          .reduce(function (acc, obj) {
              return acc
                + ((parseFloat(obj.month)||0) * (parseFloat(((obj.monthOffset)?obj.monthOffset:1))||1))
            }, 0)
    },
    budgetAnnualTotal(state,getters) {
        return getters.budgetMonthlyTotal*12
    },
    hasOffset(state) {
        let offsetTotal = state.items
          .reduce(function (acc, obj) { return acc + (parseFloat(obj.monthOffset)||0) + (parseFloat(obj.annualOffset)||0) }, 0)
        return offsetTotal % 2 == 0 ? false : true
    }
  },
  actions: {
    updateBudget({commit},budget) {
        commit('setBudget',budget)
    },
    updateCurrency({commit},currency) {
        commit('setProcessing', true)
        axios
          .get('/.netlify/functions/currency', {
              params: {
                  symbol: currency
              },
              headers: {
                  'jwt': localStorage.getItem("jwt"),
              }
          })
          .then(response => response.data)
          .then(rates => {
            const offset = parseFloat(rates.USD)/parseFloat(rates[currency]) || 1

            //TODO loop through and get all offset values and then update
            commit('swapOffsets',offset)

            commit('setCurrency',{currency,offset})
            commit('setProcessing', false)
          })
          .catch(error => {
            console.log(error)
            commit('setProcessing', false)
         })
    },
    addCategory({commit},item) {
        if(!item.id) item.id = uuidv4()
        commit('setCategory',item)
        return item.id
    },
    resetBudget({commit}) {
        commit('resetState')
    }
  },
  mutations: {
    swapOffsets(state,offset) {
        state.items  = state.items
            .map(item => {
                if(item.annualOffset)
                    item.annualOffset = offset
                if(item.monthOffset)
                    item.monthOffset = offset
                return item
            })
    },
    setCurrency(state,{currency,offset}) {
        //{"USD":1.18054,"TRY":10.062573}
        state.currencyOffset= offset
        state.currency = currency
    },
    setBudget(state,items) {
      state.items  = items
    },
    setProcessing(state,processing) {
      state.isProcessing = processing
    },
    resetState (state) {
      Object.assign(state, getDefaultState())
    }
  }

}
