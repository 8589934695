<template>
  <div>


    <h2 class="text-center  text-xl py-3 font-semibold	">In January 2022, what will your estimated household look like? 
      <TWQType qtype="actual" />
    </h2>

    <fieldset class="space-y-6 max-w-3xl text-left mx-auto">
      <legend id="radiogroup-label" class="sr-only">
      Select One
      </legend>

      <ul class="space-y-4" role="radiogroup" aria-labelledby="radiogroup-label">
        <li v-for="(plan,index) in plans" :key="plan.price" @click="pickPlan(plan)" :aria-checked="selectedPlan.plantype===index ? 'true' : 'false'" :id="'radiogroup-option-'+index" role="radio" class="group relative bg-white rounded-lg shadow-sm cursor-pointer focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-indigo-500">
          <div class="rounded-lg border border-gray-300 px-6 py-4 hover:border-gray-400 sm:flex sm:justify-between">

            <div class="flex text-left">
              <div class="text-sm">
                <p class="font-medium text-gray-900">
                  {{plan.name}}
                </p>
                <div class="text-gray-500">
                  <p class="sm:inline">{{plan.description}}</p>
                </div>
              </div>
            </div>
            <div class="mt-2 flex text-sm sm:mt-0 sm:block sm:ml-4 sm:text-right">
              <div class="text-3xl text-gray-900">{{plan.headcount}}</div>
              <div class="ml-1 text-gray-500 sm:ml-0">people</div>
            </div>
          </div>
          <!-- On: "border-indigo-500", Off: "border-transparent" -->
          <div class="absolute inset-0 rounded-lg border-2 pointer-events-none" :class="selectedPlan.plantype===index ? 'border-indigo-500' : 'border-transparent'" aria-hidden="true"></div>
        </li>
      </ul>
    </fieldset>
    <div v-if="$v.selectedPlan.$error" class="error">you should pick a plan to continue</div>
  </div>
</template>

<script>
import {required} from 'vuelidate/lib/validators'
import TWQType from './TWQType.vue'
export default {
  components: {
    TWQType
  },
  computed: {
    form() {
      return this.$store.state.estimator
    }
  },
  data() {
    return {
      plans: [
        {
          plantype: 0,
          headcount: '1',
          name: 'Single',
          description: 'For all single workers, widowed workers, or workers who no longer have any other dependents.'
        },
        {
          plantype: 1,
          headcount: '2+',
          name: 'Multi-Person Household',
          description: 'Married couples with or without dependents, Singles with dependents at home or in post-secondary education'
        }
      ],
      selectedPlan: null
    }
  },
  validations: {
    selectedPlan: {
      required
    }
  },
  created() {
    this.selectedPlan = this.form.plan
    this.$emit('planChange', this.form.plan.plantype)
  },
  methods: {
    pickPlan (plan) {
      this.selectedPlan = plan
      this.submit()
    },
    submit () {
      this.$emit('update', {
        data: {
          plan: this.selectedPlan,
        },
        valid: !this.$v.$invalid
      })
      this.$emit('planChange', this.selectedPlan.plantype)
    }
  }
}
</script>