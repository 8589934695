<template>
  <div class="bg-white overflow-hidden shadow sm:rounded-lg">
    <loading :active.sync="isProcessing"
        color="#3B82F6"
        :width="128"
        :height="128"
        backgroundColor="#fff"
        :opacity="0.5"
    ></loading>
    <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
      <div class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
        <div class="ml-4 mt-2">
            <h2 class="text-xl mb-3 font-bold leading-7 text-gray-600 sm:text-2xl sm:truncate">

<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6  inline pb-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>
            Frequently Asked Questions
            </h2>

        </div>
      </div>
    </div>
    <div >
      <div class="max-w-7xl mx-auto py-0 px-4 sm:px-6 lg:px-8">
        <dl class="mt-6 space-y-6 divide-y divide-gray-200">

          <div class="pt-6" v-for="item in faqs" :key="item._id">
            <dt class="text-lg">
              <!-- Expand/collapse question button -->
              <button @click="toggleActive(item)" type="button" class="text-left w-full flex justify-between items-start text-gray-400 border-0" aria-controls="faq-0" aria-expanded="false">
                <span class="font-medium text-gray-900">
                {{item.question}}
                </span>
                <span class="ml-6 h-7 flex items-center">
                  <!--
                    Expand/collapse icon, toggle classes based on question open state.

                    Heroicon name: outline/chevron-down

                    Open: "-rotate-180", Closed: "rotate-0"
                  -->
                  <svg class="h-6 w-6 transform" :class="activeFAQ == item._id ? '-rotate-180' : 'rotate-0'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                  </svg>
                </span>
              </button>
            </dt>
            <dd class="mt-2 pr-12" id="faq-0">
              <p class="text-base text-gray-500" v-html="item.answer" v-if="activeFAQ == item._id">
              </p>
            </dd>
          </div>






        </dl>
      </div>
    </div>
    <!--- popup definition--->
    <TWModal
      v-if="showModal"
      :message="message"
      @close="closeFootnote"
    />
  </div>

</template>

<script>
import {mapState,mapActions} from 'vuex'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import TWModal from './TWModal.vue'

export default {
  components: {
    TWModal,
    Loading
  },
  data () {
    return {
      showModal: false,
      activeFAQ: null,
      message:null,
      modalMessages: [
        "This echoes our PCA Book of Church Order language that pastors should be ‘free from worldly cares and avocations’ (BCO 20-6). Workers should not need to seek additional employment in order to make basic ends meet.",
        "To illustrate the complexity of our current system, see the question regarding the primary change to base salary.",
        "Under the new structure, each spouse would be employed in their own right and receive salary and benefits individually.",
        "This information will continue to be obtained from the PCA Administrative Committee office.",
        "Implementation of the 529 plan is based on programming and procurement details. As such, the Post-Secondary Education allowance will continue as is until the 529 plan is implemented.",
        "Eligibility for non-taxable continuing education is dependent on active (paid) employment. Approved Continuing Education expenses for unpaid spouses can be reimbursed as taxable income."
      ]
    }
  },
  computed: {
      ...mapState('faq',{
          faqs: state => state.items,
          isProcessing: state => state.isProcessing,
      }),
      // sorted() {
      //     return this.pray.slice(0).sort(function(a,b) {
      //       return a.meta.daynumber - b.meta.daynumber;
      //     });
      // }
  },
  name: 'Faqs',
  created () {
    this.$emit('ready')
  },
  mounted () {
    if(this.faqs.length ==0) {
      this.loadFaqs()
    }
  },
  methods: {
    ...mapActions('faq',{
        loadFaqs : 'loadFaqs',
    }),
    toggleActive(item) {
      this.activeFAQ = item._id
    },
    showFootnote (id) {
      this.message = this.modalMessages[id]
      this.showModal = true
    },
    closeFootnote () {
      this.showModal = false
    }
  }
}
</script>
<style>
.sub, .sup { font-size: 75%; line-height: 0; position: relative; vertical-align: baseline; }
.sup { top: -0.3em; vertical-align: super;}
.sub { bottom: -0.25em; vertical-align: sub;}
</style>