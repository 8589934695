<template>
  <div class="bg-white overflow-hidden shadow sm:rounded-lg">
    <loading :active.sync="isProcessing"
        color="#3B82F6"
        :width="128"
        :height="128"
        backgroundColor="#fff"
        :opacity="0.5"
    ></loading>
    <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
      <div class="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
        <div class="ml-4 mt-4 flex-grow">
          <h3 class="text-xl leading-6 font-medium text-gray-900">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline text-indigo-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z" />
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z" />
            </svg>
            Personal Budget Planner
          </h3>
        </div>
        <div class="ml-4 mt-4">
            <div class="flex-col">
              <div class="mt-1 text-sm text-gray-500 pr-5">
                <label for="fieldCurrency" class="block text-xs font-medium text-gray-700">Field Currency</label>
                <select @input="changeCurrency($event)" :v-model="currentName"  name="fieldCurrency" id="fieldCurrency" class="mt-1 block w-32 pl-3 pr-10 py-2 text-xs border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs rounded-md">
                  <option value="" :selected="currentName != '' ? 'selected' : ''"></option>
                  <option v-for="(value,key,index) in allCurrencies" :key="index" :value="key" :selected="currentName == key ? 'selected' : ''">{{value}}</option>
                </select>
              </div>
              <div class="text-xs text-gray-400" v-if="currentName">
              1 USD = {{currentSymbol}}{{currencyOffset | dollarAmt(2)}}
              </div>
            </div>
        </div>
        <div v-if="currentName" class="ml-4 mt-4 justify-items-end flex pr-5">
          <div class="flex-col">
            <div class="flex items-baseline text-2xl font-semibold text-green-600">
            {{currentSymbol}} <Roller :text="fieldCurrencyMonthly | dollarAmt(0)" :transition="0.5" defaultChar="0" :wordWrap="8"></Roller>/mo
            </div>
            <div class="flex items-baseline ml-0 text-sm font-medium text-gray-400">
                <span >{{currentSymbol}} {{fieldCurrentAnnual | dollarAmt(2)}} annual</span>
            </div>
          </div>

        </div>
        <div class="ml-4 mt-4 flex-shrink-0 flex pr-5">
          <div class="flex-col">
            <div class="flex items-baseline text-2xl font-semibold text-indigo-600">
            $<Roller :text="budgetTotal | dollarAmt(0)" :transition="0.5" defaultChar="0" :wordWrap="8"></Roller>/mo
            </div>
            <div class="flex items-baseline ml-0 text-sm font-medium text-gray-400">
                <span >${{annualTotal | dollarAmt(2)}} annual</span>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div >
      <div class="max-w-7xl mx-auto py-0 px-2 sm:py-6 sm:px-3 lg:px-4 ">
        <div v-for="(item,index) in budgetareas" :key="index">
          <h4 class="text-xl leading-6 font-medium text-gray-900 p-2">{{item}}</h4>
          <!-- This example requires Tailwind CSS v2.0+ -->
          <div class="flex flex-col" v-for="(category,innerIndex) in budgetcategories(item)" :key="innerIndex" >
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg mb-4">
                  <table class="min-w-full divide-y divide-gray-200 ">
                    <thead class="bg-gray-50">
                      <tr>
                        <th scope="col" class="w-1/3 px-6 py-3 text-left text-xs font-medium text-gray-800 uppercase tracking-wider">
                        {{category}}
                        </th>
                        <th scope="col" class="w-1/3 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Monthly
                        </th>
                        <th scope="col" class="w-1/3 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Annually
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item,itemIndex) in items(category)" :key="itemIndex" :class="itemIndex%2 == 0 ? 'bg-white' : 'bg-gray-50'">
                        <td class="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                          {{item.name}}
                        </td>
                        <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                          <MoneyInput
                            :item="item"
                            itemType="month"
                            @update="updateMonthly"
                            @swapCurrency="changeMoneyCurrency"
                            :currency="currency"
                            :currencyOffset="currencyOffset"
                            />
                        </td>
                        <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                          <MoneyInput
                            :item="item"
                            itemType="annual"
                            @update="updateAnnual"
                            @swapCurrency="changeMoneyCurrency"
                            :currency="currency"
                            :currencyOffset="currencyOffset"
                            />
                        </td>
                      </tr>

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer class="flex items-center place-content-center justify-center	justify-items-center place-items-center justify-center text-xs">
          **This budget tool was adapted from the Word Document available on Circle provided by the Resource Team.
          <button
            @click="toggleBudgetResetModal"
            class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
          >
            <!-- Heroicon name: solid/mail -->
            <svg class="-ml-0.5 mr-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clip-rule="evenodd" />
            </svg>
            Reset
          </button>
        </footer>

      </div>

    </div>
    <TWModal
      v-if="showCurrencyReset"
      :modalType="'accept'"
      acceptText="Yes, I want to change the currency."
      :icon="'M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4'"
      iconclass="text-green-400"
      @accept="continueCurrencyReset"
      @close="cancelCurrencyReset">
      <h2 class="text-xl pb-3 pt-2 font-semibold">Change Field Currency</h2>
      <p class="text-xs">If you've setup a different currency already, this will change these same values to the new currency.</p>
      <p class="text-xs mt-3">Are you OK with that?</p>
    </TWModal>
    <TWModal
      v-if="showBudgetResetModal"
      :modalType="'accept'"
      acceptText="Yes, let's reset"
      iconclass="text-red-400"
      :icon="'M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16'"
      @accept="reset"
      @close="toggleBudgetResetModal">
      <h2 class="text-xl pb-3 pt-2 font-semibold">Reset Budget Data</h2>
      <p class="text-xs">This will clear out your budget and erase your current data from this device.</p>
      <p class="text-xs mt-3">Are you really sure you want to start over?</p>
    </TWModal>
  </div>

</template>

<script>
const getSymbolFromCurrency = require('currency-symbol-map')
const currencies = require('../../public/currencies.json')
import {mapState,mapActions,mapGetters} from 'vuex'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import MoneyInput from './TWMoneyInput'
import TWModal from './TWModal'
import Roller from "vue-roller"
import _ from 'lodash'

export default {
  components: {
    Loading,
    TWModal,
    MoneyInput,
    Roller
  },
  data () {
    return {
      budgetitems: [],
      showModal: false,
      tempCurrency: null,
      currentName:null,
      allCurrencies: currencies,
      showCurrencyReset: false,
      showBudgetResetModal: false

    }
  },
  computed: {
      ...mapState('planner',{
          budget: state => state.items,
          currency: state => state.currency,
          currencyOffset: state => state.currencyOffset,
          isProcessing: state => state.isProcessing,
      }),
      ...mapGetters('planner',{
            budgetTotal : 'budgetMonthlyTotal',
            annualTotal : 'budgetAnnualTotal',
            hasOffset : 'hasOffset'
      }),
      fieldCurrencyMonthly() {
        return this.budgetTotal/this.currencyOffset
      },
      fieldCurrentAnnual() {
        return this.fieldCurrencyMonthly*12
      },
      sorted() {
          return this.budgetitems.slice(0).sort(function(a,b) {
            return a.area - b.area;
          });
      },
      budgetareas() {
          const areas = new Set()
          this.budgetitems.forEach(item => areas.add(item.area))
          return Array.from(areas)
      },
      currentSymbol() {
        return getSymbolFromCurrency(this.currency)
      }
  },
  name: 'Budget',
  created () {
    this.$emit('ready')
  },
  mounted () {
    this.budgetitems = this.budget
    this.currentName = this.currency
  },
  methods: {
    ...mapActions('planner',{
        updateBudget: 'updateBudget',
        updateCurrency: 'updateCurrency',
        resetBudget: 'resetBudget'
    }),
    budgetcategories(area) {
      return this.budgetitems
        .filter(item => item.area === area)
        .map(item => item.category)
        .filter((v, i, a) => a.indexOf(v) === i)
    },
    items(category) {
      return this.budgetitems
        .filter(item => item.category === category)
    },
    updateMonthly(id) {
      this.budgetitems.filter(x => x.id === id)
        .map(item => {
          let muliplier = (parseFloat(item.monthOffset)||1)/(parseFloat(item.annualOffset)||1)
          item.annual = Math.round(item.month*muliplier*12*100)/100
          return item
        })
      const newarray = []
      Object.assign(newarray, this.budgetitems)
      this.submit(newarray)
    },
    updateAnnual(id) {
      this.budgetitems.filter(x => x.id === id)
        .map(item => {
          let muliplier = (parseFloat(item.annualOffset)||1)/(parseFloat(item.monthOffset)||1)
          item.month = Math.round(muliplier*item.annual/12*100)/100
          return item
        })
      const newarray = []
      Object.assign(newarray, this.budgetitems)
      this.submit(newarray)
    },
    changeMoneyCurrency(id,itemtype,offset) {
      this.budgetitems.filter(x => x.id === id)
        .map(item => {
          if(itemtype=="month") {
            if(offset)
              item.monthOffset = offset
            else
              delete item.monthOffset
            let muliplier = (parseFloat(item.monthOffset)||1)/(parseFloat(item.annualOffset)||1)
            item.annual = Math.round(item.month*muliplier*12*100)/100
          }
          else if(itemtype=="annual") {
            if(offset)
              item.annualOffset = offset
            else
              delete item.annualOffset
            let muliplier = (parseFloat(item.annualOffset)||1)/(parseFloat(item.monthOffset)||1)
            item.month = Math.round(muliplier*item.annual/12*100)/100
          }
          return item
        })
      const newarray = []
      Object.assign(newarray, this.budgetitems)
      this.submit(newarray)
    },
    changeCurrency(event) {
      event.preventDefault()
      this.tempCurrency = event.target.value
      if(this.hasOffset) {
        this.toggleCurrencyReset()
      }else{
        this.continueCurrencyReset()
      }
    },
    continueCurrencyReset() {
      if(this.tempCurrency) {
        this.updateCurrency(this.tempCurrency)
        this.currentName = this.tempCurrency
      }
      this.tempCurrency = null
      this.toggleCurrencyReset()
    },
    cancelCurrencyReset() {
      this.currentName = this.currency
      document.getElementById('fieldCurrency').value = this.currentName
      this.toggleCurrencyReset()
    },
    toggleCurrencyReset() {
      this.showCurrencyReset = !this.showCurrencyReset
    },
    toggleBudgetResetModal() {
      this.showBudgetResetModal = !this.showBudgetResetModal
    },
    // submit (newarray) {
    //     this.updateBudget(newarray)
    // },
    submit: _.debounce(function (newarray) {
        this.updateBudget(newarray)
    }, 500),
    reset() {
      this.resetBudget()
      this.trackEvent('budget_reset')
      window.location.reload()
    },
  }
}
</script>
<style>
.sub, .sup { font-size: 75%; line-height: 0; position: relative; vertical-align: baseline; }
.sup { top: -0.3em; vertical-align: super;}
.sub { bottom: -0.25em; vertical-align: sub;}
</style>