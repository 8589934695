<template>
  <div class="frill-component">
    <p>
      <span class="frill-container text-base font-medium text-gray-400 hover:text-gray-900 cursor-pointer" >
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
        </svg>
      </span>
    </p>
  </div>
</template>
<script>
export default {
    name: 'FrillWidget',
    data: () => ({
        frillWidget: undefined,
    }),
    mounted() {
        this.setupFrillWidget();
    },
    beforeUnmount() {
        if (this.frillWidget) {
            this.frillWidget.destroy();
            this.frillWidget = undefined;
        }
    },
    methods: {
        setupFrillWidget() {
            window.Frill_Config = {
                selector: '.frill-container',
                token: 'd77c4f78-c170-4f75-a806-81f1fd59f35f',
                position: 'fixed',
                offset: [0, 10],
                callbacks: {
                    onReady: (frillWidget) => {
                        this.frillWidget = frillWidget;
                    },
                },
            };
            if ('Frill' in window) {
                this.frillWidget = window.Frill.widget(window.Frill_Config);
            }
        },
    },
}
</script>