<template>
  <div >

    <div id="estimatorwizard" class="flex flex-col md:flex-row sm:my-6 md:my-3 overflow-hidden shadow rounded-lg bg-gradient-to-r from-blue-50 to-white bg-opacity-75" >

        <!-- Wizard Navigation -->
        <div class="hidden print:hidden lg:block flex-none w-40 py-8 px-4 items-center justify-center text-transparent bg-gradient-to-r from-clearstop-100 to-clearstart-100">


          <nav class="justify-center" aria-label="Progress">
            <ol class="space-y-6">
              <li v-for="(step, index) in steps" :key="step">
                <!-- Complete Step -->
                <a v-if="index+1 < currentStepNumber" @click="navigateStep(index)" class="group cursor-pointer">
                  <span class="flex items-start relative">
                    <span v-if="index+1 < length" class="absolute top-5 left-2.5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                    <span class="flex-shrink-0 relative h-5 w-5 flex items-center justify-center">
                      <!-- Heroicon name: solid/check-circle -->
                      <svg class="h-full w-full text-indigo-600 group-hover:text-indigo-800" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                        <text x="0" y="0" fill="red">{{index+1}}</text>
                      </svg>
                    </span>
                    <span class="ml-3 text-sm font-medium text-gray-400 group-hover:text-gray-600">{{step | stepify}}</span>
                  </span>
                </a>
                <!-- Current Steps -->
                <a v-else-if="index+1 == currentStepNumber" @click="navigateStep(index)" class="group cursor-pointer flex items-start relative" aria-current="step">
                  <span v-if="index+1 < length" class="absolute top-5 left-2.5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                  <span v-if="steps.length!=currentStepNumber" class="flex-shrink-0 h-5 w-5 relative flex items-center justify-center" aria-hidden="true">
                    <span class="absolute h-4 w-4 rounded-full bg-indigo-200"></span>
                    <span class="relative block w-2 h-2 bg-indigo-600 rounded-full" ></span>
                  </span>
                  <span v-else class="flex-shrink-0 relative h-5 w-5 flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-full w-full text-green-600 group-hover:text-green-800" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                    </svg>
                  </span>
                  <span class="ml-3 text-sm font-bold " :class="steps.length===currentStepNumber ? 'text-green-600 group-hover:text-green-800' : 'text-indigo-400'">{{step | stepify}}</span>
                </a>
                <!-- Upcoming Steps -->
                <a v-else @click="navigateStep(index)" class="group cursor-pointer">
                  <div class="flex items-start relative">
                    <span v-if="index+1 < length" class="absolute top-5 left-2.5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                    <div class="flex-shrink-0 h-5 w-5 relative flex items-center justify-center" aria-hidden="true">
                      <div class="h-2 w-2 bg-gray-300 rounded-full group-hover:bg-gray-400"></div>
                    </div>
                    <!-- {{index+1===steps.length ? '' : index+1+'.'}} -->
                    <p class="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">{{step | stepify}}</p>
                  </div>
                </a>
              </li>
            </ol>
          </nav>
          <div class="items-center mx-auto p-4 mt-2">
            <button @click="toggleInstructionsModal" type="button" class="inline-flex items-center px-3.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-blue-50 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <!-- Heroicon name: information/circle -->
              <svg class="-ml-0.5 mr-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              Help
            </button>
          </div>
        </div>
        <div class="z-10 px-4 py-5 sm:p-12 flex-grow mx-auto items-center justify-center">
            <div v-if="wizardInProgress" v-show="asyncState !== 'pending'">

              <!-- Form Screens
                enter-active-class="transition ease-in duration-500"
                enter-from-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="transition ease-out duration-500"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
                              -->
              <transition
                name="fade"
                mode="out-in"
              >
                <keep-alive>
                    <component
                      class="md:max-w-6xl md:min-h-full"
                      ref="currentStep"
                      v-bind:is="currentStep"
                      @update="processStep"
                      @planChange="updatePlan"
                      :wizard-data="form">
                    </component>
                </keep-alive>
              </transition>

              <!-- Actions -->
              <div class="mx-auto items-center justify-center text-center">
                <div class="buttons py-6">
                  <button
                    @click="goBack"
                    v-if="currentStepNumber > 1"
                    class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Back
                  </button>
                  <button v-if="!isLastStep"
                    @click="nextButtonAction"
                    :disabled="!canGoNext"
                    class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >{{isLastStep ? 'Complete' : 'Next'}}</button>
                  <button v-else
                    @click="toggleEstimatorModal"
                    class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-400 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                  >
                    <!-- Heroicon name: solid/mail -->
                    <svg class="-ml-0.5 mr-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clip-rule="evenodd" />
                    </svg>
                    Start Over
                  </button>
                </div>
              </div>

              <!-- <pre class="border bg-gray-900 text-white"><code>{{form}}</code></pre> -->
            </div>
            <div v-else>
              <h1 class="title">Thank You!</h1>

              <h2 class="subtitle">
              You estimated your payroll for Clearcomp.
              </h2>
            </div>

            <div class="loading-wrapper" v-if="asyncState === 'pending'">
              <div class="loader">
                <img src="/spinner.svg" alt="">
                <p>Please wait, we're hitting our servers!</p>
              </div>
            </div>
        </div>
    </div>

    <TWModal
      v-if="!acceptedDisclaimer"
      :modalType="'accept'"
      :icon="'M20.618 5.984A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016zM12 9v2m0 4h.01'"
      @accept="acceptDisclaimer"
      @close="rejectDisclaimer">
      <h2 class="text-xl pb-3 pt-2 font-semibold">Advice Disclaimer</h2>
      <p class="text-xs">The information on the Service is provided with the understanding that it is not herein engaged in rendering legal, accounting, tax, or other professional advice and services. As such, it should not be used as a substitute for consultation with professional accounting, tax, legal or other competent advisers.</p>
      <p class="mt-3 text-xs">In no event shall this service or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever arising out of or in connection with your access or use or inability to access or use the service.</p>
      <p class="mt-3 text-xs">The information provided by this tool is for informational purposes and is for estimation and planning purposes only. No data is stored on this service.</p>
    </TWModal>
    <TWModal
      v-if="showEstimatorResetModal"
      :modalType="'accept'"
      acceptText="Yes, let's reset"
      :icon="'M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16'"
      @accept="resetEstimator"
      @close="toggleEstimatorModal">
      <h2 class="text-xl pb-3 pt-2 font-semibold">Reset Estimator Data</h2>
      <p class="text-xs">This will restart you at the beginning of the estimator and erase your current data from this device.</p>
      <p class="text-xs mt-3">Are you sure you want to start over?</p>
    </TWModal>
    <TWModal
      v-if="showInstructionsModal"
      @close="toggleInstructionsModal">
      <h2 class="text-xl pb-3 pt-2 font-semibold">Estimator Tips</h2>
      <div class="text-left mx-auto text-xs font-normal text-gray-500">
          <p>
            <span class="text-sm font-semibold">Preview Data:</span> Data shown in the prevew is based off the best estimated data for 2022.  The values that will change before 2022,
            may include changes to changes to: base salary, taxes, goods and services (cost of living).  Other benefit amounts such as office admin fee, health insurance, and other benefit amts could change slightly as well.
            **The preview estimator also does not include these items:
            <ul class="list-decimal ml-6">
              <li>Taxable life insurance</li>
              <li>One-off deductions</li>
              <li>Office admin fee</li>
              <li>HMA and Reserve</li>
              <li>Ministry and Team Expenses</li>
            </ul>
          </p>
          <p class="mt-3">
          <span class="text-sm font-semibold">Question Types:</span>
          There are two types of questions in the estimator, some are ACTUAL values which are historical and some are ESTIMATED (or guess-timated) amounts for which you are planning for 2022.
          These are highlighted on the question to indicate which is which: <br/>
          ACTUAL questions will have this icon:
          <svg  class="text-indigo-300 inline h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
          </svg> <br/>
          ESTIMATED questions will have this icon:
          <svg class="text-pink-300 inline h-5 w-5"  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z" />
          </svg>
          </p>
        <p class="mt-3">
          <span class="text-sm font-semibold">Trouble? Questions?:</span> Use the chat function on the bottom right, to let us know what's happening for you.
        </p>

      </div>
    </TWModal>
  </div>


</template>

<script>
import FormStart from './FormReadyToStart'
import FormPlanPicker from './FormPlanPicker'
import FormComparisonReport from './FormComparisonReport'
import FormDependents from './FormDependents'
import FormLeadership from './FormLeadership'
import FormAdditionalContributions from './FormAdditionalContributions'
import FormUSAWorkers from './FormHomeWorkers'
import FormTaxes from './FormTaxes'
import FormPlanPreview from './FormPlanPreview'
import {mapState,mapGetters,mapActions} from 'vuex'
import TWModal from './TWModal'
const delay = ms => new Promise(res => setTimeout(res, ms));

export default {
  name: 'FormWizard',
  components: {
    FormStart,
    FormComparisonReport,
    FormPlanPicker,
    FormLeadership,
    FormAdditionalContributions,
    FormDependents,
    FormUSAWorkers,
    FormTaxes,
    FormPlanPreview,
    TWModal
  },
  data () {
    return {
      asyncState: null,
      showStats: false,
      showEstimatorResetModal:false,
      showInstructionsModal: false,
      currentStepNumber: null,
      canGoNext: false,
      steps: [
        'FormStart',
        'FormPlanPicker',
        'FormComparisonReport',
        'FormLeadership',
        'FormAdditionalContributions',
        'FormUSAWorkers',
        'FormTaxes',
        'FormPlanPreview'
      ],
    }
  },
  created () {
    this.$emit('ready')
  },
  mounted () {

    // update plantype
    this.updatePlan(this.form.plan.plantype)

    // jump to last spot in estimator
    let lastStep = this.steps.indexOf(this.form.currentStep)
    if(lastStep > 0) {
      this.currentStepNumber = lastStep+1
      this.canGoNext = true
    }else{ // just in case we set the original list to null
      this.currentStepNumber = 1
    }

  },
  filters: {
    stepify: function (value) {
      return value.slice(4).replace(/([A-Z]+)/g, ' $1').trim()
    }
  },
  computed: {
    ...mapState({
      form: state => state.estimator,
    }),
    ...mapGetters({
      isTwoPlan: 'estimator/isTwoPlan',
      furthestStep: 'estimator/furthestStep',
      acceptedDisclaimer: 'disclaimer/acceptedDisclaimer'
    }),
    isLastStep () {
      return this.currentStepNumber === this.length
    },
    wizardInProgress () {
      return this.currentStepNumber <= this.length
    },
    wizardShowProgress () {
      return this.currentStepNumber > 1
    },
    progress () {
      return this.currentStepNumber/this.length * 100
    },
    length () {
      return this.steps.length
    },
    currentStep () {
      return this.steps[this.currentStepNumber-1]
    }
  },
  methods: {
    ...mapActions({
      updateData : 'estimator/updateData',
      togglePlan : 'estimator/togglePlan',
      acceptDisclaimer : 'disclaimer/acceptDisclaimer',
      updateUserStep : 'estimator/updateUserStep',
      maxFurthestStep : 'estimator/maxFurthestStep',
      resetFurthestStep : 'estimator/resetFurthestStep',
      resetCalculator : 'estimator/resetEstimator'
    }),
    navigateStep(step) {
      if(this.furthestStep > step)
        this.currentStepNumber = step+1
    },
    rejectDisclaimer() {
      this.$router.push('/program')
    },
    toggleInstructionsModal() {
      this.showInstructionsModal = !this.showInstructionsModal
    },
    toggleEstimatorModal() {
      this.showEstimatorResetModal = !this.showEstimatorResetModal
    },
    resetEstimator() {
      this.resetCalculator()
      this.trackEvent('estimator_reset')
      window.location.reload()
    },
    submitOrder () {
      this.asyncState = 'pending'
      // postFormToDB(this.form)
      //   .then(() => {
      //     console.log('form submitted', this.form)
      //     this.asyncState = 'success'
      //     this.currentStepNumber++
      //   })
    },
    nextButtonAction () {
      if(this.isLastStep)
        this.submitOrder()
      else
        this.goNext()
    },
    processStep (step) {
      this.updateData(step.data)
      if(!step.valid) { //if there is an error, fall back to this section.
        this.resetFurthestStep(this.currentStepNumber)
      }
      this.canGoNext = step.valid
    },
    updatePlan() {
      if(this.isTwoPlan) {
        if(this.steps.indexOf("FormDependents") < 0) {
          this.steps.splice(3,0,'FormDependents')
        }
      }else {
        this.togglePlan(false) //for preview page... turns the toggle off
        if(this.steps.indexOf('FormDependents') > 0) {
          this.steps.splice(3,1)
        }
      }
    },
    goBack () {
      this.currentStepNumber--
      this.canGoNext = true
    },
    async goNext () {
      this.$refs.currentStep.$v.$touch()
      console.log('Attempting step forward.')
      if(!this.$refs.currentStep.$v.$invalid) {
        this.currentStepNumber++
        this.maxFurthestStep(this.currentStepNumber) //keep increasing your furthest completed until error
        this.$nextTick(async () =>  {
          this.updateUserStep(this.currentStep)
          this.trackEvent('wizard_step',{
            'step_name': this.currentStep
          })
          await delay(1000);
          this.canGoNext = !this.$refs.currentStep.$v.$invalid
        })
      }

    }
  }
}
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  opacity: 1;
  transition: all 0.6s;
}
.fade-enter,
.fade-leave-to {
  transform: translateY(5px);
  opacity: 0;
}
</style>