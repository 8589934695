<template>
  <div class="container mx-auto">

    <h2 class="text-center text-2xl py-2 font-semibold">

      Let's get started

    <svg xmlns="http://www.w3.org/2000/svg" class="inline h-10 w-10 text-indigo-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11.933 12.8a1 1 0 000-1.6L6.6 7.2A1 1 0 005 8v8a1 1 0 001.6.8l5.333-4zM19.933 12.8a1 1 0 000-1.6l-5.333-4A1 1 0 0013 8v8a1 1 0 001.6.8l5.333-4z" />
    </svg>

      </h2>
<div class="text-center text-sm text-gray-400">Watch a <a href="https://vimeo.com/572501750/1558dc0a87" target="_tutorials" class="underline font-bold">Walkthrough</a> video.</div>

  <fieldset class="p-3 space-y-4 max-w-2xl text-left mx-auto md:p-12">
    <div class="space-y-5 max-w-xl text-left mx-auto">
         <h3 class="text-center text-xl py-3">What's your target monthly budget (net/take-home)?
          <TWQType qtype="estimated" />
         </h3>

         <!-- <label for="price" class="block text-sm font-medium text-gray-700">Price</label> -->
        <div class="flex flex-col">
          <div class="mb-3 pt-0 relative rounded-md shadow-sm md:h-15 text-sm md:text-2xl">
              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <span class="text-gray-500">
                  $
              </span>
              </div>
              <input v-model="$v.monthlytarget.$model" @keyup="selectDocument()" type="number" name="price" id="price" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full md:h-15 pl-12 pr-16 text-sm md:text-2xl text-gray-500 text-right border-gray-300 rounded-md" placeholder="0.00" aria-describedby="price-currency">
              <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <span class="text-gray-500" id="price-currency">
                  USD
              </span>
              </div>
          </div>
          <div v-if="monthlytarget" class="text-right text-xs text-gray-400">
          or ${{parseFloat(monthlytarget)*12 | dollarAmt}} USD annual
          </div>
        </div>
        <TWAlert v-if="$v.monthlytarget.$error && !$v.monthlytarget.required" alerttype="error" message="You'll need a target take-home amount."></TWAlert>
        <TWAlert v-if="$v.monthlytarget.$error && !$v.monthlytarget.decimal" alerttype="error" message="Take-home needs to be a valid USD amount. "></TWAlert>
        <TWAlert v-if="$v.monthlytarget.$error && $v.monthlytarget.decimal && !$v.monthlytarget.minValue" alerttype="error" message="Take-home should be a positive monthly dollar amt."></TWAlert>
        <TWAlert v-if="$v.monthlytarget.$error && $v.monthlytarget.decimal && !$v.monthlytarget.maxValue" alerttype="warn" message="Remember this is a MONTHLY take-home amount."></TWAlert>

    </div>

    <h3 class="text-center text-xl">Do you have your comparison sheet with you?</h3>

    <TWAlert v-if="$v.comparison.$error" alerttype="warn" message="You must have at least your Comparison Report to continue."></TWAlert>
    <div class="bg-white rounded-md -space-y-px">
      <!-- On: "bg-indigo-50 border-indigo-200 z-10", Off: "border-gray-200" -->
      <div class="relative border rounded-tl-md rounded-tr-md p-4 flex">
        <div class="flex items-center h-5">
          <input disabled id="settings-option-0" v-model="$v.comparison.$model" @blur="selectDocument('comparison')"  name="privacy_setting" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 cursor-pointer border-gray-300" checked>
        </div>
        <label for="settings-option-0" class="ml-3 flex flex-row cursor-pointer">
          <div class="flex flex-col">
            <!-- On: "text-indigo-900", Off: "text-gray-900" -->
            <span class="block text-sm font-medium text-gray-900" :class="{ 'text-indigo-900': comparison }">
            2020 Comparison Report
            </span>
            <!-- On: "text-indigo-700", Off: "text-gray-500" -->
            <span class="block text-sm text-gray-500" :class="{ 'text-indigo-700': comparison }">
            This is the report that shows your 2020 actuals.  You'll need this to drive your estimations.
            </span>
          </div>
          <div>
            <img src="/comparethumb.png" class="p-1 h-16 w-16" alt="Comparison Report">
          </div>
        </label>
      </div>
    </div>
  </fieldset>
</div>
</template>

<script>
import {required,maxValue,minValue,decimal} from 'vuelidate/lib/validators'
import {mapState} from 'vuex'
import TWAlert from './TWAlert.vue'
import TWQType from './TWQType.vue'
export default {
  components: {
    TWAlert,
    TWQType
  },
  data () {
    return {
      documents: {
        comparison: true,
        payroll: null,
        supportaccount: null,
      },
      comparison: true,
      monthlytarget: 0
    }
  },
  validations: {
    comparison: {
        checked: value => value === true
    },
    monthlytarget: {
      required,
      minValue: minValue(1),
      maxValue: maxValue(20000),
      decimal
    }

  },
  created () {
    this.monthlytarget=this.form.takehometarget
    this.documents = this.form.documents
    //this.submit()
  },
  computed: {
    ...mapState({
      form: state => state.estimator
    }),
  },
  methods: {
    selectDocument () {
      this.$v.$touch()
      this.documents.comparison = this.comparison
      this.submit()
    },
    submit () {
      this.$emit('update', {
        data: {
          documents: this.documents,
          takehometarget: this.monthlytarget
        },
        valid: !this.$v.$invalid
      })
    }
  }
}
</script>