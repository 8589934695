<template>
    <div class="flex flex-col items-center" :content="getTooltip" v-tippy >
      <div :class="topclass" class="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0">
        <svg v-if="arrowme" :class="innerclass" class="-ml-1 mr-0.5 flex-shrink-0 self-center h-4 w-4" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
          <path fill-rule="evenodd" :d="svg" clip-rule="evenodd" />
        </svg>
        <span class="sr-only">
        {{text}} by
        </span>
        <span v-if="amt > 0">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
            </svg>
        </span>
        <span v-else-if="Math.abs(amt) > 24">
          {{(Math.abs(amt)/12).toFixed(0)}}
        </span>
        <span v-else>
          {{Math.abs(amt).toFixed(0)}}
        </span>
      </div>
      <div v-if="type == 'months'" class="text-xs font-medium text-center text-gray-300">
        <span v-if="amt > 0">
        ok
        </span>
        <span v-else-if="Math.abs(amt) > 24">
         years
        </span>
        <span v-else>
         months
        </span>
      </div>
    </div>
</template>
<script>

export default {
  name:'TWSupportBadge',
  data() {
    return {
      colorindex: [-60,-48,-36,-24,-1,0,5],
      topcolors: ['bg-green-200 text-green-800','bg-green-200 text-green-800','bg-yellow-500 text-yellow-900',
        'bg-yellow-400 text-yellow-600','bg-red-300 text-red-800','bg-red-300 text-red-800','bg-green-200 text-green-800'],
      arrowcolors: ['text-red-800','text-red-700','text-red-400','text-yellow-900'
        ,'text-yellow-600','text-green-800','text-green-700','text-green-500'
        ,'text-yellow-600','text-red-800']
    }
  },
  props: {
    amt: Number,
    msg: String,
    arrowme: {
      type: Boolean,
      default: true
    },
    type: String,
    tooltip: String,
    raise: Number,
  },
  computed:  {
    getTooltip () {
        if(this.tooltip)
            return this.tooltip
        else if(this.amt > 0)
            return 'Account positive monthly accrural of $'+parseFloat(this.raise).toFixed(0)+'/mo.'
        else
            return 'Target additional monthly support to raise is $'+Math.abs(parseFloat(this.raise)).toFixed(0)+''
    },
    closestColor () {
      const output = this.colorindex.reduce((prev, curr) => Math.abs(curr -parseFloat(this.amt)) < Math.abs(prev - parseFloat(this.amt)) ? curr : prev)
      return output
    },
    topclass () {
      return this.topcolors[this.colorindex.indexOf(this.closestColor)]
    },
    innerclass () {
      return this.arrowcolors[this.colorindex.indexOf(this.closestColor)]
    },
    svg () {
      if(parseFloat(this.amt) < 0)
        return "M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z"
      else
        return "M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z"
    },
    text () {
      if(parseFloat(this.amt) < 0)
        return "Decreased"
      else
        return "Increased"
    }
  }

}
</script>