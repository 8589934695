<template>
    <div id="estmatorpanel" ref="estimatorPDF" class="-mt-4">

        <div class="flex-row mx-auto flex justify-between items-end content-end ">

                <div id="previewPanelStarts" class="flex">
                    <img class="object-right-top w-24 h-8 pr-2 pb-1 hidden sm:block" src="/vector/default-monochrome.svg" alt="" align-left>

                    <span class="text-md leading-6 font-normal text-gray-900">
                    Preview
                    </span>
                </div>

            <span class="relative z-0 inline-flex shadow-sm rounded-md">
                <button id="buttonStartTour" type="button" @click="startTour" class="relative hidden md:inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500" :disabled="processingPDF">
                        <svg xmlns="http://www.w3.org/2000/svg" class="-ml-1 mr-1 h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 4v16M17 4v16M3 8h4m10 0h4M3 12h18M3 16h4m10 0h4M4 20h16a1 1 0 001-1V5a1 1 0 00-1-1H4a1 1 0 00-1 1v14a1 1 0 001 1z" />
                        </svg>
                        <span class="ml-1">Tour</span>
                </button>
                <button id="buttonExport" @click="generateReport" type="button" class="relative hidden md:inline-flex items-center px-4 py-2 md:-ml-px border border-gray-300 bg-white text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500" :disabled="processingPDF">
                        <svg v-if="!processingPDF" class="-ml-1 mr-1 h-4 w-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                        </svg>
                        <span v-if="!processingPDF"  class="ml-1">Export</span>
                        <svg v-if="processingPDF" class="animate-spin -ml-1 mr-2 h-5 w-5 text-yellow" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        <span v-if="processingPDF" class="ml-1">Processing</span>
                </button>
                <button id="buttonSettings" @click="toggleSettings" aria-label="Settings" type="button" class="sm:-ml-px relative inline-flex items-center px-2 py-2 rounded-md md:rounded-l-none border border-gray-300 bg-white text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500" :disabled="processingPDF">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
                    </svg>
                </button>

            </span>

        </div>

        <!--- md:divide-y-0 md:divide-x --->
        <div class="" id="previewPanels">
            <dl class="mt-3 grid grid-cols-2 gap-3 md:grid-cols-6 print:grid-cols-6" >
                <div class="col-span-2 md:col-span-6 print:col-span-6">
                    <div class="grid grid-cols-1 gap-2 p-0 md:grid-cols-4 print:grid-cols-4">
                        <!--- Program Selection Column --->
                        <div v-if="isTwoPlan" class="col-span-1 rounded-lg bg-white overflow-hidden shadow divide-gray-200 px-3 pt-3 sm:p-6">
                            <div class="flex flex-cols justify-between items-baseline">
                                <div class="flex-1">
                                    <dt class="text-base font-normal text-gray-900">
                                    Program Selection
                                    </dt>
                                    <dd class="mt-1 flex justify-between items-baseline md:block lg:flex">
                                    <div class="flex-col">
                                    <div class="flex items-baseline text-2xl font-semibold text-blue-600 whitespace-nowrap">
                                    {{isTwoToggled ? '2-Paid' : '1-Paid'}}
                                    </div>
                                    <!-- <div class="flex items-baseline ml-0 text-sm font-medium text-gray-400">
                                    ${{vsrMonthly*12 | dollarAmt(form.rounding)}} annual
                                    </div> -->
                                    </div>
                                    </dd>
                                    <button id="programToggle" type="button" @click="toggleTwoPay()" :class="isTwoToggled ? 'bg-indigo-600' : 'bg-gray-200'" class="mt-2 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" :aria-pressed="isTwoToggled ? 'true' : 'false'">
                                    <span class="sr-only">Payment Option</span>
                                        <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
                                        <span :class="isTwoToggled ? 'translate-x-5' : 'translate-x-0'" class="pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200">
                                            <!-- Enabled: "opacity-0 ease-out duration-100", Not Enabled: "opacity-100 ease-in duration-200" -->
                                            <span :class="!isTwoToggled ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100'" class="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity" aria-hidden="true">
                                                <svg class="bg-white h-3 w-3 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                    <path d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </span>
                                            <!-- Enabled: "opacity-100 ease-in duration-200", Not Enabled: "opacity-0 ease-out duration-100" -->
                                            <span :class="isTwoToggled ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100'" class="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity" aria-hidden="true">
                                                <svg class="bg-white h-3 w-3 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                    <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
                                                </svg>
                                            </span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <!--- VSRColumn --->
                        <div id="vsrSection" :class="isTwoPlan ? 'md:col-span-3 print:col-span-3' : 'md:col-span-4 print:col-span-4'" class="col-span-1 rounded-lg bg-white overflow-hidden shadow divide-gray-200 px-3 pt-3 sm:p-6">
                            <div class="flex flex-col justify-between items-baseline lg:flex-row">
                                <div class="flex-none">
                                    <dt class="text-base font-normal text-gray-900">
                                    Voluntary Salary Reduction
                                    </dt>
                                    <dd class="mt-1 flex justify-between items-baseline md:block lg:flex">
                                    <div class="flex-col">
                                    <div class="flex items-baseline text-2xl font-semibold text-yellow-500">
                                        ${{vsrMonthly | dollarAmt(form.rounding)}}/mo
                                    </div>
                                    <div class="flex items-baseline ml-0 text-sm font-medium text-gray-400">
                                    ${{vsrMonthly*12 | dollarAmt(form.rounding)}} annual
                                    </div>
                                    </div>
                                    </dd>
                                </div>
                                <div class="flex flex-col gap-1 lg:ml-3">
                                    <div class="flex-shrink font-medium text-xs text-gray-300 text-center">
                                        <span v-if="isTwoPlan && isTwoToggled && parseFloat(twoPaidVSRTarget) > 0" :content="'$'+Math.round(twoPaidVSRTarget)" v-tippy>
                                            Suggested total VSR to hit target: <br/>${{(Math.round(twoPaidVSRTarget*.90 / 10) * 10) | dollarAmt(form.rounding)}} - ${{(Math.round(twoPaidVSRTarget*1.10 / 10) * 10) | dollarAmt(form.rounding)}}
                                        </span>
                                        <span v-else-if="isTwoPlan && !isTwoToggled && parseFloat(onePaidVSRTarget) > 0" :content="'$'+Math.round(onePaidVSRTarget)" v-tippy>
                                            Suggested range of VSR to hit target: <br/>${{(Math.round(onePaidVSRTarget*.90 / 10) * 10) | dollarAmt(form.rounding)}} - ${{(Math.round(onePaidVSRTarget*1.10 / 10) * 10) | dollarAmt(form.rounding)}}
                                        </span>
                                        <span v-else-if="!isTwoPlan && parseFloat(singlePaidVSRTarget) > 0" :content="'$'+Math.round(singlePaidVSRTarget)" v-tippy>
                                            Suggested range of VSR to hit target: <br/>${{(Math.round(singlePaidVSRTarget*.90 / 10) * 10) | dollarAmt(form.rounding)}} - ${{(Math.round(singlePaidVSRTarget*1.10 / 10) * 10) | dollarAmt(form.rounding)}}
                                        </span>
                                    </div>
                                    <div class="flex flex-col gap-2 text-xs font-medium text-gray-500 mt-3 md:flex-row print:flex-row">
                                        <div class="w-34">
                                            <span v-if="!isTwoToggled">(Optional) Monthly VSR amt:</span>
                                            <span v-else>Spouse 1 Monthly VSR:</span>
                                            <div class="mb-3 pt-0 relative rounded-md shadow-sm">
                                                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                <span class="text-gray-500 sm:text-sm">
                                                    $
                                                </span>
                                                </div>
                                                <input v-model="$v.vsr1.$model" @keyup="submit()" type="number" name="price" id="price" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md" placeholder="0.00" aria-describedby="price-currency">
                                                <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                <span class="text-gray-500 sm:text-sm" id="price-currency">
                                                    USD
                                                </span>
                                                </div>
                                            </div>
                                            <TWAlert v-if="$v.vsr1.$error && !$v.vsr1.decimal" alerttype="mini" message="Need a valid USD amount."></TWAlert>
                                            <TWAlert v-if="$v.vsr1.$error && $v.vsr1.decimal && !$v.vsr1.minValue" alerttype="mini" message="Requires positive $ amt."></TWAlert>
                                            <TWAlert v-if="vsr1 > 0 && (twoPaid1NetMonthly < 100 || netMonthly < 100)" alerttype="error" message="Minimum net is $100."></TWAlert>
                                            <!-- <TWAlert v-if="$v.vsr1.$error && $v.vsr1.decimal && !$v.vsr1.maxValue" alerttype="warn" message="Remember this is a MONTHLY VSR"></TWAlert> -->
                                        </div>
                                        <div v-if="isTwoToggled" class="w-34">
                                            <span>Spouse 2 Monthly VSR:</span>
                                            <div class="mb-3 pt-0 relative rounded-md shadow-sm">
                                                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                <span class="text-gray-500 sm:text-sm">
                                                    $
                                                </span>
                                                </div>
                                                <input v-model="$v.vsr2.$model" @keyup="submit()" type="number" name="price" id="price" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md" placeholder="0.00" aria-describedby="price-currency">
                                                <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                <span class="text-gray-500 sm:text-sm" id="price-currency">
                                                    USD
                                                </span>
                                                </div>
                                            </div>
                                            <TWAlert v-if="$v.vsr2.$error && !$v.vsr2.decimal" alerttype="mini" message="Need a valid USD amount."></TWAlert>
                                            <TWAlert v-if="$v.vsr2.$error && $v.vsr2.decimal && !$v.vsr2.minValue" alerttype="mini" message="Requires positive $ amt."></TWAlert>
                                            <TWAlert v-if="vsr2 > 0 && (twoPaid2NetMonthly < 100 || netMonthly < 100)" alerttype="error" message="Minimum net is $100."></TWAlert>
                                            <!-- <TWAlert v-if="$v.vsr2.$error && $v.vsr2.decimal && !$v.vsr2.maxValue" alerttype="warn" message="Remember this is a MONTHLY VSR"></TWAlert> -->
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-span-2"> <!--- Gross Column --->
                    <div class="grid grid-row-2 gap-2">
                        <div id="grossSection" class="rounded-lg bg-white overflow-hidden shadow divide-gray-200 px-4 py-5 sm:p-6 ">
                            <dt class="text-base font-normal text-gray-900">
                            Gross Salary
                            </dt>
                            <dd class="mt-1 flex justify-between items-baseline md:block lg:flex">
                                <div class="flex-col">
                                    <div class="flex items-baseline text-2xl font-semibold text-indigo-600">
                                        $<Roller :text="grossMonthly | dollarAmt(form.rounding)" :transition="0.8" defaultChar="0" :wordWrap="8"></Roller>/mo
                                        <!-- ${{grossMonthly | dollarAmt}}/mo -->
                                    </div>
                                    <div @mouseover="grossHover = true" @mouseleave="grossHover = false" class="flex items-baseline ml-0 text-sm font-medium text-gray-400">
                                        <span v-if="grossHover">${{grossMonthly | dollarAmt(form.rounding)}}/mo + ${{grossAnnualItemsTotal | dollarAmt(form.rounding)}}/yr</span>
                                        <span v-else>${{grossAnnual | dollarAmt(form.rounding)}} annual</span>

                                        <span v-if="grossAnnual < federalMinimum" class="inline" content="Annual salary lower than federal minimum for exempt." v-tippy data-balloon-length="medium" >
                                            <svg xmlns="http://www.w3.org/2000/svg" class="ml-1 mt-1 h-4 w-4 text-yellow-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                                <TWBadge :amt="grossPercentDiff"
                                    msg="from 2020"
                                    v-bind:tooltip="form.takehomegross/12 | dollarAmt"/>
                            </dd>
                            <div v-if="isTwoToggled" class="mt-4 grid grid-cols-2 gap-2">
                                <div class="rounded-lg bg-gray-50 overflow-hidden shadow divide-gray-200 p-2 sm:p-3">
                                    <dt class="text-xs font-normal text-gray-500">
                                    Spouse 1
                                    </dt>
                                    <dd class="mt-1 flex justify-between items-baseline md:block lg:flex">
                                        <div class="text-sm font-semibold text-indigo-400 inline">
                                        ${{twoPaid1GrossMonthly | dollarAmt(form.rounding)}}/mo

                                        </div>
                                             <span v-if="twoPaid1GrossMonthly*12 < federalMinimum" class="inline" content="Below Fed exempt." v-tippy data-balloon-length="medium" >
                                                <svg xmlns="http://www.w3.org/2000/svg" class="ml-1 mt-1 h-4 w-4 text-yellow-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                                </svg>
                                            </span>
                                    </dd>
                                </div>
                                <div class="rounded-lg bg-gray-50 overflow-hidden shadow divide-gray-200 p-2 sm:p-3">
                                    <dt class="text-xs font-normal text-gray-500">
                                    Spouse 2
                                    </dt>
                                    <dd class="mt-1 flex justify-between items-baseline md:block lg:flex">
                                        <div class="text-sm font-semibold text-indigo-400">
                                        ${{twoPaid2GrossMonthly | dollarAmt(form.rounding)}}/mo

                                        </div>
                                        <span v-if="twoPaid2GrossMonthly*12 < federalMinimum" class="inline" content="Below Fed exempt" v-tippy data-balloon-length="medium" >
                                        <svg xmlns="http://www.w3.org/2000/svg" class="ml-1 mt-1 h-4 w-4 text-yellow-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                        </svg>
                                        </span>
                                    </dd>
                                </div>
                            </div>
                    </div>
                    <div class="rounded-lg bg-gray-50 overflow-hidden shadow p-6 md:p-3 text-sm text-gray-400 ">
                        <div class="flex flex-col">
                            <div>
                                <span>Monthly Income Items</span>
                                <div v-if="isTwoToggled" class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                        <table class="min-w-full divide-y divide-gray-200 text-right">
                                        <thead class="bg-gray-50">
                                            <tr>
                                            <th scope="col" class="px-3 py-1 whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Desc
                                            </th>
                                            <th scope="col" class="px-3 py-1 whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Spouse 1
                                            </th>
                                            <th scope="col" class="px-3 py-1 whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Spouse2
                                            </th>
                                            </tr>
                                        </thead>
                                        <tbody class="bg-white divide-y divide-gray-200">
                                            <tr>
                                                <td class="px-3 py-1 text-xs text-right font-medium text-gray-600">
                                                Base Salary
                                                </td>
                                                <td class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                ${{singleBase/12 | dollarAmt(form.rounding)}}
                                                </td>
                                                <td class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                ${{singleBase/12 | dollarAmt(form.rounding)}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="px-3 py-1 text-xs text-right font-medium text-gray-600">
                                                Goods and Services
                                                </td>
                                                <td class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                ${{form.costofliving2paid/12 | dollarAmt(form.rounding)}}
                                                </td>
                                                <td class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                ${{form.costofliving2paid/12 | dollarAmt(form.rounding)}}
                                                </td>
                                            </tr>
                                            <tr v-if="form.leadership || form.leadership2">
                                                <td class="px-3 py-1 whitespace-nowrap text-right text-xs font-medium text-gray-600">
                                                Leadership
                                                </td>
                                                <td class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                ${{form.fieldresponsiblity | dollarAmt(form.rounding)}}
                                                </td>
                                                <td class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                ${{parseFloat(form.fieldresponsiblity2)||0 | dollarAmt(form.rounding)}}
                                                </td>
                                            </tr>
                                            <!-- <tr v-if="form.ordained && form.ordainedhousing">
                                                <td class="px-3 py-1 text-xs text-right font-medium text-gray-600">
                                                Ord. Housing
                                                </td>
                                                <td class="px-3 py-1 whitespace-nowrap text-xs text-red-300">
                                                ${{-form.ordainedhousing | dollarAmt(form.rounding)}}
                                                </td>
                                                <td class="px-3 py-1 whitespace-nowrap text-xs text-red-300">
                                                --
                                                </td>
                                            </tr> -->
                                            <tr>
                                                <td class="px-3 py-1 text-xs text-right font-medium text-gray-600">
                                                VSR
                                                </td>
                                                <td class="px-3 py-1 whitespace-nowrap text-xs text-yellow-600">
                                                ${{-vsr1 | dollarAmt(form.rounding)}}
                                                </td>
                                                <td class="px-3 py-1 whitespace-nowrap text-xs text-yellow-600">
                                                ${{-vsr2 | dollarAmt(form.rounding)}}
                                                </td>
                                            </tr>
                                            <tr v-if="form.ordained && !form.ordainedSSExempt">
                                                <td class="px-3 py-1 text-xs text-right font-medium text-gray-600">
                                                SECA
                                                </td>
                                                <td class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                ${{twoPaid1GrossMonthly-twoPaid1BaseMonthly | dollarAmt(form.rounding)}}
                                                </td>
                                                <td class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                --
                                                </td>
                                            </tr>
                                            <tr v-for="item in grossPersonals" :key="item.id">
                                                 <td class="px-3 py-1 text-xs text-right font-medium text-gray-600" @click="editCustomItem(item)" :content="item.name" v-tippy>
                                                     <div class="truncate" >{{item.name}}</div>
                                                </td>
                                                <td class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                ${{item.amt1 | dollarAmt(form.rounding)}}
                                                </td>
                                                <td class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                ${{item.amt2 | dollarAmt(form.rounding)}}
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td class="px-3 py-1 text-xs text-right font-medium text-gray-600">

                                                </td>
                                                <td class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                ${{twoPaid1GrossMonthly | dollarAmt(form.rounding)}}
                                                </td>
                                                <td class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                ${{twoPaid2GrossMonthly | dollarAmt(form.rounding)}}
                                                </td>
                                            </tr>
                                        </tfoot>
                                        </table>
                                    </div>
                                    </div>
                                </div>
                                <div v-else class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                        <table class="min-w-full divide-y divide-gray-200 text-right">
                                        <thead class="bg-gray-50">
                                            <tr>
                                            <th scope="col" class="px-3 py-1 whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Desc
                                            </th>
                                            <th scope="col" class="px-3 py-1 whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Amt
                                            </th>
                                            </tr>
                                        </thead>
                                        <tbody class="bg-white divide-y divide-gray-200">
                                            <tr>
                                                <td class="px-3 py-1 text-xs text-right font-medium text-gray-600">
                                                Base Salary
                                                </td>
                                                <td v-if="isTwoPlan" class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                ${{multiBase/12 | dollarAmt(form.rounding)}}
                                                </td>
                                                <td v-else class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                ${{singleBase/12 | dollarAmt(form.rounding)}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="px-3 py-1 text-xs text-right font-medium text-gray-600">
                                                Goods and Services
                                                </td>
                                                <td class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                ${{form.costofliving1paid/12 | dollarAmt(form.rounding)}}
                                                </td>
                                            </tr>
                                            <tr v-if="form.leadership && form.fieldresponsiblity">
                                                <td class="px-3 py-1 whitespace-nowrap text-right text-xs font-medium text-gray-600">
                                                Leadership
                                                </td>
                                                <td class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                ${{form.fieldresponsiblity | dollarAmt(form.rounding)}}
                                                </td>
                                            </tr>
                                            <!-- <tr v-if="form.ordained && form.ordainedhousing">
                                                <td class="px-3 py-1 text-xs text-right font-medium text-gray-600">
                                                Ord. Housing
                                                </td>
                                                <td class="px-3 py-1 whitespace-nowrap text-xs text-red-300">
                                                ${{-form.ordainedhousing | dollarAmt(form.rounding)}}
                                                </td>
                                            </tr> -->
                                            <tr>
                                                <td class="px-3 py-1 text-xs text-right font-medium text-gray-600">
                                                VSR
                                                </td>
                                                <td class="px-3 py-1 whitespace-nowrap text-xs text-yellow-500">
                                                ${{-vsr1 | dollarAmt(form.rounding)}}
                                                </td>
                                            </tr>
                                            <tr v-if="form.ordained && !form.ordainedSSExempt">
                                                <td class="px-3 py-1 text-xs text-right font-medium text-gray-600">
                                                SECA
                                                </td>
                                                <td v-if="isTwoPlan" class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                ${{onePaidGrossMonthly-onePaidBaseMonthly | dollarAmt(form.rounding)}}
                                                </td>
                                                <td v-else class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                ${{singlePaidGrossMonthly-singlePaidBaseMonthly | dollarAmt(form.rounding)}}
                                                </td>
                                            </tr>
                                            <tr v-for="item in grossPersonals" :key="item.id">
                                                 <td class="px-3 py-1 text-xs text-right font-medium text-gray-600" @click="editCustomItem(item)" :content="item.name" v-tippy>
                                                     <div class="truncate text-right" >{{item.name}}</div>
                                                </td>
                                                <td class="px-3 py-1 whitespace-nowrap text-xs" :class="item.amt < 0 ? 'text-red-300' : 'text-gray-500'">
                                                ${{item.amt | dollarAmt(form.rounding)}}
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td class="px-3 py-1 text-xs text-right font-medium text-gray-600">

                                                </td>
                                                <td v-if="isTwoPlan" class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                ${{onePaidGrossMonthly | dollarAmt(form.rounding)}}
                                                </td>
                                                <td v-else class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                ${{singlePaidGrossMonthly | dollarAmt(form.rounding)}}
                                                </td>
                                            </tr>
                                        </tfoot>
                                        </table>
                                    </div>
                                    </div>
                                </div>

                                <personal-item-button
                                    kind="grossmonthly"
                                    @open="toggleCustomItem"
                                />
                            </div>
                            <div class="mt-3">
                                Annual Income Items
                                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                        <table class="min-w-full divide-y divide-gray-200 text-right">
                                        <thead class="bg-gray-50">
                                            <tr>
                                            <th scope="col" class="px-3 py-1 whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Desc
                                            </th>
                                            <th scope="col" class="px-3 py-1 whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Amt
                                            </th>
                                            </tr>
                                        </thead>
                                        <tbody class="bg-white divide-y divide-gray-200">
                                            <tr v-if="form.schoolcontributions.schoolpostsecondary">
                                                <td class="px-3 py-1 text-xs text-right font-medium text-gray-600">
                                                Secondary Edu
                                                </td>
                                                <td class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                ${{form.schoolcontributions.schoolpostsecondary | dollarAmt(form.rounding)}}
                                                </td>
                                            </tr>
                                            <tr v-for="item in annualPersonals" :key="item.id">
                                                <td class="px-3 py-1 text-xs text-right font-medium text-gray-600" @click="editCustomItem(item)" :content="item.name" v-tippy>
                                                    <div class="truncate text-right" >{{item.name}}</div>
                                                </td>
                                                <td class="px-3 py-1 whitespace-nowrap text-xs" :class="item.amt < 0 ? 'text-red-300' : 'text-gray-500'">
                                                ${{item.amt | dollarAmt(form.rounding)}}
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td class="px-3 py-1 text-xs text-right font-medium text-gray-600">

                                                </td>
                                                <td class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                ${{grossAnnualItemsTotal | dollarAmt(form.rounding)}}
                                                </td>
                                            </tr>
                                        </tfoot>
                                        </table>
                                    </div>
                                    </div>
                                </div>
                                <personal-item-button
                                    kind="grossannual"
                                    prettykind="Annual Income"
                                    @open="toggleCustomItem"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div class="col-span-2"> <!--- Net Column --->
                    <div class="grid grid-row-2 gap-2">
                        <div id="netSection" class="rounded-lg bg-white overflow-hidden shadow divide-gray-200 px-4 py-5 sm:p-6 ">
                                <dt class="text-base font-normal text-gray-900">
                                Estimated Net Take-Home
                                </dt>
                                <dd class="mt-1 flex justify-between items-baseline md:block lg:flex">
                                    <div class="flex-col">
                                        <div class="flex items-baseline text-2xl font-semibold text-green-700">
                                            $<Roller :text="netMonthly | dollarAmt(form.rounding)" :transition="0.8" defaultChar="0" :wordWrap="8"></Roller>/mo
                                            <!-- ${{netMonthly | dollarAmt}}/mo -->
                                        </div>
                                        <div class="flex items-baseline ml-0 text-sm font-medium text-green-400">
                                        ${{netAnnual | dollarAmt(form.rounding)}} annual
                                        </div>
                                    </div>
                                    <TWBadge :amt="netPercentDiff"
                                        msg="of goal"
                                        :full100="true"
                                        :arrowme="false"
                                        v-bind:tooltip="form.takehometarget | dollarAmt"
                                        />
                                </dd>
                                <div v-if="isTwoToggled" class="mt-4 grid grid-cols-2 gap-2">
                                    <div class="rounded-lg bg-gray-50 overflow-hidden shadow divide-gray-200 p-2 sm:p-3">
                                        <dt class="text-xs font-normal text-gray-700">
                                        Spouse 1
                                        </dt>
                                        <dd class="mt-1 flex justify-between items-baseline md:block lg:flex">
                                            <div class="text-sm font-semibold text-green-400">
                                            ${{twoPaid1NetMonthly | dollarAmt(form.rounding)}}/mo
                                            </div>
                                        </dd>
                                    </div>
                                    <div class="rounded-lg bg-gray-50 overflow-hidden shadow divide-gray-200 p-2 sm:p-3">
                                        <dt class="text-xs font-normal text-gray-700">
                                        Spouse 2
                                        </dt>
                                        <dd class="mt-1 flex justify-between items-baseline md:block lg:flex">
                                            <div class="text-sm font-semibold text-green-400">
                                            ${{twoPaid2NetMonthly | dollarAmt(form.rounding)}}/mo
                                            </div>
                                        </dd>
                                    </div>

                                </div>
                        </div>
                        <div class="rounded-lg bg-gray-50 overflow-hidden shadow p-6 md:p-3 text-sm text-gray-400 ">
                            <div class="flex flex-col">
                                <div>
                                    Pre-Tax
                                    <div v-if="isTwoToggled" class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                            <table class="min-w-full divide-y divide-gray-200 text-right">
                                            <thead class="bg-gray-50">
                                                <tr>
                                                <th scope="col" class="px-3 py-1 whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Desc
                                                </th>
                                                <th scope="col" class="px-3 py-1 whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Spouse 1
                                                </th>
                                                <th scope="col" class="px-3 py-1 whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Spouse2
                                                </th>
                                                </tr>
                                            </thead>
                                            <tbody class="bg-white divide-y divide-gray-200">
                                                <tr>
                                                    <td class="px-3 py-1 text-xs text-right font-medium text-gray-600">
                                                    Gross Salary
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                    ${{twoPaid1GrossMonthly | dollarAmt(form.rounding)}}
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                    ${{twoPaid2GrossMonthly | dollarAmt(form.rounding)}}
                                                    </td>
                                                </tr>
                                                <tr v-if="form.flexiblespendingplan2 || form.flexiblespendingplan">
                                                    <td class="px-3 py-1 whitespace-nowrap text-right text-xs font-medium text-gray-600">
                                                    US-Flex Plan
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs text-red-300">
                                                    ${{-(parseFloat(form.flexiblespendingplan1)||0) | dollarAmt(form.rounding)}}
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs text-red-300">
                                                    ${{-(parseFloat(form.flexiblespendingplan2)||0) | dollarAmt(form.rounding)}}
                                                    </td>
                                                </tr>
                                                <tr v-for="item in pretaxPersonals" :key="item.id">
                                                    <td class="px-3 py-1 text-xs text-right font-medium text-gray-600" @click="editCustomItem(item)" :content="item.name" v-tippy>
                                                        <div class="truncate" >{{item.name}}</div>
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs" :class="item.amt > 0 ? 'text-red-300' : 'text-gray-500'">
                                                    ${{-item.amt1 | dollarAmt(form.rounding)}}
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs" :class="item.amt > 0 ? 'text-red-300' : 'text-gray-500'">
                                                    ${{-item.amt2 | dollarAmt(form.rounding)}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td class="px-3 py-1 text-xs text-right font-medium text-gray-600">

                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                    ${{twoPaid1TaxableMonthly | dollarAmt(form.rounding)}}
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                    ${{twoPaid2TaxableMonthly | dollarAmt(form.rounding)}}
                                                    </td>
                                                </tr>
                                            </tfoot>
                                            </table>
                                        </div>
                                        </div>
                                    </div>
                                    <div v-else class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                            <table class="min-w-full divide-y divide-gray-200 text-right">
                                            <thead class="bg-gray-50">
                                                <tr>
                                                <th scope="col" class="px-3 py-1 whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Desc
                                                </th>
                                                <th scope="col" class="px-3 py-1 whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Amt
                                                </th>
                                                </tr>
                                            </thead>
                                            <tbody class="bg-white divide-y divide-gray-200">
                                                <tr>
                                                    <td class="px-3 py-1 text-xs text-right font-medium text-gray-600">
                                                    Gross Salary
                                                    </td>
                                                    <td v-if="isTwoPlan" class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                    ${{onePaidGrossMonthly | dollarAmt(form.rounding)}}
                                                    </td>
                                                    <td v-else class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                    ${{singlePaidGrossMonthly | dollarAmt(form.rounding)}}
                                                    </td>
                                                </tr>
                                                <tr v-if="form.flexiblespendingplan">
                                                    <td class="px-3 py-1 whitespace-nowrap text-right text-xs font-medium text-gray-600">
                                                    US-Flex Plan
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs text-red-300">
                                                    ${{-(parseFloat(form.flexiblespendingplan)||0) | dollarAmt(form.rounding)}}
                                                    </td>
                                                </tr>
                                                <tr v-for="item in pretaxPersonals" :key="item.id">
                                                    <td class="px-3 py-1 text-xs text-right font-medium text-gray-600" @click="editCustomItem(item)" :content="item.name" v-tippy>
                                                        <div class="truncate" >{{item.name}}</div>
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs" :class="item.amt > 0 ? 'text-red-300' : 'text-gray-500'">
                                                    ${{-item.amt | dollarAmt(form.rounding)}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td class="px-3 py-1 text-xs text-right font-medium text-gray-600">

                                                    </td>
                                                    <td v-if="isTwoPlan" class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                    ${{onePaidTaxableMonthly | dollarAmt(form.rounding)}}
                                                    </td>
                                                    <td v-else class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                    ${{singlePaidTaxableMonthly | dollarAmt(form.rounding)}}
                                                    </td>
                                                </tr>
                                            </tfoot>
                                            </table>
                                        </div>
                                        </div>
                                    </div>
                                    <personal-item-button
                                        kind="pretax"
                                        @open="toggleCustomItem"
                                    />
                                    <!-- content="**If ordained, salary includes the untaxed ordained housing amount" data-balloon-length="medium" v-tippy data-balloon-break="" -->
                                    <h3 class="mt-3" >Post-Tax Deductions &amp; Taxes</h3>
                                    <div v-if="isTwoToggled" class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                            <table class="min-w-full divide-y divide-gray-200 text-right">
                                            <thead class="bg-gray-50">
                                                <tr>
                                                <th scope="col" class="px-3 py-1 whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Desc
                                                </th>
                                                <th scope="col" class="px-3 py-1 whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Spouse 1
                                                </th>
                                                <th scope="col" class="px-3 py-1 whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Spouse2
                                                </th>
                                                </tr>
                                            </thead>
                                            <tbody class="bg-white divide-y divide-gray-200">
                                                <tr>
                                                    <td class="px-3 py-1 text-xs text-right font-medium text-gray-600">
                                                    <span content="**If ordained, includes a non-taxed ordained housing amount" data-balloon-length="medium" v-tippy data-balloon-break="">Salary</span>

                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                    ${{twoPaid1TaxableMonthly | dollarAmt(form.rounding)}}
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                    ${{twoPaid2TaxableMonthly | dollarAmt(form.rounding)}}
                                                    </td>
                                                </tr>
                                                <tr v-if="form.additionalcontributions.lifeinsurance || form.additionalcontributions.lifeinsurance2">
                                                    <td class="px-3 py-1 whitespace-nowrap text-right text-xs font-medium text-gray-600">
                                                    Life Ins+
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs text-red-300">
                                                    ${{-form.additionalcontributions.lifeinsurance1 | dollarAmt(form.rounding)}}
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs text-red-300">
                                                    ${{-form.additionalcontributions.lifeinsurance2 | dollarAmt(form.rounding)}}
                                                    </td>
                                                </tr>
                                                <!-- <tr v-if="form.ordained && form.ordainedhousing">
                                                    <td class="px-3 py-1 text-xs text-right font-medium text-gray-600">
                                                    Ord. Housing
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                    ${{form.ordainedhousing | dollarAmt(form.rounding)}}
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                    --
                                                    </td>
                                                </tr> -->
                                                <!-- <tr v-if="form.additionalcontributions.postretirement || form.additionalcontributions.postretirement2">
                                                    <td class="px-3 py-1 whitespace-nowrap text-right text-xs font-medium text-gray-600">
                                                    PostTax Retire
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs text-red-300">
                                                    ${{-form.additionalcontributions.postretirement | dollarAmt(form.rounding)}}
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs text-red-300">
                                                    ${{-form.additionalcontributions.postretirement2 | dollarAmt(form.rounding)}}
                                                    </td>
                                                </tr>                                             -->
                                                <tr v-if="form.additionalcontributions.retirement || form.additionalcontributions.retirement2">
                                                    <td class="px-3 py-1 whitespace-nowrap text-right text-xs font-medium text-gray-600">
                                                    Retire+
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs text-red-300">
                                                    ${{-form.additionalcontributions.retirement1 | dollarAmt(form.rounding)}}
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs text-red-300">
                                                    ${{-form.additionalcontributions.retirement2 | dollarAmt(form.rounding)}}
                                                    </td>
                                                </tr>
                                                <tr v-if="form.taxes.federalwithholding || form.taxes.federalwithholding2">
                                                    <td class="px-3 py-1 whitespace-nowrap text-right text-xs font-medium text-gray-600">
                                                    Fed. Tax
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs text-red-300">
                                                    ${{-form.taxes.federalwithholding1 | dollarAmt(form.rounding)}}
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs text-red-300">
                                                    ${{-form.taxes.federalwithholding2 | dollarAmt(form.rounding)}}
                                                    </td>
                                                </tr>
                                                <tr v-if="form.taxes.statewithholding || form.taxes.statewithholding2">
                                                    <td class="px-3 py-1 whitespace-nowrap text-right text-xs font-medium text-gray-600">
                                                    State Tax
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs text-red-300">
                                                    ${{-form.taxes.statewithholding1 | dollarAmt(form.rounding)}}
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs text-red-300">
                                                    ${{-form.taxes.statewithholding2 | dollarAmt(form.rounding)}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="px-3 py-1 whitespace-nowrap text-right text-xs font-medium text-gray-600">
                                                    OASDI
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs text-red-300">
                                                    <span v-if="!form.ordained">${{-twoPaid1TaxableMonthly*.062 | dollarAmt(form.rounding)}}</span>
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs text-red-300">
                                                    ${{-twoPaid2TaxableMonthly*.062 | dollarAmt(form.rounding)}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="px-3 py-1 whitespace-nowrap text-right text-xs font-medium text-gray-600">
                                                    Medicare
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs text-red-300">
                                                    <span v-if="!form.ordained">${{-twoPaid1TaxableMonthly*.0145 | dollarAmt(form.rounding)}}</span>
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs text-red-300">
                                                    ${{-twoPaid2TaxableMonthly*.0145 | dollarAmt(form.rounding)}}
                                                    </td>
                                                </tr>
                                                <tr v-for="item in posttaxPersonals" :key="item.id">
                                                    <td class="px-3 py-1 text-xs text-right font-medium text-gray-600" @click="editCustomItem(item)" :content="item.name" v-tippy>
                                                        <div class="truncate" >{{item.name}}</div>
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs" :class="item.amt > 0 ? 'text-red-300' : 'text-gray-500'">
                                                    ${{-item.amt1 | dollarAmt(form.rounding)}}
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs" :class="item.amt > 0 ? 'text-red-300' : 'text-gray-500'">
                                                    ${{-item.amt2 | dollarAmt(form.rounding)}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td class="px-3 py-1 text-xs text-right font-medium text-gray-600">

                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                    ${{twoPaid1NetMonthly | dollarAmt(form.rounding)}}
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                    ${{twoPaid2NetMonthly | dollarAmt(form.rounding)}}
                                                    </td>
                                                </tr>
                                            </tfoot>
                                            </table>
                                        </div>
                                        </div>
                                    </div>
                                    <div v-else class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                            <table class="min-w-full divide-y divide-gray-200 text-right">
                                            <thead class="bg-gray-50">
                                                <tr>
                                                <th scope="col" class="px-3 py-1 whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Desc
                                                </th>
                                                <th scope="col" class="px-3 py-1 whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Amt
                                                </th>
                                                </tr>
                                            </thead>
                                            <tbody class="bg-white divide-y divide-gray-200">
                                                <tr>
                                                    <td class="px-3 py-1 text-xs text-right font-medium text-gray-600">
                                                    Salary
                                                    </td>
                                                    <td v-if="isTwoPlan" class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                    ${{onePaidTaxableMonthly | dollarAmt(form.rounding)}}
                                                    </td>
                                                    <td v-else class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                    ${{singlePaidTaxableMonthly | dollarAmt(form.rounding)}}
                                                    </td>
                                                </tr>
                                                <tr v-if="form.additionalcontributions.lifeinsurance">
                                                    <td class="px-3 py-1 whitespace-nowrap text-right text-xs font-medium text-gray-600">
                                                    Life Ins+
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs text-red-300">
                                                    ${{-(parseFloat(form.additionalcontributions.lifeinsurance) || 0) | dollarAmt(form.rounding)}}
                                                    </td>
                                                </tr>
                                                <!-- <tr v-if="form.ordained && form.ordainedhousing">
                                                    <td class="px-3 py-1 text-xs text-right font-medium text-gray-600">
                                                    Ord. Housing
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                    ${{form.ordainedhousing | dollarAmt(form.rounding)}}
                                                    </td>
                                                </tr> -->
                                                <tr v-if="form.additionalcontributions.retirement">
                                                    <td class="px-3 py-1 whitespace-nowrap text-right text-xs font-medium text-gray-600">
                                                    Retire+
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs text-red-300">
                                                    ${{-form.additionalcontributions.retirement | dollarAmt(form.rounding)}}
                                                    </td>
                                                </tr>
                                                <tr v-if="form.taxes.federalwithholding">
                                                    <td class="px-3 py-1 whitespace-nowrap text-right text-xs font-medium text-gray-600">
                                                    Fed. Tax
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs text-red-300">
                                                    ${{-form.taxes.federalwithholding | dollarAmt(form.rounding)}}
                                                    </td>
                                                </tr>
                                                <tr v-if="form.taxes.statewithholding">
                                                    <td class="px-3 py-1 whitespace-nowrap text-right text-xs font-medium text-gray-600">
                                                    State Tax
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs text-red-300">
                                                    ${{-form.taxes.statewithholding | dollarAmt(form.rounding)}}
                                                    </td>
                                                </tr>
                                                <tr v-if="!form.ordained">
                                                    <td class="px-3 py-1 whitespace-nowrap text-right text-xs font-medium text-gray-600">
                                                    OASDI
                                                    </td>
                                                    <td v-if="isTwoPlan" class="px-3 py-1 whitespace-nowrap text-xs text-red-300">
                                                    ${{-onePaidTaxableMonthly*.062 | dollarAmt(form.rounding)}}
                                                    </td>
                                                    <td v-else class="px-3 py-1 whitespace-nowrap text-xs text-red-300">
                                                    ${{-singlePaidTaxableMonthly*.062 | dollarAmt(form.rounding)}}
                                                    </td>
                                                </tr>
                                                <tr v-if="!form.ordained">
                                                    <td class="px-3 py-1 whitespace-nowrap text-right text-xs font-medium text-gray-600">
                                                    Medicare
                                                    </td>
                                                    <td v-if="isTwoPlan" class="px-3 py-1 whitespace-nowrap text-xs text-red-300">
                                                    ${{-onePaidTaxableMonthly*.0145 | dollarAmt(form.rounding)}}
                                                    </td>
                                                    <td v-else class="px-3 py-1 whitespace-nowrap text-xs text-red-300">
                                                    ${{-singlePaidTaxableMonthly*.0155 | dollarAmt(form.rounding)}}
                                                    </td>
                                                </tr>
                                                <tr v-for="item in posttaxPersonals" :key="item.id">
                                                    <td class="px-3 py-1 text-xs text-right font-medium text-gray-600" @click="editCustomItem(item)" :content="item.name" v-tippy>
                                                        <div class="truncate" >{{item.name}}</div>
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs" :class="item.amt > 0 ? 'text-red-300' : 'text-gray-500'">
                                                    ${{-item.amt | dollarAmt(form.rounding)}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td class="px-3 py-1 text-xs text-right font-medium text-gray-600">

                                                    </td>
                                                    <td v-if="isTwoPlan" class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                    ${{onePaidNetMonthly | dollarAmt(form.rounding)}}
                                                    </td>
                                                    <td v-else class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                    ${{singlePaidNetMonthly | dollarAmt(form.rounding)}}
                                                    </td>
                                                </tr>
                                            </tfoot>
                                            </table>
                                        </div>
                                        </div>
                                    </div>
                                    <personal-item-button
                                        kind="posttax"
                                        @open="toggleCustomItem"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-span-2"> <!--- Support Column --->
                    <div class="grid grid-row-2 gap-2">
                        <div id="supportSection" class="rounded-lg bg-white overflow-hidden shadow divide-gray-200 px-4 py-5 sm:p-6 ">
                                <dt class="text-base font-normal text-gray-900" content="Some items are not included: office fee, HMA fees, and ministry business expenses." data-balloon-length="large" v-tippy>
                                Support Account
                                    <svg  class="inline h-4 w-4 text-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                                    </svg>
                                </dt>
                                <dd class="mt-1 flex justify-between items-baseline md:block lg:flex">
                                    <div class="flex-col">
                                        <div class="flex items-baseline text-2xl font-semibold text-gray-700">
                                            $<Roller :text="supportMonthly | dollarAmt(form.rounding)" :transition="0.8" defaultChar="0" :wordWrap="8"></Roller>/mo
                                            <!-- ${{supportMonthly | dollarAmt}}/mo -->
                                        </div>
                                        <div @mouseover="supportHover = true" @mouseleave="supportHover = false" class="flex items-baseline ml-0 text-sm font-medium text-gray-400">
                                            <span v-if="supportHover">${{supportMonthly | dollarAmt(form.rounding)}}/mo + ${{grossAnnualItemsTotal | dollarAmt(form.rounding)}}/yr</span>
                                            <span v-else>${{supportAnnual | dollarAmt(form.rounding)}} annual</span>
                                        </div>
                                    </div>

                                    <TWSupportBadge v-if="supportExists" :raise="supportRaiseAmt" :amt="supportBurnRate" type="months" :arrowme="false"/>
                                </dd>
                                <div v-if="isTwoToggled" class="mt-4 grid grid-cols-2 gap-2">
                                    <div class="rounded-lg bg-gray-50 overflow-hidden shadow divide-gray-200 p-2 sm:p-3">
                                        <dt class="text-xs font-normal text-gray-700">
                                        Spouse 1
                                        </dt>
                                        <dd class="mt-1 flex justify-between items-baseline md:block lg:flex">
                                            <div class="text-sm font-semibold text-green-400">
                                            ${{twoPaid1SupportTotal | dollarAmt(form.rounding)}}/mo
                                            </div>
                                        </dd>
                                    </div>
                                    <div class="rounded-lg bg-gray-50 overflow-hidden shadow divide-gray-200 p-2 sm:p-3">
                                        <dt class="text-xs font-normal text-gray-700">
                                        Spouse 2
                                        </dt>
                                        <dd class="mt-1 flex justify-between items-baseline md:block lg:flex">
                                            <div class="text-sm font-semibold text-green-400">
                                            ${{twoPaid2SupportTotal | dollarAmt(form.rounding)}}/mo
                                            </div>
                                        </dd>
                                    </div>
                                </div>
                        </div>
                        <div class="rounded-lg bg-gray-50 overflow-hidden shadow p-6 md:p-3 text-sm text-gray-400 ">
                            <div class="flex flex-col">
                                <div>
                                    Salary + Benefit Monthly Items
                                    <div v-if="isTwoToggled" class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                            <table class="min-w-full divide-y divide-gray-200 text-right">
                                            <thead class="bg-gray-50">
                                                <tr>
                                                <th scope="col" class="px-3 py-1 whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Desc
                                                </th>
                                                <th scope="col" class="px-3 py-1 whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Spouse 1
                                                </th>
                                                <th scope="col" class="px-3 py-1 whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Spouse2
                                                </th>
                                                </tr>
                                            </thead>
                                            <tbody class="bg-white divide-y divide-gray-200">
                                                <tr>
                                                    <td class="px-3 py-1 text-xs text-right font-medium text-gray-600">
                                                    Gross Salary
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                    ${{twoPaid1GrossMonthly | dollarAmt(form.rounding)}}
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                    ${{twoPaid2GrossMonthly | dollarAmt(form.rounding)}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="px-3 py-1 text-xs text-right font-medium text-gray-600" content="This is the employer contribution of FICA." v-tippy>
                                                    ER OASDI/Medicare
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                        <span v-if="!form.ordained">
                                                            ${{twoPaid1TaxableMonthly*.0765 | dollarAmt(form.rounding)}}
                                                        </span>
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                    ${{twoPaid2TaxableMonthly*.0765 | dollarAmt(form.rounding)}}
                                                    </td>
                                                </tr>
                                                <tr v-for="benefit in twoPaidBenefits" :key="benefit.name" >
                                                    <td v-if="benefit.tooltip" class="px-3 py-1 text-xs text-right font-medium text-gray-600" :content="benefit.tooltip" v-tippy>
                                                    {{benefit.name}}
                                                    </td>
                                                   <td v-else class="px-3 py-1 text-xs text-right font-medium text-gray-600">
                                                    {{benefit.name}}
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs text-gray-500" >
                                                    ${{benefit.amt | dollarAmt(form.rounding)}}
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                    ${{benefit.amt2 | dollarAmt(form.rounding)}}
                                                    </td>
                                                </tr>
                                                <tr v-for="item in benefitPersonals" :key="item.id">
                                                    <td class="px-3 py-1 text-xs text-right font-medium text-gray-600" @click="editCustomItem(item)" :content="item.name" v-tippy>
                                                        <div class="truncate" >{{item.name}}</div>
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs" :class="item.amt < 0 ? 'text-red-300' : 'text-gray-500'">
                                                    ${{item.amt1 | dollarAmt(form.rounding)}}
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs" :class="item.amt < 0 ? 'text-red-300' : 'text-gray-500'">
                                                    ${{item.amt2 | dollarAmt(form.rounding)}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td class="px-3 py-1 text-xs text-right font-medium text-gray-600">

                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                    ${{twoPaid1SupportTotal | dollarAmt(form.rounding)}}
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                    ${{twoPaid2SupportTotal | dollarAmt(form.rounding)}}
                                                    </td>
                                                </tr>
                                            </tfoot>
                                            </table>
                                        </div>
                                        </div>
                                    </div>
                                    <div v-else class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                            <table class="min-w-full divide-y divide-gray-200 text-right">
                                            <thead class="bg-gray-50">
                                                <tr>
                                                <th scope="col" class="px-3 py-1 whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Desc
                                                </th>
                                                <th scope="col" class="px-3 py-1 whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Amt
                                                </th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="isTwoPlan" class="bg-white divide-y divide-gray-200">
                                                <tr>
                                                    <td class="px-3 py-1 text-xs text-right font-medium text-gray-600">
                                                    Gross Salary
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                    ${{onePaidGrossMonthly | dollarAmt(form.rounding)}}
                                                    </td>
                                                </tr>
                                                <tr v-if="!form.ordained">
                                                    <td class="px-3 py-1 text-xs text-right font-medium text-gray-600">
                                                    ER OASDI/Medicare
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                    ${{onePaidTaxableMonthly*.0765 | dollarAmt(form.rounding)}}
                                                    </td>
                                                </tr>
                                                <tr v-for="benefit in onePaidBenefits" :key="benefit.name">
                                                    <td v-if="benefit.tooltip" class="px-3 py-1 text-xs text-right font-medium text-gray-600" :content="benefit.tooltip" v-tippy>
                                                    {{benefit.name}}
                                                    </td>
                                                   <td v-else class="px-3 py-1 text-xs text-right font-medium text-gray-600">
                                                    {{benefit.name}}
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                    ${{benefit.amt | dollarAmt(form.rounding)}}
                                                    </td>
                                                </tr>
                                                <tr v-for="item in benefitPersonals" :key="item.id">
                                                    <td class="px-3 py-1 text-xs text-right font-medium text-gray-600" @click="editCustomItem(item)" :content="item.name" v-tippy>
                                                        <div class="truncate" >{{item.name}}</div>
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs" :class="item.amt < 0 ? 'text-red-300' : 'text-gray-500'">
                                                    ${{item.amt | dollarAmt(form.rounding)}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody v-else class="bg-white divide-y divide-gray-200">
                                                <tr>
                                                    <td class="px-3 py-1 text-xs text-right font-medium text-gray-600">
                                                    Gross Salary
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                    ${{singlePaidGrossMonthly | dollarAmt(form.rounding)}}
                                                    </td>
                                                </tr>
                                                <tr v-if="!form.ordained">
                                                    <td class="px-3 py-1 text-xs text-right font-medium text-gray-600">
                                                    ER OASDI/Medicare
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                    ${{singlePaidTaxableMonthly*.0765 | dollarAmt(form.rounding)}}
                                                    </td>
                                                </tr>
                                                <tr v-for="benefit in singlePaidBenefits" :key="benefit.name">
                                                    <td v-if="benefit.tooltip" class="px-3 py-1 text-xs text-right font-medium text-gray-600" :content="benefit.tooltip" v-tippy>
                                                    {{benefit.name}}
                                                    </td>
                                                    <td v-else class="px-3 py-1 text-xs text-right font-medium text-gray-600" >
                                                    {{benefit.name}}
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                    ${{benefit.amt | dollarAmt(form.rounding)}}
                                                    </td>
                                                </tr>
                                                <tr v-for="item in benefitPersonals" :key="item.id">
                                                    <td class="px-3 py-1 text-xs text-right font-medium text-gray-600" @click="editCustomItem(item)" :content="item.name" v-tippy>
                                                        <div class="truncate" >{{item.name}}</div>
                                                    </td>
                                                    <td class="px-3 py-1 whitespace-nowrap text-xs" :class="item.amt < 0 ? 'text-red-300' : 'text-gray-500'">
                                                    ${{item.amt | dollarAmt(form.rounding)}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td class="px-3 py-1 text-xs text-right font-medium text-gray-600">

                                                    </td>
                                                    <td v-if="isTwoPlan" class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                    ${{onePaidSupportTotal | dollarAmt(form.rounding)}}
                                                    </td>
                                                    <td v-else class="px-3 py-1 whitespace-nowrap text-xs text-gray-500">
                                                    ${{singlePaidSupportTotal | dollarAmt(form.rounding)}}
                                                    </td>
                                                </tr>
                                            </tfoot>
                                            </table>
                                        </div>
                                        </div>
                                    </div>
                                    <personal-item-button
                                        kind="benefit"
                                        @open="toggleCustomItem"
                                        id="buttonAddCustom"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </dl>
            <div class="text-center mt-6 prose prose-indigo text-xs text-gray-300 lg:max-w-none">
                <p><span class="text-semibold">*</span> The information on the Service is provided with the understanding that the Company is not herein engaged in rendering legal, accounting, tax, or other professional advice and services. As such, it should not be used as a substitute for consultation with professional accounting, tax, legal or other competent advisers.</p>
                <p><span class="text-semibold">**</span> By default, displayed dollar amounts are rounded to the nearest dollar which will cause small rounding errors.</p>
            </div>
            <div id="pdf"></div>
        </div>
        <TWModal
            v-if="showPDFCreated"
            @close="togglePDFCreated"
            iconclass="text-red-500"
            icon="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z">
            <h2 class="text-xl pb-3 pt-2 font-semibold">Creating PDF</h2>
            <div class="text-left mx-auto text-sm font-normal text-gray-500">
                <p >
                {{showPDFMessage}}
                </p>
            </div>
        </TWModal>

        <FormSettings
            :showModal="showSettings"
            v-if="showSettings"
            @close="toggleSettings">
        </FormSettings>

        <FormPersonalItem
            :showModal="showCustomItem"
            :kind="itemKind"
            :passedItem="itemEdit"
            v-if="showCustomItem"
            @close="toggleCustomItem">
        </FormPersonalItem>
    </div>
</template>
<script>
import {maxValue,minValue,decimal} from 'vuelidate/lib/validators'
import _ from 'lodash'
import {mapState,mapGetters,mapActions} from 'vuex'
import TWAlert from './TWAlert.vue'
import TWBadge from './TWBadgeChange.vue'
import TWSupportBadge from './TWSupportBadge.vue'
import FormPersonalItem from './ModalPersonalItem.vue'
import FormSettings from './ModalSettings.vue'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import TWModal from './TWModal.vue'
import {twoPlanGuide,onePlanGuide} from './introjssteps.js'
import "intro.js/minified/introjs.min.css";
import introJs from 'intro.js';
import PersonalItemButton from './PersonalItemButton.vue'
import Roller from "vue-roller";
const delay = ms => new Promise(res => setTimeout(res, ms));

export default {
    name: 'PlanPreview',
    components: {
        TWAlert,
        TWBadge,
        TWSupportBadge,
        TWModal,
        FormSettings,
        FormPersonalItem,
        PersonalItemButton,
        Roller
    },
    computed: {
        ...mapState('estimator',{
            form: state => state
        }),
        ...mapGetters('estimator',{
            isTwoPlan : 'isTwoPlan',
            isTwoToggled : 'isTwoToggled',
            vsrMonthly : 'vsrMonthly',
            supportMonthly : 'supportMonthly',
            supportAnnual : 'supportAnnual',
            supportBurnRate : 'supportBurnRate',
            supportRaiseAmt : 'supportRaiseAmt',
            supportExists : 'supportExists',
            grossMonthly : 'grossMonthly',
            grossAnnual : 'grossAnnual',
            grossAnnualItemsTotal : 'grossAnnualTotal',
            grossPercentDiff : 'grossPercentDiff',
            netMonthly : 'netMonthly',
            netAnnual : 'netAnnual',
            netPercentDiff : 'netPercentDiff',
            twoPaid1BaseMonthly : 'twoPaid1BaseMonthly',
            twoPaid1GrossMonthly : 'twoPaid1GrossMonthly',
            twoPaid1GrossAnnual : 'twoPaid1GrossAnnual',
            twoPaid1TaxableMonthly : 'twoPaid1TaxableMonthly',
            twoPaid1NetMonthly : 'twoPaid1NetMonthly',
            twoPaid2GrossMonthly : 'twoPaid2GrossMonthly',
            twoPaid2GrossAnnual : 'twoPaid2GrossAnnual',
            twoPaid2TaxableMonthly : 'twoPaid2TaxableMonthly',
            twoPaid2NetMonthly : 'twoPaid2NetMonthly',
            twoPaidVSRTarget : 'twoPaidVSRTarget',
            twoPaidBenefits : 'twoPaidBenefits',
            twoPaidBenefitsTotal : 'twoPaidBenefitsTotal',
            twoPaid1SupportTotal : 'twoPaid1SupportTotal',
            twoPaid2SupportTotal : 'twoPaid2SupportTotal',
            singlePaidBaseMonthly : 'singlePaidBaseMonthly',
            singlePaidGrossMonthly : 'singlePaidGrossMonthly',
            singlePaidGrossAnnual : 'singlePaidGrossAnnual',
            singlePaidTaxableMonthly : 'singlePaidTaxableMonthly',
            singlePaidNetMonthly : 'singlePaidNetMonthly',
            singlePaidVSRTarget : 'singlePaidVSRTarget',
            singlePaidBenefits : 'singlePaidBenefits',
            singlePaidBenefitsTotal : 'singlePaidBenefitsTotal',
            singlePaidSupportTotal : 'singlePaidSupportTotal',
            onePaidBaseMonthly : 'onePaidBaseMonthly',
            onePaidGrossMonthly : 'onePaidGrossMonthly',
            onePaidGrossAnnual : 'onePaidGrossAnnual',
            onePaidTaxableMonthly : 'onePaidTaxableMonthly',
            onePaidNetMonthly : 'onePaidNetMonthly',
            onePaidVSRTarget : 'onePaidVSRTarget',
            onePaidBenefits : 'onePaidBenefits',
            onePaidBenefitsTotal : 'onePaidBenefitsTotal',
            onePaidSupportTotal: 'onePaidSupportTotal',
            singleBase : 'singleBase',
            multiBase : 'multiBase'
        }),
        annualPersonals () {
           return this.form.personal.filter(a => a.kind == 'grossannual')
        },
        grossPersonals () {
            return this.form.personal.filter(a => a.kind == 'grossmonthly')
        },
        pretaxPersonals () {
            return this.form.personal.filter(a => a.kind == 'pretax')
        },
        posttaxPersonals () {
            return this.form.personal.filter(a => a.kind == 'posttax')
        },
        benefitPersonals () {
            return this.form.personal.filter(a => a.kind == 'benefit')
        }
    },
    data () {
        return {
            twopaytoggle: false,
            showPDFCreated: false,
            itemKind:'grossmonthly',
            itemEdit: null,
            processingPDF: false,
            showPDFMessage: "",
            showSettings: false,
            showCustomItem: false,
            supportHover: false,
            grossHover:false,
            vsr1: 0.00,
            vsr2: 0.00,
            federalMinimum: 35568
        }
    },
    validations: {
        vsr1: {
            minValue: minValue(0),
            maxValue: maxValue(5000),
            decimal
        },
        vsr2: {
            minValue: minValue(0),
            maxValue: maxValue(5000),
            decimal
        },

    },
    created () {
        this.vsr1 = this.form.vsr1
        this.vsr2 = this.form.vsr2
        this.twopaytoggle = this.form.viewTwoToggle
    },
    mounted() {
        this.trackEvent('estimator_preview',{
            'two_plan': this.isTwoPlan.toString()
        })
    },
    methods: {
        ...mapActions('estimator',{
            togglePlan : 'togglePlan',
        }),
        toggleTwoPay () {
            this.twopaytoggle = !this.twopaytoggle
            this.togglePlan(this.twopaytoggle)
            this.vsr1 = this.form.vsr1
            this.vsr2 = this.form.vsr2
            this.$v.$touch()
        },
        togglePDFCreated () {
            this.showPDFCreated = !this.showPDFCreated
        },
        toggleSettings () {
            this.showSettings = !this.showSettings
            if(this.showSettings)
                this.trackEvent('estimator_viewsettings',{
                    'two_plan': this.isTwoPlan.toString()
                })
        },
        editCustomItem (item) {
            if(item) {
                 this.itemEdit = item
                 this.itemKind = item.kind
            }
            this.showCustomItem = !this.showCustomItem
        },
        toggleCustomItem (kind) {
            if(kind) this.itemKind = kind
            this.itemEdit = null
            this.showCustomItem = !this.showCustomItem
        },
        async generateReport() {

            this.processingPDF = true
            this.showPDFMessage = "1/3 Gathering report data..."
            this.trackEvent('estimator_generatepdf',{
                'two_plan': this.isTwoPlan.toString()
            })
            html2canvas(document.querySelector('#previewPanels'), {imageTimeout: 4000, useCORS: true}).then(canvas => {
                document.getElementById('pdf').appendChild(canvas)
                let img = canvas.toDataURL('image/png')
                let pdf = new jsPDF('portrait', 'mm', 'a4')
                const imgProps= pdf.getImageProperties(img);
                const pdfWidth = (pdf.internal.pageSize.getWidth())-5;
                const pdfHeight = ((imgProps.height * pdfWidth) / imgProps.width)-7;
                pdf.addImage(img, 'JPEG', 5, 5, pdfWidth,pdfHeight)
                pdf.save('ClearcompPreview.pdf')
                document.getElementById('pdf').innerHTML = ''
            })
            await delay(2000);
            this.showPDFCreated = true
            this.showPDFMessage = "2/3 Building PDF document..."
            await delay(1000);
            this.showPDFMessage = "3/3 Download started. (You can close this modal)."
            await delay(1000);
            this.showPDFCreated = false
            this.processingPDF = false
        },
        submit: _.debounce(function () {
            this.$emit('update', {
                data: {
                    vsr1 : this.vsr1,
                    vsr2 : this.vsr2
                },
                valid: !this.$v.$invalid
            })
        }, 500),
        startTour() {
            this.trackEvent('estimator_starttour',{
                'two_plan': this.isTwoPlan.toString()
            })
            console.log('start the tour')
            let steps = (this.isTwoPlan) ? twoPlanGuide : onePlanGuide
            introJs().setOptions({
                showProgress:true,
                tooltipClass: 'customTooltip',
                scrollToElement: false,
                steps
            }).start();
            localStorage.setItem('watchedTour',true)
        }
    }
}
</script>
<style>
.customTooltip * {
  color: #4a4a4a;
}
.customTooltip .introjs-tooltip-title {
  color: #0a41c9;
}
.customTooltip .introjs-tooltiptext {
    font-size:1em
}
:root {
  z-index:80
}
</style>