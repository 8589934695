<template>
  <div :class="topclass">
      <div class="flex">
        <div v-if="svg" class="flex-shrink-0">
          <!-- Heroicon name: solid/exclamation -->
          <svg class="h-5 w-5" :class="innerclass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" :d="svg" clip-rule="evenodd" />
          </svg>
        </div>
        <div :class="alerttype == 'mini' ? 'm-0 p-0' : 'ml-3'">
          <p :class="alerttype == 'mini' ? 'text-xs' : 'text-sm'">
            {{message}}
          </p>
        </div>
      </div>
  </div>
</template>
<script>
export default {
  name:'TWAlert',
  data () {
     return {
      alerts: [
        {
          name: 'warn',
          topclass: 'bg-yellow-50 border-l-4 border-yellow-400 p-4 text-yellow-700',
          innerclass: 'text-yellow-400',
          svg: 'M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z'
        },
         {
          name: 'error',
          topclass: 'rounded-md bg-red-50 p-4 text-red-700',
          innerclass: 'text-red-400',
          svg:'M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z'
        },
         {
          name: 'mini',
          topclass: 'p-1 text-red-600',
          innerclass: 'text-red-400'
        },
      ],
      topclass: null,
      innerclass: null,
      svg:null
    }
  },
  props: {
    alerttype: String,
    message: String

  },
  created () {
    let obj = this.alerts.find(o => o.name === this.alerttype);
    this.topclass = obj.topclass
    this.innerclass = obj.innerclass
    this.svg = obj.svg
  }

}
</script>