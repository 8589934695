<template>
  <div>

    <h2 class="text-center text-xl py-3 font-semibold	">(Optional) Elective Contributions <TWQType qtype="actual" /></h2>
        <p class="text-center text-xs mb-5">These are additonal retirement contributions or life insurance premiums <span class="font-semibold">above</span> your existing benefit plan.</p>

    <div class="space-y-6 text-left mx-auto">
      <!-- Retirement --->
      <div class="flex flex-col gap-3 md:items-center md:justify-between md:flex-row">
        <span class="flex-grow flex flex-col md:w-64" id="availability-label">
          <span class="text-sm font-medium text-gray-900" content="This is what you want to designate above what the organization contributes." data-balloon-length="large" v-tippy data-balloon-break="">Retirement (Pre-Tax/Post/Roth IRA)</span>
          <span class="text-sm text-gray-500">Will you be designating <span class="font-bold">additional monthly</span> contributions to your 403b?</span>
        </span>

        <div class="md:w-48">
          <!-- <label v-if="isTwoPlan" for="retirement" class="block text-sm font-medium text-gray-400">1-Paid or Spouse 1</label> -->
          <div class="flex flex-col">
            <div class="mb-1 pt-0 relative rounded-md shadow-sm">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm">
                    $
                </span>
                </div>
                <input v-model="$v.retirement.$model" @keyup="submit()" type="number" name="retirement" id="retirement" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md" placeholder="0.00" aria-describedby="529 contribution amount ">
                <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm" id="price-currency">
                    USD
                </span>
                </div>
            </div>
            <div v-if="(retirement || retirement2)&& !$v.retirement.$error" class="text-center text-xs text-gray-400">
            ${{(parseFloat(retirement) || 0)*12 | dollarAmt}} USD/year
             </div>
             <TWAlert v-if="$v.retirement.$error && !$v.retirement.decimal" alerttype="mini" message="Needs valid USD $ amount."></TWAlert>
            <TWAlert v-if="$v.retirement.$error && $v.retirement.decimal && !$v.retirement.minValue" alerttype="mini" message="Needs valid positive $ amount"></TWAlert>

          </div>
        </div>

        <!-- <div v-if="isTwoPlan" class="md:w-48">
          <label for="retirement2" class="block text-sm font-medium text-gray-400">Spouse 2</label>
          <div class="flex flex-col">
            <div class="mb-1 pt-0 relative rounded-md shadow-sm">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm">
                    $
                </span>
                </div>
                <input v-model="$v.retirement2.$model" @keyup="submit()" type="number" name="retirement" id="retirement" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md" placeholder="0.00" aria-describedby="529 contribution amount ">
                <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm" id="price-currency">
                    USD
                </span>
                </div>
            </div>
            <div v-if="(retirement || retirement2)&& !$v.retirement2.$error" class="text-center text-xs text-gray-400">
            ${{(parseFloat(retirement2) || 0)*12 | dollarAmt}} USD/year
            </div>
            <TWAlert v-if="$v.retirement2.$error && !$v.retirement2.decimal" alerttype="mini" message="Needs valid USD $ amount."></TWAlert>
            <TWAlert v-if="$v.retirement2.$error && $v.retirement2.decimal && !$v.retirement2.minValue" alerttype="mini" message="Needs valid positive $ amount"></TWAlert>

          </div>
        </div> -->
      </div>

      <!-- Retirement
      <div class="flex flex-col gap-3 md:items-center md:justify-between md:flex-row md:mt-3">
        <span class="flex-grow flex flex-col" id="availability-label">
          <span class="text-sm font-medium text-gray-900" content="Not a common election." data-balloon-length="large" v-tippy data-balloon-break="">ROTH/Post-Tax Retirement</span>
          <span class="text-sm text-gray-500">Are you doing post-tax or ROTH <span class="font-bold">monthly</span> contributions to your 403b?</span>
        </span>

        <div class="md:w-48">
          <label v-if="isTwoPlan" for="postretirement" class="block text-sm font-medium text-gray-400">1-Paid or Spouse 1</label>
          <div class="flex flex-col">
            <div class="mb-1 pt-0 relative rounded-md shadow-sm">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm">
                    $
                </span>
                </div>
                <input v-model="$v.postretirement.$model" @keyup="submit()" type="number" name="postretirement" id="postretirement" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md" placeholder="0.00" aria-describedby="529 contribution amount ">
                <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm" id="price-currency">
                    USD
                </span>
                </div>
            </div>
            <div v-if="(postretirement || postretirement2)&& !$v.postretirement.$error" class="text-center text-xs text-gray-400">
            ${{(parseFloat(postretirement) || 0)*12 | dollarAmt}} USD/year
             </div>
             <TWAlert v-if="$v.postretirement.$error && !$v.postretirement.decimal" alerttype="mini" message="Needs valid USD $ amount."></TWAlert>
            <TWAlert v-if="$v.postretirement.$error && $v.postretirement.decimal && !$v.postretirement.minValue" alerttype="mini" message="Needs valid positive $ amount"></TWAlert>

          </div>
        </div>

        <div v-if="isTwoPlan" class="md:w-48" >
          <label for="postretirement2" class="block text-sm font-medium text-gray-400">Spouse 2</label>
          <div class="flex flex-col">
            <div class="mb-1 pt-0 relative rounded-md shadow-sm">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm">
                    $
                </span>
                </div>
                <input v-model="$v.postretirement2.$model" @keyup="submit()" type="number" name="postretirement" id="postretirement" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md" placeholder="0.00" aria-describedby="529 contribution amount ">
                <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm" id="price-currency">
                    USD
                </span>
                </div>
            </div>
            <div v-if="(postretirement || postretirement2)&& !$v.postretirement2.$error" class="text-center text-xs text-gray-400">
            ${{(parseFloat(postretirement2) || 0)*12 | dollarAmt}} USD/year
            </div>
            <TWAlert v-if="$v.postretirement2.$error && !$v.postretirement2.decimal" alerttype="mini" message="Needs valid USD $ amount."></TWAlert>
            <TWAlert v-if="$v.postretirement2.$error && $v.postretirement2.decimal && !$v.postretirement2.minValue" alerttype="mini" message="Needs valid positive $ amount"></TWAlert>

          </div>
        </div>
      </div>--->

      <!-- Insurance --->
      <div class="flex flex-col gap-3 md:items-center md:justify-between md:flex-row">
        <span class="flex-grow flex flex-col" id="availability-label">
          <span class="text-sm font-medium text-gray-900" content="Not a common election. **Additional (supplemental) life can only be elected within 30 days of your hire date only." data-balloon-length="large" v-tippy data-balloon-break="">Supplemental Life Insurance
            <svg class="text-gray-300 inline h-5 w-5"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
            </svg>

          </span>
          <span class="text-sm text-gray-500">What was your elected <span class="font-bold">monthly</span> additional contribution? </span>
          <span class="text-xs text-gray-300"></span>
        </span>

        <div class="md:w-48">
          <!-- <label v-if="isTwoPlan" for="insurance" class="block text-sm font-medium text-gray-400">1-Paid or Spouse 1</label> -->
          <div class="flex flex-col">
            <div class="mb-1 pt-0 relative rounded-md shadow-sm">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm">
                    $
                </span>
                </div>
                <input v-model="$v.lifeinsurance.$model" @keyup="submit()" type="number" name="lifeinsurance" id="lifeinsurance" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md" placeholder="0.00" aria-describedby="529 contribution amount ">
                <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm" id="price-currency">
                    USD
                </span>
                </div>
            </div>
            <div v-if="(lifeinsurance || lifeinsurance2) && !$v.lifeinsurance.$error" class="text-center text-xs text-gray-400">
            ${{(parseFloat(lifeinsurance) || 0)*12 | dollarAmt}} USD/year
            </div>
             <TWAlert v-if="$v.lifeinsurance.$error && !$v.lifeinsurance.decimal" alerttype="mini" message="Needs valid USD $ amount."></TWAlert>
            <TWAlert v-if="$v.lifeinsurance.$error && $v.lifeinsurance.decimal && !$v.lifeinsurance.minValue" alerttype="mini" message="Needs valid positive $ amount"></TWAlert>
          </div>
        </div>

        <!-- <div v-if="isTwoPlan" class="md:w-48">
          <label for="insurance2" class="block text-sm font-medium text-gray-400">Spouse 2</label>
          <div class="flex flex-col">
            <div class="mb-1 pt-0 relative rounded-md shadow-sm">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm">
                    $
                </span>
                </div>
                <input v-model="$v.lifeinsurance2.$model" @keyup="submit()" type="number" name="lifeinsurance2" id="lifeinsurance2" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md" placeholder="0.00" aria-describedby="529 contribution amount ">
                <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm" id="price-currency">
                    USD
                </span>
                </div>
            </div>
            <div v-if="(lifeinsurance || lifeinsurance2) && !$v.lifeinsurance2.$error" class="text-center text-xs text-gray-400">
            ${{(parseFloat(lifeinsurance2) || 0)*12 | dollarAmt}} USD/year
            </div>
             <TWAlert v-if="$v.lifeinsurance2.$error && !$v.lifeinsurance2.decimal" alerttype="mini" message="Needs valid USD $ amount."></TWAlert>
            <TWAlert v-if="$v.lifeinsurance2.$error && $v.lifeinsurance2.decimal && !$v.lifeinsurance2.minValue" alerttype="mini" message="Needs valid positive $ amount"></TWAlert>
          </div>
        </div> -->

      </div>

    </div>

  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import {minValue,decimal} from 'vuelidate/lib/validators'
import TWAlert from './TWAlert.vue'
import TWQType from './TWQType.vue'
export default {
  components: {
    TWAlert,
    TWQType
  },
  data () {
    return {
      showTooltip: false,
      retirement: null,
      retirement2: null,
      postretirement: null,
      postretirement2: null,
      lifeinsurance: null,
      lifeinsurance2: null
    }
  },
  validations: {

    retirement: {
      decimal,
      minValue: minValue(0)
    },
    retirement2: {
      decimal,
      minValue: minValue(0)
    },
    postretirement: {
      decimal,
      minValue: minValue(0)
    },
    postretirement2: {
      decimal,
      minValue: minValue(0)
    },
    lifeinsurance: {
      decimal,
      minValue: minValue(0)
    },
    lifeinsurance2: {
      decimal,
      minValue: minValue(0)
    }

  },
  mounted () {
    this.retirement = this.form.additionalcontributions.retirement
    this.retirement2 = this.form.additionalcontributions.retirement2
    this.postretirement = this.form.additionalcontributions.postretirement
    this.postretirement2 = this.form.additionalcontributions.postretirement2
    this.lifeinsurance = this.form.additionalcontributions.lifeinsurance
    this.lifeinsurance2 = this.form.additionalcontributions.lifeinsurance2
  },
  computed: {
    ...mapState({
      form: state => state.estimator
    }),
    ...mapGetters('estimator',{
      isTwoPlan : 'isTwoPlan'
    })
  },
  methods: {
    toggleTooltip () {
      this.showTooltip = !this.showTooltip
    },
    submit () {
      const offsetR = parseFloat(this.form.additionalcontributions.retirementOffset) || 50
      const offsetL = parseFloat(this.form.additionalcontributions.lifeinsuranceOffset) || 50
      this.$emit('update',{
        data: {
          additionalcontributions: {
            retirement: this.retirement,
            retirement1: this.retirement*(offsetR/100),
            retirement2: this.retirement*((100-offsetR)/100),
            postretirement: this.postretirement,
            postretirement2: this.postretirement2,
            lifeinsurance: this.lifeinsurance,
            lifeinsurance1: this.lifeinsurance*(offsetL/100),
            lifeinsurance2: this.lifeinsurance*((100-offsetL)/100),
          }
        },
        valid: !this.$v.$invalid
      })
    }
  }
}
</script>

<style scoped>
</style>