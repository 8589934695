import Vuex from 'vuex'
import Vue from 'vue'
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

//modules
import estimator from './modules/estimator'
import disclaimer from './modules/disclaimer'
import faq from './modules/faq'
import post from './modules/post'
import planner from './modules/planner'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    estimator,
    disclaimer,
    faq,
    post,
    planner
  },
  state: {
  },
  plugins: [createPersistedState({
    key:'estimator',
    paths:['estimator','disclaimer','planner'],
    storage: {
      getItem: (key) => {
        try {
          return ls.get(key)
        }
        catch(error)
        {
          return localStorage.getItem(key)
        }
      },
      setItem: (key, value) => ls.set(key, value),
      removeItem: (key) => ls.remove(key)
    }
  })],
  getters: {
  },
  actions: {
  },
  mutations: {
  }

});
