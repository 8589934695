<template>
  <div>

    <h2 class="text-center text-xl py-3 font-semibold	">Taxes  <TWQType qtype="estimated" /></h2>
    <div class="space-y-6 max-w-3xl text-left mx-auto">

      <div class="flex items-center justify-between">
        <span class="flex-grow flex flex-col" id="availability-label">
          <span class="text-sm font-medium text-gray-900">Do you estimate withholding federal or state taxes for 2022?</span>
          <span class="text-sm text-gray-500">Amounts will be a rough estimate based on your current withholding or <span class="font-semibold">recommended by your tax expert</span>.</span>
        </span>
        <!-- Enabled: "bg-indigo-600", Not Enabled: "bg-gray-200" -->
        <button type="button" @click="isWithholding()" :aria-pressed="iswithholding ? 'true' : 'false'" :class="iswithholding ? 'bg-indigo-600' : 'bg-gray-200'" class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"  aria-labelledby="availability-label">
          <span class="sr-only">Has children</span>
          <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
          <span aria-hidden="true" :class="iswithholding ? 'translate-x-5' : 'translate-x-0'" class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
        </button>
      </div>

      <div v-if="iswithholding" class="flex flex-col gap-3 md:items-center md:justify-between md:flex-row md:mt-3">
        <span class="flex-grow flex flex-col" id="availability-label">
          <span class="text-sm font-medium text-gray-900">Federal Withholding</span>
          <span class="text-sm text-gray-500">What is approximate <span class="font-bold">estimated monthly</span> federal withholding in 2022?</span>
        </span>

        <div class="md:w-48">
          <!-- <label v-if="isTwoPlan" for="flexiblespendingplan" class="block text-sm font-medium text-gray-400">1-Paid or Spouse 1</label> -->
          <div class="flex flex-col">
            <div class="mb-1 pt-0 relative rounded-md shadow-sm">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm">
                    $
                </span>
                </div>
                <input v-model="$v.federalwithholding.$model" @keyup="submit()" type="number" name="federalwithholding" id="federalwithholding" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md" placeholder="0.00" aria-describedby="529 contribution amount ">
                <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm" id="price-currency">
                    USD
                </span>
                </div>
            </div>  
            <div v-if="(federalwithholding || federalwithholding2) && !$v.federalwithholding.$error" class="text-center text-xs text-gray-400">
            ${{(parseFloat(federalwithholding) || 0)*12 | dollarAmt}} annually
            </div>
            <TWAlert v-if="$v.federalwithholding.$error && !$v.federalwithholding.decimal" alerttype="mini" message="Needs valid USD $ amount."></TWAlert>
            <TWAlert v-if="$v.federalwithholding.$error && $v.federalwithholding.decimal && !$v.federalwithholding.minValue" alerttype="mini" message="Needs valid positive $ amount"></TWAlert>          
          </div>
        </div>

        <!-- <div v-if="isTwoPlan" class="md:w-48">
          <label for="federalwithholding2" class="block text-sm font-medium text-gray-400">Spouse 2</label>
          <div class="flex flex-col">
            <div class="mb-1 pt-0 relative rounded-md shadow-sm">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm">
                    $
                </span>
                </div>
                <input v-model="$v.federalwithholding2.$model" @keyup="submit()" type="number" name="federalwithholding2" id="federalwithholding2" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md" placeholder="0.00" aria-describedby="529 contribution amount ">
                <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm" id="price-currency">
                    USD
                </span>
                </div>
            </div>  
            <div v-if="(federalwithholding2 || federalwithholding) && !$v.federalwithholding2.$error" class="text-center text-xs text-gray-400">
            ${{(parseFloat(federalwithholding2) || 0)*12 | dollarAmt}} annually
            </div>
            <TWAlert v-if="$v.federalwithholding2.$error && !$v.federalwithholding2.decimal" alerttype="mini" message="Needs valid USD $ amount."></TWAlert>
            <TWAlert v-if="$v.federalwithholding2.$error && $v.federalwithholding2.decimal && !$v.federalwithholding2.minValue" alerttype="mini" message="Needs valid positive $ amount"></TWAlert>          
          </div>
        </div>         -->
      </div>

      <div v-if="iswithholding" class="flex flex-col gap-3 md:items-center md:justify-between md:flex-row md:mt-3">
        <span class="flex-grow flex flex-col" id="availability-label">
          <span class="text-sm font-medium text-gray-900">State Withholding</span>
          <span class="text-sm text-gray-500">What is approximate <span class="font-bold">estimated monthly</span> state withholding in 2022?</span>
        </span>

        <div class="md:w-48">
          <!-- <label v-if="isTwoPlan" for="statewithholding" class="block text-sm font-medium text-gray-400">1-Paid or Spouse 1</label> -->
          <div class="flex flex-col">
            <div class="mb-1 pt-0 relative rounded-md shadow-sm">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm">
                    $
                </span>
                </div>
                <input v-model="$v.statewithholding.$model" @keyup="submit()" type="number" name="statewithholding" id="statewithholding" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md" placeholder="0.00" aria-describedby="529 contribution amount ">
                <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm" id="price-currency">
                    USD
                </span>
                </div>
            </div>
            <div v-if="(statewithholding || statewithholding2) && !$v.statewithholding.$error" class="text-right text-xs text-gray-400">
            ${{(parseFloat(statewithholding) || 0)*12 | dollarAmt}} USD annually
            </div> 
            <TWAlert v-if="$v.statewithholding.$error && !$v.statewithholding.decimal" alerttype="mini" message="Needs valid USD $ amount."></TWAlert>
            <TWAlert v-if="$v.statewithholding.$error && $v.statewithholding.decimal && !$v.statewithholding.minValue" alerttype="mini" message="Needs valid positive $ amount"></TWAlert>        
          </div>
        </div>

        <!-- <div v-if="isTwoPlan" class="md:w-48">
          <label for="statewithholding2" class="block text-sm font-medium text-gray-400">Spouse 2</label>
          <div class="flex flex-col">
            <div class="mb-1 pt-0 relative rounded-md shadow-sm">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm">
                    $
                </span>
                </div>
                <input v-model="$v.statewithholding2.$model" @keyup="submit()" type="number" name="statewithholding2" id="statewithholding2" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md" placeholder="0.00" aria-describedby="529 contribution amount ">
                <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm" id="price-currency">
                    USD
                </span>
                </div>
            </div>
            <div v-if="(statewithholding || statewithholding2) && !$v.statewithholding2.$error" class="text-right text-xs text-gray-400">
            ${{(parseFloat(statewithholding2) || 0)*12 | dollarAmt}} USD annually
            </div> 
            <TWAlert v-if="$v.statewithholding2.$error && !$v.statewithholding2.decimal" alerttype="mini" message="Needs valid USD $ amount."></TWAlert>
            <TWAlert v-if="$v.statewithholding2.$error && $v.statewithholding2.decimal && !$v.statewithholding2.minValue" alerttype="mini" message="Needs valid positive $ amount"></TWAlert>        
          </div>
        </div>         -->
      </div>




    </div>

  </div>
</template>

<script>
import {minValue,decimal} from 'vuelidate/lib/validators'
import {mapState, mapGetters} from 'vuex'
import TWAlert from './TWAlert.vue'
import TWQType from './TWQType.vue'
export default {
  components: {
    TWAlert,
    TWQType
  },
  data () {
    return {
      iswithholding: false,
      federalwithholding: null,
      federalwithholding2: null,
      statewithholding: null,
      statewithholding2: null
    }
  },
  validations: {

    federalwithholding: {
      decimal,
      minValue: minValue(0)
    },
    statewithholding: {
      decimal,
      minValue: minValue(0)
    },
    federalwithholding2: {
      decimal,
      minValue: minValue(0)
    },
    statewithholding2: {
      decimal,
      minValue: minValue(0)
    }    

  },
  computed: {
    ...mapState({
      form: state => state.estimator
    }),
    ...mapGetters('estimator',{
      isTwoPlan : 'isTwoPlan'
    }) 
  },
  created () {
    this.iswithholding = this.form.iswithholding
    this.federalwithholding = this.form.taxes.federalwithholding
    this.statewithholding = this.form.taxes.statewithholding
    this.federalwithholding2= this.form.taxes.federalwithholding2
    this.statewithholding2 = this.form.taxes.statewithholding2 
  },
  methods: {
    isWithholding() {
      this.iswithholding = !this.iswithholding
      if(!this.iswithholding) {
        this.federalwithholding = null
        this.federalwithholding2 = null
        this.statewithholding = null
        this.statewithholding2 = null        
      }      
      this.submit()
    },
    submit () {
      const offsetF = parseFloat(this.form.taxes.federalwithholdingOffset) || 50 
      const offsetS = parseFloat(this.form.taxes.statewithholdingOffset) || 50 

      this.$emit('update',{
        data: {
          iswithholding: this.iswithholding,
          taxes: {
            federalwithholding: this.federalwithholding,
            federalwithholding1: this.federalwithholding*(offsetF/100),    
            federalwithholding2: this.federalwithholding*((100-offsetF)/100), 
            statewithholding: this.statewithholding,
            statewithholding1: this.statewithholding*(offsetS/100),    
            statewithholding2: this.statewithholding*((100-offsetS)/100),          
          }
        },
        valid: !this.$v.$invalid
      })
    }
  }
}
</script>

<style scoped>

</style>