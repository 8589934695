<template>
  <div class="h-screen overflow-visible bg-gray-300">
    <div class="fixed bg-white w-full z-20 shadow print:hidden">
      <div class="max-w-7xl mx-auto flex justify-between items-center px-4 py-5 sm:px-6 sm:py-4 lg:px-8 md:justify-start md:space-x-10">
        <div>
          <a href="#" class="flex">
            <span class="sr-only">ClearComp</span>
            <img class="h-7 p-0 md:p-1 w-auto sm:h-10" src="/vector/default-monochrome.svg" alt="">
          </a>
        </div>
        <div class="-mr-2 -my-2 md:hidden">
          <button type="button" @click="showMobile = !showMobile" class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
            <span class="sr-only">Open menu</span>
            <!-- Heroicon name: menu -->
            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>
        <div class="hidden md:flex-1 md:flex md:items-center md:justify-between">
          <nav class="flex space-x-10">
            <router-link to="/program" class="text-base font-medium text-gray-500 hover:text-gray-900" active-class="text-gray-900">
            Program
            </router-link>
            <router-link to="/faqs" class="text-base font-medium text-gray-500 hover:text-gray-900" active-class="text-gray-900">
            FAQs
            </router-link>
            <router-link to="/budget" class="text-base font-medium text-gray-500 hover:text-gray-900" active-class="text-gray-900">
            Budget Plan
            </router-link>
            <router-link to="/estimator" class="text-base font-medium text-gray-500 hover:text-gray-900" active-class="text-gray-900">
            Estimator
            </router-link>





            <!-- <router-link to="help" class="text-base font-medium text-gray-500 hover:text-gray-900" active-class="text-gray-900">
            Help
            </router-link> -->
          </nav>
          <div class="flex items-center md:ml-12">
            <FrillWidget  />
            <button @click="exitTool" class="ml-6 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700">
            Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--
      Mobile menu, show/hide based on mobile menu state.

      Entering: "duration-200 ease-out"
        From: "opacity-0 scale-95"
        To: "opacity-100 scale-100"
      Leaving: "duration-100 ease-in"
        From: "opacity-100 scale-100"
        To: "opacity-0 scale-95"
    -->
    <transition
      enter-active-class="duration-200 ease-out"
      enter-from-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="duration-100 ease-in"
      leave-from-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <div v-show="showMobile" class="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
        <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
          <div class="pt-5 pb-6 px-5 sm:pb-8">
            <div class="flex items-center justify-between">
              <div>
                <img class="h-8 w-auto" src="/vector/default-monochrome.svg" alt="Workflow">
              </div>
              <div class="-mr-2">
                <button type="button" @click="showMobile = !showMobile" class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span class="sr-only">Close menu</span>
                  <!-- Heroicon name: x -->
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
            <div class="mt-6 sm:mt-8">
              <nav @click="showMobile = !showMobile">
                <div class="grid gap-7 sm:grid-cols-2 sm:gap-y-8 sm:gap-x-4">
                  <router-link to="/program" class="-m-3 flex items-center p-3 rounded-lg hover:bg-gray-50">
                    <div class="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                      <!-- Heroicon name: view-grid -->
                      <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
                      </svg>
                    </div>
                    <div class="ml-4 text-base font-medium text-gray-900">
                    Program
                    </div>
                  </router-link>

                  <router-link to="/faqs" class="-m-3 flex items-center p-3 rounded-lg hover:bg-gray-50">
                    <div class="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                      <!-- Heroicon name: view-grid -->
                      <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
                      </svg>
                    </div>
                    <div class="ml-4 text-base font-medium text-gray-900">
                    FAQs
                    </div>
                  </router-link>
                   <router-link to="/estimator" class="-m-3 flex items-center p-3 rounded-lg hover:bg-gray-50">
                    <div class="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                      <!-- Heroicon name: chart-bar -->
                      <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                      </svg>
                    </div>
                    <div class="ml-4 text-base font-medium text-gray-900">
                     Estimator
                    </div>
                  </router-link>
                   <router-link to="/budget" class="-m-3 flex items-center p-3 rounded-lg hover:bg-gray-50">
                    <div class="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                      <!-- Heroicon name: chart-bar -->
                      <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                      </svg>
                    </div>
                    <div class="ml-4 text-base font-medium text-gray-900">
                     Budget
                    </div>
                  </router-link>


                </div>
                <!-- <div class="mt-8 text-base">
                  <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500"> View all products <span aria-hidden="true">&rarr;</span></a>
                </div> -->
              </nav>
            </div>
          </div>
          <div class="py-6 px-5">
            <!-- <div class="grid grid-cols-2 gap-4">
              <a href="#" class="rounded-md text-base font-medium text-gray-900 hover:text-gray-700">
                Pricing
              </a>

              <a href="#" class="rounded-md text-base font-medium text-gray-900 hover:text-gray-700">
                Docs
              </a>

              <a href="#" class="rounded-md text-base font-medium text-gray-900 hover:text-gray-700">
                Company
              </a>

              <a href="#" class="rounded-md text-base font-medium text-gray-900 hover:text-gray-700">
                Resources
              </a>

              <a href="#" class="rounded-md text-base font-medium text-gray-900 hover:text-gray-700">
                Blog
              </a>

              <a href="#" class="rounded-md text-base font-medium text-gray-900 hover:text-gray-700">
                Contact Sales
              </a>
            </div> -->
            <div class="mt-6">
              <button @click="exitTool" class="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700">
              Close Tool
              </button>
              <!-- <p class="mt-6 text-center text-base font-medium text-gray-500">
                Existing customer?
                <a href="#" class="text-indigo-600 hover:text-indigo-500">
                  Sign in
                </a>
              </p> -->
            </div>
          </div>
        </div>
      </div>
    </transition>

    <main class="overflow-scroll h-screen pt-12 bg-gradient-to-r from-indigo-200 via-grey to-green-200" >
      <div class="max-w-7xl mx-auto py-1 sm:px-6 sm:py-6 lg:px-8">
        <!-- Replace with your content -->
        <div class="overflow-visible h-screen px-0 py-4 sm:px-0 ">

            <router-view @ready="pageReady"></router-view>

        </div>
        <!-- /End replace -->
      </div>
    </main>
    <script type="application/javascript" defer>window.$crisp=[];window.CRISP_WEBSITE_ID="f80c8403-2eae-4c99-b5c0-d042bdee0103";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();</script>

  </div>
</template>

<script>
import VueJwtDecode from "vue-jwt-decode";
import FrillWidget from '../components/WidgetFrill.vue';

export default {
  data: () => ({
    showMobile: false,
    showSolutions: false,
    showMore: false,
    showFrillWidget: true,
    user: {}
  }),
  components: {
    FrillWidget
  },
  name: 'Template',
  created () {
    this.$emit('ready')
  },
  methods: {
    getUserDetails() {
      let token = localStorage.getItem("jwt");
      let decoded = VueJwtDecode.decode(token);
      this.user = decoded;
    },
    exitTool () {
      localStorage.removeItem('projectCode')
      localStorage.removeItem('jwt')
      this.$router.push({name: 'Login'})
    },
    pageReady () {
      this.$emit('ready')
    }
  }
}
</script>

<style scoped>
</style>
