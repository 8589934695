<template>
  <div>
    <h2 class="text-center text-xl py-3 font-semibold	">Leadership and Ordination  <TWQType qtype="estimated" /></h2>

    <div class="space-y-6 text-left ">

      <div class="flex items-center justify-between ">
        <span class="flex-grow flex flex-col" id="availability-label">
          <span class="text-sm font-medium text-gray-900">Ordained</span>
          <span class="text-sm text-gray-500">Are you ordained or do you expect to be ordained in or by January 2022? </span>
        </span>
        <!-- Enabled: "bg-indigo-600", Not Enabled: "bg-gray-200" -->
        <button type="button" @click="isOrdained()" :aria-pressed="form.ordained ? 'true' : 'false'" :class="form.ordained ? 'bg-indigo-600' : 'bg-gray-200'" class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"  aria-labelledby="availability-label">
          <span class="sr-only">Is ordained?</span>
          <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
          <span aria-hidden="true" :class="form.ordained ? 'translate-x-5' : 'translate-x-0'" class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
        </button>
      </div>

      <div v-if="form.ordained" class="flex items-center justify-between">
        <span class="flex-grow flex flex-col" id="availability-label">
          <span class="text-sm text-gray-500">Did you exempt out of FICA? (Not common)</span>

        </span>
        <!-- Enabled: "bg-indigo-600", Not Enabled: "bg-gray-200" -->
        <button type="button" @click="isOrdainedExempt()" :aria-pressed="form.ordainedSSExempt ? 'true' : 'false'" :class="form.ordainedSSExempt ? 'bg-indigo-600' : 'bg-gray-200'" class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"  aria-labelledby="availability-label">
          <span class="sr-only">Is ordained?</span>
          <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
          <span aria-hidden="true" :class="form.ordainedSSExempt ? 'translate-x-5' : 'translate-x-0'" class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
        </button>

      </div>

      <!-- <div v-if="form.ordained" class="flex items-center justify-between">
        <span class="flex-grow flex flex-col" id="availability-label">
          <span class="text-sm font-medium text-gray-900">Ordained Housing</span>
          <span class="text-sm text-gray-500">What is the <span class="font-bold">monthly</span> amount you designate for housing?</span>
          <TWAlert v-if="$v.form.ordainedhousing.$error && !$v.form.ordainedhousing.decimal" alerttype="mini" message="Needs valid USD $ amount."></TWAlert>

        </span>
        <div class="flex flex-col">
          <div class="mb-1 pt-0 relative rounded-md shadow-sm">
              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <span class="text-gray-500 sm:text-sm">
                  $
              </span>
              </div>
              <input v-model="$v.form.ordainedhousing.$model" @blur="submit()" type="number" name="ordainedhousing" id="ordainedhousing" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md" placeholder="0.00" aria-describedby="529 contribution amount ">
              <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <span class="text-gray-500 sm:text-sm" id="price-currency">
                  USD
              </span>
              </div>
          </div>
          <div v-if="form.ordainedhousing" class="text-center text-xs text-gray-400">
          ${{parseFloat(form.ordainedhousing)*12 | dollarAmt}} USD/annually
          </div>
        </div>

      </div> -->

      <div class="flex items-center justify-between">
        <span class="flex-grow flex flex-col" id="availability-label">
          <span class="text-sm font-medium text-gray-900">Leadership</span>
          <span v-if="isTwoPlan" class="text-sm text-gray-500">Next January will either of you be in a leadership position?</span>
          <span v-else class="text-sm text-gray-500">Next January will you be in a leadership position?</span>
        </span>
        <!-- Enabled: "bg-indigo-600", Not Enabled: "bg-gray-200" -->
        <button type="button" @click="isLeader()" :aria-pressed="form.leadership ? 'true' : 'false'" :class="form.leadership ? 'bg-indigo-600' : 'bg-gray-200'" class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"  aria-labelledby="availability-label">
          <span class="sr-only">Is leadership?</span>
          <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
          <span aria-hidden="true" :class="form.leadership ? 'translate-x-5' : 'translate-x-0'" class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
        </button>
      </div>

      <div v-if="form.leadership" class="flex flex-col gap-2 md:items-center md:justify-between md:flex-row">
        <span class="flex-grow flex flex-col " id="availability-label">
          <span class="text-sm font-medium text-gray-900">Field Responsiblity</span>
          <span class="text-sm text-gray-500">Select leadership role(s)</span>
          <TWAlert v-if="form.leadership && !$v.form.fieldresponsiblity.required" alerttype="mini" message="At least one position must be selected"></TWAlert>

        </span>

        <div class="flex-1 " >
          <label v-if="isTwoPlan" for="fieldresponsiblity" class="block text-sm font-medium text-gray-400">1-Paid or Spouse 1</label>
          <div class="flex flex-col">
            <select v-model="form.fieldresponsiblity" @click="submit" id="form.fieldresponsiblity" name="form.fieldresponsiblity" class="mt-1 block md:w-56 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
              <option value="100">Assistant TL</option>
              <option value="200">TL</option>
              <option value="300">CC/RC</option>
              <option value="400">CD</option>
              <option value="550">RD</option>
              <option value="600">ID</option>
            </select>
            <div v-if="form.fieldresponsiblity || form.fieldresponsiblity2" class="text-center text-xs text-gray-400">
            ${{(parseFloat(form.fieldresponsiblity) || 0) | dollarAmt}}/mo
            </div>
          </div>
        </div>

        <div v-if="isTwoPlan" >
          <label for="fieldresponsiblity2" class="block text-sm font-medium text-gray-400">Spouse 2</label>
          <div class="flex flex-col">
            <select v-model="form.fieldresponsiblity2" @click="submit" id="form.fieldresponsiblity2" name="form.fieldresponsiblity2" class="mt-1 block md:w-56 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
              <option value="100">Assistant TL</option>
              <option value="200">TL</option>
              <option value="300">CC/RC</option>
              <option value="400">CD</option>
              <option value="550">RD</option>
              <option value="600">ID</option>
            </select>
            <div v-if="form.fieldresponsiblity2 || form.fieldresponsiblity" class="text-center text-xs text-gray-400">
            ${{(parseFloat(form.fieldresponsiblity2) || 0) | dollarAmt}}/mo
            </div>
          </div>
        </div>

      </div>


    </div>
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import {decimal,required} from 'vuelidate/lib/validators'
import TWAlert from './TWAlert.vue'
import TWQType from './TWQType.vue'
export default {
  components: {
    TWAlert,
    TWQType
  },
  computed: {
    ...mapState({
      formdata: state => state.estimator
    }),
    ...mapGetters('estimator',{
      isTwoPlan : 'isTwoPlan'
    })
  },
  data () {
    return {
      form: {
        leadership: false,
        fieldresponsiblity: null,
        fieldresponsiblity2: null,
        ordained: false,
        ordainedhousing: null,
        ordainedSSExempt: false,
      }
    }
  },
  validations() {
    let d,b = {}
    if(this.form.leadership) {
      d = {
        fieldresponsiblity: {
          required
        }
      }
    }
    if(this.form.ordained) {
      b = {
        ordainedhousing: {
            decimal
        }
      }
    }
    return {form: {...d,...b}}
  },
  created () {
    this.form.leadership = this.formdata.leadership
    this.form.fieldresponsiblity = this.formdata.fieldresponsiblity
    this.form.fieldresponsiblity2 = this.formdata.fieldresponsiblity2
    this.form.ordained = this.formdata.ordained
    this.form.ordainedhousing = this.formdata.ordainedhousing
    this.form.ordainedSSExempt = this.formdata.ordainedSSExempt
  },
  methods: {
    isOrdained () {
      this.form.ordained = !this.form.ordained
      if(!this.form.ordained) {
        this.form.ordainedhousing = null
        this.form.ordainedSSExempt = false
      }
      this.submit()
    },
    isOrdainedExempt () {
      this.form.ordainedSSExempt = !this.form.ordainedSSExempt
      this.submit()
    },
    isLeader () {
      this.form.leadership = !this.form.leadership
      if(!this.form.leadership)
        this.form.fieldresponsiblity = null
        this.form.fieldresponsiblity2 = null
      this.submit()
    },
    submit () {
      this.$emit('update',{
        data: this.form,
        valid: !this.$v.$invalid
      })
    }
  }
}
</script>

<style scoped>

</style>