<template>
  <div class="container mx-auto">

    <h2 class="text-center text-xl pb-3 font-semibold">Comparison Report <TWQType qtype="actual" /></h2>
    <p class="text-center text-sm mb-3">Now we need to transfer a couple fields from the comparison report to be able to run the estimator.</p>

  <div class="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6 md:mt-6">
    <!-- Only needed for forcing same size grids
      :class="isTwoPlan ? 'md:grid-cols-5 md:grid-rows-6' : 'md:grid-cols-3 md:grid-rows-4'"
    -->
    <div class="md:grid md:gap-2">
      <div class="hidden flex md:inline-flex md:col-span-1 " :class="isTwoPlan ? 'md:row-span-3' : ''">

      </div>
      <div class="hidden flex md:inline-flex md:col-span-1 bg-gray-200 justify-center items-center text-center p-3" :class="isTwoPlan ? 'md:row-span-3' : ''">
      2020 Actual YTD Earnings
      </div>
      <div class="hidden flex md:inline-flex md:row-span-1 bg-blue-100 justify-center items-center p-4" :class="isTwoPlan ? 'md:col-span-3' : 'md:col-span-1'">
      ClearComp Estimates
      </div>
      <div v-if="isTwoPlan" class="hidden flex md:inline-flex md:col-span-1 md:row-span-2 bg-blue-50 justify-center items-center p-4">
        One-Paid Spouse
      </div>
      <div v-if="isTwoPlan" class="hidden flex md:inline-flex md:col-span-2 bg-green-100 justify-center items-center p-4">
        Two-Paid Spouses
      </div>
      <div v-if="isTwoPlan" class="hidden flex md:inline-flex md:col-span-1 bg-green-50 justify-center items-center p-4">
        Employee 1
      </div>
      <div v-if="isTwoPlan" class="hidden flex md:inline-flex md:col-span-1 bg-green-50 justify-center items-center p-4">
        Employee 2
      </div>
      <div class="md:col-span-1 justify-center md:items-center mb-2 pt-3">
          <h3 class="text-lg font-medium leading-6 text-gray-900">Goods and Services</h3>
          <p class="mt-1 text-xs text-gray-400">
          Enter the <span class="font-bold">same</span> Goods and Service line values from the report.
          </p>
      </div>
      <div class="hidden md:block md:col-span-1 justify-center items-center">
        <label for="cola2020" class="block text-sm font-medium text-gray-100">2020 Actual</label>
        <input type="text" name="cola2020" id="cola2020" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:opacity-50 bg-gray-100" disabled>
      </div>
      <div class="md:col-span-1 justify-center items-center">
        <label v-if="isTwoPlan" for="colaonepaid" class="block text-sm font-medium text-gray-400">1 Paid Employee</label>
        <label v-else for="colaonepaid" class="block text-sm font-medium text-gray-400">Annual Estimates</label>
        <div class="flex flex-col">
          <div class="mt-1 pt-0 relative rounded-md shadow-sm">
              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <span class="text-gray-500 sm:text-sm">
                  $
              </span>
              </div>
              <input v-model="$v.costofliving1paid.$model" @blur="submit()" type="number" name="colaonepaid" id="colaonepaid" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md" placeholder="0.00" aria-describedby="price-currency">
              <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <span class="text-gray-500 sm:text-sm" id="price-currency">
                  USD
              </span>
              </div>
          </div>
          <div v-if="costofliving1paid" class="text-center text-xs text-gray-400">
          ${{parseFloat(costofliving1paid)/12 | dollarAmt}}/mo
          </div>
        </div>
        <TWAlert v-if="$v.costofliving1paid.$error && !$v.costofliving1paid.required" alerttype="mini" message="Annual Goods &amp; Services $ amount required."></TWAlert>
        <TWAlert v-if="$v.costofliving1paid.$error && !$v.costofliving1paid.decimal" alerttype="mini" message="A valid dollar amount required. "></TWAlert>
        <TWAlert v-if="costofliving1paid > 50000" alerttype="mini" message="Do you live on Mars?"></TWAlert>
      </div>
      <div v-if="isTwoPlan" class="md:col-span-1 justify-center items-center">
        <label for="cola2paid1" class="block text-sm font-medium text-gray-400"><span class="inline md:hidden">2 Paid Employee - </span>Employee 1  </label>
        <div class="flex flex-col">
          <div class="mt-1 pt-0 relative rounded-md shadow-sm">
              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <span class="text-gray-500 sm:text-sm">
                  $
              </span>
              </div>
              <input v-model="$v.costofliving2paid.$model" @blur="submit()" type="number" name="cola2paid1" id="cola2paid1" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md" placeholder="0.00" aria-describedby="price-currency">
              <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <span class="text-gray-500 sm:text-sm" id="price-currency">
                  USD
              </span>
              </div>
          </div>
          <div v-if="costofliving2paid" class="text-center text-xs text-gray-400">
          ${{parseFloat(costofliving2paid)/12 | dollarAmt}}/mo
          </div>
        </div>
        <TWAlert v-if="$v.costofliving2paid.$error && !$v.costofliving2paid.required" alerttype="mini" message="Annual Goods &amp; Services $ amount required."></TWAlert>
        <TWAlert v-if="$v.costofliving2paid.$error && !$v.costofliving2paid.decimal" alerttype="mini" message="A valid dollar amount required."></TWAlert>
      </div>
      <div v-if="isTwoPlan" class="hidden md:block md:col-span-1 justify-center items-center">
        <label for="cola2paid2" class="block text-sm font-medium text-gray-400"><span class="inline md:hidden">2 Paid Employee - </span>Employee 2</label>
        <div class="flex flex-col">
          <div class="mt-1 pt-0 relative rounded-md shadow-sm">
              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <span class="text-gray-500 sm:text-sm">
                  $
              </span>
              </div>
              <input v-model="$v.costofliving2paid.$model" @blur="submit()" type="number" name="cola2paid2" id="cola2paid2" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md" placeholder="0.00" aria-describedby="price-currency" disabled>
              <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <span class="text-gray-500 sm:text-sm" id="price-currency">
                  USD
              </span>
              </div>
          </div>
          <div v-if="costofliving2paid" class="text-center text-xs text-gray-400">
          ${{parseFloat(costofliving2paid)/12 | dollarAmt}}/mo
          </div>
        </div>
      </div>
      <div class="hidden md:block justify-center items-center pt-3" :class="isTwoPlan ? 'md:col-span-5' : 'md:col-span-3'">
        <div class="relative">
          <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="w-full border-t border-gray-300"></div>
          </div>
          <div class="relative flex justify-center">
            <span class="px-2 bg-white text-sm text-gray-500">
            Totals
            </span>
          </div>
        </div>
      </div>
      <div class="md:col-span-1 justify-center md:items-center mb-2 pt-3">
          <h3 class="text-lg font-medium leading-6 text-gray-900">Total Annual Gross Projections </h3>
          <p class="mt-1 text-xs text-gray-400">
          Enter your <span class="font-bold">2020 Actual Annual Gross</span> from the report.
          </p>
      </div>
      <div class="md:col-span-1 justify-center items-center">
        <label for="totalannual2020" class="block text-sm font-medium text-gray-400">2020 Annual Actual</label>
        <div class="flex flex-col">
          <div class="mt-1 pt-0 relative rounded-md shadow-sm">
              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <span class="text-gray-500 sm:text-sm">
                  $
              </span>
              </div>
              <input v-model="$v.takehomegross.$model" @keyup="submit()" type="number" name="totalannual2020" id="totalannual2020" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md" placeholder="0.00" aria-describedby="price-currency">
              <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <span class="text-gray-500 sm:text-sm" id="price-currency">
                  USD
              </span>
              </div>
          </div>
          <div v-if="takehomegross" class="text-center text-xs text-gray-400">
          ${{parseFloat(takehomegross)/12 | dollarAmt}}/mo
          </div>
        </div>
        <TWAlert v-if="$v.takehomegross.$error && !$v.takehomegross.required" alerttype="mini" message="You'll need a 2020 annual gross amount."></TWAlert>
        <TWAlert v-if="$v.takehomegross.$error && !$v.takehomegross.decimal" alerttype="mini" message="2020 annual gross needs to be a valid USD amount. "></TWAlert>
        <TWAlert v-if="$v.takehomegross.$error && $v.takehomegross.decimal && !$v.takehomegross.minValue" alerttype="mini" message="Annual gross should be a positive monthly dollar amt."></TWAlert>
      </div>
      <div class="hidden md:block md:col-span-1 justify-center items-center">
        <label v-if="isTwoPlan" for="totalannual1paid" class="block text-sm font-medium text-gray-100">One-Paid Total</label>
        <label v-else for="totalannual1paid" class="block text-sm font-medium text-gray-300">ClearComp Estimates</label>
        <input type="text" name="totalannual1paid" id="totalannual1paid" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:opacity-50 bg-gray-100" disabled>
      </div>
      <div v-if="isTwoPlan" class="md:col-span-1 justify-center items-center">

      </div>
      <div v-if="isTwoPlan" class="hidden md:block md:col-span-1 justify-center items-right">
        <label for="totalannual2paid" class="block text-sm font-medium text-gray-100">Two-Paid Total</label>
        <input type="text" name="totalannual2paid" id="totalannual2paid" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:opacity-50 bg-gray-100" disabled>
      </div>
    </div>
  </div>

  </div>
</template>

<script>
import {required,minValue,decimal} from 'vuelidate/lib/validators'
import {mapState, mapGetters} from 'vuex'
import TWAlert from './TWAlert.vue'
import TWQType from './TWQType.vue'
export default {
  components: {
    TWAlert,
    TWQType
  },
  name:'FormComparisonReport',
  data () {
    return {
      costofliving1paid: null,
      costofliving2paid: null,
      takehomegross: null,
    }
  },
  validations() {
    if(this.isTwoPlan) {
      return {
        costofliving1paid: {
          required,
          decimal
        },
        costofliving2paid: {
          required,
          decimal
        },
        takehomegross: {
          required,
          decimal,
          minValue: minValue(0)
        }
      }
    } else {
      return {
        costofliving1paid: {
          required,
          decimal
        },
        takehomegross: {
          required,
          decimal,
          minValue: minValue(0)
        }
      }
    }
  },
  computed: {
    ...mapState({
      form: state => state.estimator
    }),
    ...mapGetters('estimator',{
      isTwoPlan : 'isTwoPlan'
    }),
  },
  created () {
    this.costofliving1paid = this.form.costofliving1paid
    this.costofliving2paid = this.form.costofliving2paid
    this.takehomegross = this.form.takehomegross
  },
  methods: {
    submit () {
      this.$v.$touch()
      this.$emit('update',{
        data: {
          costofliving1paid: this.costofliving1paid,
          costofliving2paid: this.costofliving2paid,
          takehomegross: this.takehomegross,
        },
        valid: !this.$v.$invalid
      })
    }
  }
}
</script>

<style scoped>

</style>