<template>
  <div>

    <h2 class="text-center text-xl py-3 font-semibold	">Dependents and Education  <TWQType qtype="estimated" /></h2>

    <div class="space-y-6 max-w-3xl text-left mx-auto">

      <div class="flex items-center justify-between">
        <span class="flex-grow flex flex-col" id="availability-label" content="This would be any children or dependents who are either living with you OR in school OR whom would qualify on your health plan" v-tippy data-balloon-length="large">
          <span class="text-sm font-medium text-gray-900">Children</span>
          <span class="text-sm text-gray-500">Do you have dependent children?</span>
        </span>
        <!-- Enabled: "bg-indigo-600", Not Enabled: "bg-gray-200" -->
        <button type="button" @click="hasKids()" :aria-pressed="kidsinschool ? 'true' : 'false'" :class="kidsinschool ? 'bg-indigo-600' : 'bg-gray-200'" class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"  aria-labelledby="availability-label">
          <span class="sr-only">Has children</span>
          <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
          <span aria-hidden="true" :class="kidsinschool ? 'translate-x-5' : 'translate-x-0'" class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
        </button>
      </div>

      <!-- <div v-if="kidsinschool" class="flex items-center justify-between">
        <span class="flex-grow flex flex-col" id="availability-label">
          <span class="text-sm font-medium text-gray-900">529 Contributions</span>
          <span class="text-sm text-gray-500">What is your <span class="font-bold">annual</span> estimated 529 plan contributions for them?</span>
        <TWAlert v-if="$v.school529.$error && !$v.school529.decimal" alerttype="mini" message="Needs valid USD $ amount."></TWAlert>
        <TWAlert v-if="$v.school529.$error && $v.school529.decimal && !$v.school529.minValue" alerttype="mini" message="Needs valid positive $ amount"></TWAlert>
        </span>

        <div class="mb-1 pt-0 relative rounded-md shadow-sm">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span class="text-gray-500 sm:text-sm">
                $
            </span>
            </div>
            <input v-model="$v.school529.$model" @keyup="submit()" type="number" name="school529" id="school529" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md" placeholder="0.00" aria-describedby="529 contribution amount ">
            <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <span class="text-gray-500 sm:text-sm" id="price-currency">
                USD
            </span>
            </div>
        </div>
      </div> -->

      <div v-if="kidsinschool" class="flex items-center justify-between">
        <span class="flex-grow flex flex-col" id="availability-label">
          <span class="text-sm font-medium text-gray-900">Post-Secondary</span>
          <span class="text-sm text-gray-500">Enter your <span class="font-bold">annual</span> estimated post-secondary education for 2022?</span>
        <TWAlert v-if="$v.schoolpostsecondary.$error && !$v.schoolpostsecondary.decimal" alerttype="mini" message="Needs valid USD $ amount."></TWAlert>
        <TWAlert v-if="$v.schoolpostsecondary.$error && $v.schoolpostsecondary.decimal && !$v.schoolpostsecondary.minValue" alerttype="mini" message="Needs valid positive $ amount"></TWAlert>
        </span>

        <div class="flex flex-col">
          <div class="mb-1 pt-0 relative rounded-md shadow-sm">
              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <span class="text-gray-500 sm:text-sm">
                  $
              </span>
              </div>
              <input v-model="$v.schoolpostsecondary.$model" @keyup="submit()" type="number" name="schoolpostsecondary" id="schoolpostsecondary" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md" placeholder="0.00" aria-describedby="529 contribution amount ">
              <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <span class="text-gray-500 sm:text-sm" id="price-currency">
                  USD
              </span>
              </div>
          </div>
          <div v-if="schoolpostsecondary" class="text-center text-xs text-gray-400">
          average ${{parseFloat(schoolpostsecondary)/12 | dollarAmt}} USD/mo
          </div>
        </div>
      </div>




    </div>

  </div>
</template>

<script>
import {minValue,decimal} from 'vuelidate/lib/validators'
import TWAlert from './TWAlert.vue'
import TWQType from './TWQType.vue'
export default {
  components: {
    TWAlert,
    TWQType
  },
  data () {
    return {
      kidsinschool: false,
      school529: null,
      schoolpostsecondary: null
    }
  },
  validations: {

    school529: {
      decimal,
      minValue: minValue(0)
    },
    schoolpostsecondary: {
      decimal,
      minValue: minValue(0)
    }

  },
  computed: {
    form() {
      return this.$store.state.estimator
    }
  },
  created () {
    this.kidsinschool = this.form.kidsinschool
    this.school529 = this.form.schoolcontributions.school529
    this.schoolpostsecondary = this.form.schoolcontributions.schoolpostsecondary
  },
  methods: {
    hasKids () {
      this.kidsinschool = !this.kidsinschool
      this.submit()
    },
    submit () {
      const offset = (this.form.schoolcontributions.schoolpostsecondaryOffset) || 50
      this.$emit('update',{
        data: {
          kidsinschool: this.kidsinschool,
          schoolcontributions: {
            school529: this.school529,
            schoolpostsecondary: this.schoolpostsecondary,
            schoolpostsecondary1: this.schoolpostsecondary*(offset/100),
            schoolpostsecondary2: this.schoolpostsecondary*((100-offset)/100),
          }
        },
        valid: !this.$v.$invalid
      })
    }
  }
}
</script>

<style scoped>

</style>