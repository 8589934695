export default {
  namespaced:true,
  state: { 
    accepted: false
  },
  getters: { 
    acceptedDisclaimer (state) {
        return state.accepted;
    }
  },
  actions: {
    // Disclaimers
    acceptDisclaimer({commit}) {
      commit('setDisclaimer')
    },
    rejectDisclaimer({commit}) {
      commit('removeDisclaimer')
    }
  },
  mutations: { 
    // Disclaimers 
    setDisclaimer(state) { 
      state.accepted = true
    },
    removeDisclaimer(state) { 
      state.accepted = false
    }
  }

}
